import React from 'react';

import strings from '../../Localization/Localization';

import { Row, Col, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  bills as billsRoutes,
  quotes as quotesRoutes,
  orders as ordersRoutes,
  services as servicesRoutes,
  technicalTicket as technicalTicketRoutes,
  // technicalGlobalTicket as technicalGlobalTicketRoutes,
  commercialTicket as commercialTicketRoutes,
  administrativeTicket as administrativeTicketRoutes,
  installationTicket as installationTicketRoutes,
  terminationTicket as terminationTicketRoutes,
} from '../../MetaData/Route/routes';

class HistoryElement extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      displayWholeComment: true,

      displayComment: false,
      displayReference: false
    }
  }

  getRouteForTicket = (ticket) => {
    switch (ticket["request_type"].group.name) {
      case "installation":
        return installationTicketRoutes.routes.show.createPath(ticket.id);
      case "termination":
        return terminationTicketRoutes.routes.show.createPath(ticket.id);
      case "technical":
        return technicalTicketRoutes.routes.show.createPath(ticket.id);
      // case "technical_global":
        // return technicalGlobalTicketRoutes.routes.show.createPath(ticket.id);
      case "commercial":
        return commercialTicketRoutes.routes.show.createPath(ticket.id);
      case "administrative":
        return administrativeTicketRoutes.routes.show.createPath(ticket.id);
      default:
    }
  }

  getReferenceTicketType = (ticket) => {
    switch (ticket["request_type"].group.name) {
      case "installation":
        return "installationTicket";
      case "termination":
        return "terminationTicket";
      case "technical":
        return "technicalTicket";
      case "technical_global":
        return "technicalGlobalTicket";
      case "commercial":
        return "commercialTicket";
      case "administrative":
        return "administrativeTicket";
      default:
    }

    return null;
  }

  openInNewTab = (href) => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
    }).click();
  }

  displayTitle() {
    let date = this.props.element["begin_at"].split('T');
    return (
      <Row>
        <Col>{`Appel ${(this.props.element.inout === "in")?"reçu":""}${(this.props.element.inout === "out")?"émis":""} par ${this.props.element.operator.displayname} le ${date[0]} à ${date[1]} avec le ${this.props.element.number} de ${this.props.element.user.displayname?this.props.element.user.displayname:""}${this.props.element["temporary_user"].name?this.props.element["temporary_user"].name:""}`}</Col>
        <Col xs="auto">
          <Button variant="my-information" className="call-report-reference-button" onClick={() => this.setState({displayComment: !this.state.displayComment})}><i className="icon-pen"/></Button>
          <Button variant="my-information" className="call-report-reference-button" onClick={() => this.setState({displayReference: !this.state.displayReference})}><i className="icon-paperclip"/></Button>
        </Col>
      </Row>
    )
  }

  displayComment() {
    if(this.state.displayComment) {
      return (
        <>
          {this.displaySeparator()}
          <Row>
            <div className={this.state.displayWholeComment?"call-report-history-comment-full":"call-report-history-comment"}>
              {this.props.element.comment}
            </div>
          </Row>
          {this.displayCommentExtender()}
        </>
      )
    }
  }

  displayCommentExtender() {
    if(!this.state.displayWholeComment) {
      return (
        <Row className="justify-content-center">
          <Button variant="none" onClick={() => this.setState({displayWholeComment: true})}>
            <i className="icon-chevron-down"/>
          </Button>
        </Row>
      )
    }
  }

  displayReferences() {

    /*


      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>{strings.form.cart.state.options.order.waitingValidation}</Tooltip>
          }>
          <div className="item-index-status item-index-status-info-icon"><i className="icon-cart"/></div>
        </OverlayTrigger>
      );

    */

    if(this.state.displayReference) {
      return (
        <>
          {this.displaySeparator()}
          <Row>
            {this.props.element.bills.map((bill, i) => {
              return (
                <ButtonGroup key={i} className="btn-group-history-reference">
                  <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.openInNewTab(billsRoutes.routes.show.createPath(bill.bill.id))}><i className="icon-doc"/>&nbsp;{bill.bill.number}</Button>
                  <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.props.addReference("bill", bill.bill)}><i className="icon-send"/></Button>
                </ButtonGroup>
              )
            })}
            {this.props.element.orders.map((order, i) => {
              return (
                <ButtonGroup key={i} className="btn-group-history-reference">
                  <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.openInNewTab(ordersRoutes.routes.show.createPath(order.order.id))}><i className="icon-deplacement"/>&nbsp;{order.order.number}</Button>
                  <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.props.addReference("order", order.order)}><i className="icon-send"/></Button>
                </ButtonGroup>
              )
            })}
            {this.props.element.quotes.map((quote, i) => {
              return (
                <ButtonGroup key={i} className="btn-group-history-reference">
                  <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.openInNewTab(quotesRoutes.routes.show.createPath(quote.quote.id))}><i className="icon-doc"/>&nbsp;{quote.quote.number}</Button>
                  <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.props.addReference("quote", quote.quote)}><i className="icon-send"/></Button>
                </ButtonGroup>
              )
            })}
            {this.props.element.services.map((service, i) => {
              return (
                <ButtonGroup key={i} className="btn-group-history-reference">
                  <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.openInNewTab(servicesRoutes.routes.show.createPath(service.service.id))}><i className="icon-sante"/>&nbsp;{service.service.number}</Button>
                  <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.props.addReference("service", service.service)}><i className="icon-send"/></Button>
                </ButtonGroup>
              )
            })}
            {this.props.element.tickets.map((ticket, i) => {
              return (
                <ButtonGroup key={i} className="btn-group-history-reference">
                  <OverlayTrigger
                    overlay={
                      <Tooltip>{ticket.ticket.number}</Tooltip>
                    }>
                    <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.openInNewTab(this.getRouteForTicket(ticket.ticket))}><i className="icon-support"/>&nbsp;{ticket.ticket.number}</Button>
                  </OverlayTrigger>
                  <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.props.addReference(this.getReferenceTicketType(ticket.ticket), ticket.ticket)}><i className="icon-send"/></Button>
                </ButtonGroup>
              )
            })}
            {this.props.element["ticket_appointments"].map((ticketAppointment, i) => {
              return (
                <ButtonGroup key={i} className="btn-group-history-reference">
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <p>Cause</p>
                        <p>{ticketAppointment.appointment.cause}</p>
                        <p>Result</p>
                        <p>{ticketAppointment.appointment.result}</p>
                      </Tooltip>
                    }>
                    <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.openInNewTab(this.getRouteForTicket(ticketAppointment.appointment.ticket))}><i className="icon-support"/>&nbsp;{ticketAppointment.appointment.number}</Button>
                  </OverlayTrigger>
                  <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.props.addReference(this.getReferenceTicketType(ticketAppointment.appointment.ticket), ticketAppointment.appointment.ticket)}><i className="icon-send"/></Button>
                </ButtonGroup>
              )
            })}
            {this.props.element["ticket_comments"].map((ticketComment, i) => {
              return (
                <ButtonGroup key={i} className="btn-group-history-reference">
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip>{ticketComment.comment.body}</Tooltip>
                    }>
                    <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.openInNewTab(this.getRouteForTicket(ticketComment.comment.ticket))}><i className="icon-support"/>&nbsp;<i className="icon-tickets"/>&nbsp;{ticketComment.comment.ticket.number}</Button>
                  </OverlayTrigger>
                  <Button variant="my-secondary-outline" className="btn-history-reference" onClick={() => this.props.addReference(this.getReferenceTicketType(ticketComment.comment.ticket), ticketComment.comment.ticket)}><i className="icon-send"/></Button>
                </ButtonGroup>
              )
            })}
          </Row>
        </>
      )
    }
  }

  displaySeparator() {
    return (
      <div className="call-report-separator"/>
    )
  }

  render() {
    return (
      <div className="call-report-history-container">
        <Col>
          {this.displayTitle()}
          {this.displayComment()}
          {this.displayReferences()}
        </Col>
      </div>
    );
  }
}


export default HistoryElement;
