import React from 'react';

import strings from '../../Localization/Localization';

import { Col, Row, ButtonGroup, Button, DropdownButton, Dropdown, Modal, Card } from 'react-bootstrap';

import { v4 as uuid } from 'uuid';

import ScrollContainer from '../Basic/ScrollContainer'

import '../../css/call_report.css';

import { connect } from 'react-redux';
import { resetBreadcrumbAction } from '../../Store/Action/breadcrumb';
import {
  createCallReportAction,
  modifyCallReportAction,
  resetCallReportAction
} from '../../Store/Action/callReport';

import { withModalHandler } from '../../HOC/ModalHandler';

import {
  newItem,
  index as indexCallReports
} from '../../API/CallReports';
import { fetchPartnerUsers } from '../../API/Users';
import { fetchPartners } from '../../API/Partners';
import { fetchTemporaryUser } from '../../API/CallReportTemporaryUsers';

import { conf as billConf } from '../../MetaData/Configuration/bills';
import { conf as quoteConf } from '../../MetaData/Configuration/quotes';
import { conf as orderConf } from '../../MetaData/Configuration/orders';
import { conf as serviceConf } from '../../MetaData/Configuration/services';
import {
  technicalTicketConf,
  commercialTicketConf,
  administrativeTicketConf,
  installationTicketConf,
  terminationTicketConf
} from '../../MetaData/Configuration/tickets';

import MainLayout from '../Layout/MainLayout';
import SubFooter from '../Layout/SubFooter';

import Loading from '../Layout/Loading';

import Index from '../ItemIndex/ItemIndex';

import Grid from '../Grid/Grid';
import Box from '../Grid/Box/Box';

import MyForm from '../Form/MyForm';

import HistoryElement from './HistoryElement';
import ReferenceSelector from './ReferenceSelector';

import AsyncCounter from '../../Utils/Counter/AsyncCounter';

const CallReportKeepDimensionTheme = React.lazy(() => import('../Theme/Themes/call_report_keep_dimension'));

class CallReport extends React.Component {

  static THRESHOLD_INFINITE_SCROLL_HISTORY = 50;

  constructor(props) {
    super(props);

    this.counter = new AsyncCounter();

    this.gridRef = React.createRef();

    this.generalDataFormRef = React.createRef();
    this.resultDataFormRef = React.createRef();
    this.referenceRefs = [];

    this.indexCallReports = this.props.modalHandler.addVerificationWithCallback(indexCallReports, this.postFetchCallReports, this.postFetchCallReportsFailure);
    this.newItem = this.props.modalHandler.addVerificationWithCallback(newItem, this.postNewItem, this.postNewItemFailure);

    this.historyLoading = false;

    this.state = {
      gridRef: 0,

      newItemLoading: false,
      callReportLoading: false,

      historyLoading: false,
      historySelected: "",
      historyItems: null,
      historyPage: 1,
      historyTotal: 0,

      searchModalSelected: null,
      initializedIndex: null,

      viewportWidth: 1920,

      checkLoading: false
    }
  }

  componentDidMount() {
    if(!this.props.match.params.key || !this.props.callReport[this.props.match.params.key]) {
      this.uuid = uuid();
      let number = this.getPreFilledPhoneNumber();
      this.props.dispatch(createCallReportAction(this.uuid, number, number?"in":"out"));
    }

    window.addEventListener('resize', this.onResize);
    this.onResize();

    this.props.dispatch(resetBreadcrumbAction(window.location.pathname, "Rapport appel"));
  }

  componentDidUpdate() {
    if(this.uuid && this.uuid !== this.props.match.params.key) {
      this.props.history.push(`/callReport/${this.uuid}`)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  getPreFilledPhoneNumber() {
    let key_values = this.props.history.location.search.slice(1).split('&');
    let parameters = {};
    key_values.forEach(key_value => {
      key_value = key_value.split('=');
      parameters[key_value[0]] = key_value[1]
    });
    if(parameters.CallerNumber) {
      return parameters.CallerNumber
    }
    return null
  }

  onResize = () => {
    if(this.state.viewportWidth !== window.innerWidth) {
      this.setState({
        viewportWidth: window.innerWidth
      })
    }
  }

  getKeepDimension() {
    return true;
  }

  getUuid() {
    return this.props.match.params.key;
  }

  getCallReportSearch = () => {
    switch (this.state.historySelected) {
      case "number":
        return {
          number: (this.props.callReport[this.getUuid()].generalData)?this.props.callReport[this.getUuid()].generalData.number:""
        }
      case "user":
        switch (this.props.callReport[this.getUuid()].generalDataUserType) {
          case "referee":
            return {
              user: (this.props.callReport[this.getUuid()].generalData && this.props.callReport[this.getUuid()].generalData.referee)?this.props.callReport[this.getUuid()].generalData.referee.id:""
            }
          case "temporaryUser":
            return {
              temporary_user_name: (this.props.callReport[this.getUuid()].generalData)?this.props.callReport[this.getUuid()].generalData.temporaryUser:""
            }
          default:
        }
        break;
      case "partner":
        return {
          partner: (this.props.callReport[this.getUuid()].generalData && this.props.callReport[this.getUuid()].generalData.partner)?this.props.callReport[this.getUuid()].generalData.partner.id:""
        }
      default:
    }
    return {}
  }

  loadCallReports = () => {
    this.indexCallReports(1, 15, this.getCallReportSearch(), 'DESC', 'main');
  }

  postFetchCallReports = (data) => {
    let items = [];
    if(data.page === 1) {
      items = data.items
    }
    else {
      items = this.state.historyItems;
      items = items.concat(data.items);
    }

    this.setState({
      historyLoading: false,
      historyItems: items,
      historyPage: data.page,
      historyTotal: data.total,
    }, () => {
      this.historyLoading = false
    })
  }

  postFetchCallReportsFailure = (msg) => {
    console.log(msg);
  }

  postNewItem = (data) => {

    let appointmentIds = data.item["ticket_appointments"]
      .filter(ticketAppointment => !!ticketAppointment.appointment["yousign_signature_request"].id)
      .map(ticketAppointment => ticketAppointment.appointment.id);
    if(appointmentIds.length > 0) {
      this.props.history.push("/callReportAppointmentSignature?appointmentId="+appointmentIds.join(","));
    }
    else {
      this.props.history.push("/dashboard");
    }

    this.props.dispatch(resetCallReportAction(this.getUuid()));

    this.setState({
      newItemLoading: false
    })
  }

  postNewItemFailure = (msg) => {
    this.setState({
      newItemLoading: false
    })
  }

  deleteCallReport = () => {
    this.props.history.push("/dashboard");
    this.props.dispatch(resetCallReportAction(this.getUuid()));
  }

  startSaveCallReport = () => {
    this.setState({
      checkLoading: true
    }, () => {
      this.counter.reset();

      let quantity = 1;
      quantity += this.referenceRefs.filter(ref => !!ref.current).length;
      this.counter.setValue(quantity);

      this.counter.subscribe(0, this.saveCounterFinished);

      this.generalDataFormRef.current.onSubmit();
      // this.resultDataFormRef.current.onSubmit();
      this.referenceRefs.filter(ref => !!ref.current).forEach((ref) => {
        ref.current.askForValidation();
      });
      ;
    })
  }

  saveCounterDecrement = (data) => {
    this.counter.decrement();
  }

  saveCounterFailed = () => {
    this.counter.reset();
    this.setState({
      checkLoading: false
    })
  }

  saveCounterFinished = () => {
    this.saveCallReport();
  }

  saveCallReport = () => {
    let newItem = {
      begin_at: this.props.callReport[this.getUuid()].generalData.beginAt,
      inout: this.props.callReport[this.getUuid()].generalData.inout,
      number: this.props.callReport[this.getUuid()].generalData.number,
      user: (this.props.callReport[this.getUuid()].generalDataUserType === "referee")?{ id: this.props.callReport[this.getUuid()].generalData.referee.id }:null,
      temporary_user: (this.props.callReport[this.getUuid()].generalDataUserType === "temporaryUser")?{
        name: (typeof this.props.callReport[this.getUuid()].generalData.temporaryUser === "object")?this.props.callReport[this.getUuid()].generalData.temporaryUser.name:this.props.callReport[this.getUuid()].generalData.temporaryUser,
        function: this.props.callReport[this.getUuid()].generalData.temporaryUserFunction
      }:null,
      partner: { id: this.props.callReport[this.getUuid()].generalData.partner.id },
      result: this.props.callReport[this.getUuid()].generalData.result,
      comment: this.props.callReport[this.getUuid()].generalData.mainComment,
      bills: [],
      quotes: [],
      orders: [],
      tickets: [],
      ticket_comments: [],
      ticket_appointments: [],
      new_tickets: [],
      services: []
    }

    if(this.props.callReport[this.getUuid()].references) {
      newItem.bills = this.props.callReport[this.getUuid()].references
        .filter(reference => reference._callReportType === "bill")
        .map(reference => {
          return {
            bill: {
              id: reference.id
            }
          }
        })

      newItem.quotes = this.props.callReport[this.getUuid()].references
        .filter(reference => reference._callReportType === "quote")
        .map(reference => {
          return {
            quote: {
              id: reference.id
            }
          }
        })

      newItem.orders = this.props.callReport[this.getUuid()].references
        .filter(reference => reference._callReportType === "order")
        .map(reference => {
          return {
            order: {
              id: reference.id
            }
          }
        })

      newItem.services = this.props.callReport[this.getUuid()].references
        .filter(reference => reference._callReportType === "service")
        .map(reference => {
          return {
            service: {
              id: reference.id
            }
          }
        })

      newItem.tickets = this.props.callReport[this.getUuid()].references
        .filter(reference => !reference._actionType && ["installationTicket", "terminationTicket", "technicalTicket", "commercialTicket", "administrativeTicket"].includes(reference._callReportType))
        .map(reference => {
          return {
            ticket: {
              id: reference.id
            }
          }
        })

      newItem.ticket_comments = this.props.callReport[this.getUuid()].references
        .filter(reference => reference._actionType === "ticketComment" && ["installationTicket", "terminationTicket", "technicalTicket", "commercialTicket", "administrativeTicket"].includes(reference._callReportType))
        .map(reference => {
          return {
            comment: {
              body: reference._ticketComment.comment.body,
              hidden: reference._ticketComment.comment.hidden?reference._ticketComment.comment.hidden:false,
              send_notification: reference._ticketComment.comment.send_notification?reference._ticketComment.comment.send_notification:false,
              close_ticket: reference._ticketComment.comment.close_ticket?reference._ticketComment.comment.close_ticket:false,
              ticket: {
                id: reference.id
              }
            }
          }
        })

      newItem.ticket_appointments = this.props.callReport[this.getUuid()].references
        .filter(reference => reference._actionType === "ticketAppointment" && ["installationTicket", "terminationTicket", "technicalTicket", "commercialTicket", "administrativeTicket"].includes(reference._callReportType))
        .map(reference => {
          return {
            appointment: {
              secondary_tickets: reference._ticketAppointment.appointment.secondary_tickets?reference._ticketAppointment.appointment.secondary_tickets.map(secondaryTicket => { return { id: secondaryTicket.id } }):[],
              referee_client: reference._ticketAppointment.appointment.referee_client,
              cause: reference._ticketAppointment.appointment.cause,
              result: reference._ticketAppointment.appointment.result,
              appointment_type: reference._ticketAppointment.appointment.appointment_type,
              appointment_products: reference._ticketAppointment.appointment.appointment_products,
              time_slot_estimate: reference._ticketAppointment.appointment.time_slot_estimate,
              date_start: reference._ticketAppointment.appointment.date_start,
              date_end: reference._ticketAppointment.appointment.date_end,
              close_ticket: reference._ticketAppointment.appointment.close_ticket,
              yousign_operation_mode: reference._ticketAppointment.appointment.yousign_operation_mode,
              ticket: {
                id: reference.id
              }
            }
          }
        })

    newItem.new_tickets = this.props.callReport[this.getUuid()].references
      .filter(reference => !reference._actionType && ["newTechnicalTicket", "newCommercialTicket", "newAdministrativeTicket"].includes(reference._callReportType))
      .map(reference => {
        return {
          ticket: {
            client: reference.client,
            client_account: reference.client_account,
            criticity: reference.criticity,
            description: reference.description,
            place: reference.place,
            referee_clients: reference.referee_clients,
            request_type: reference.request_type,
            ticket_origin: reference.ticket_origin
          }
        }
      })

    newItem.new_ticket_comments = this.props.callReport[this.getUuid()].references
      .filter(reference => reference._actionType === "ticketComment" && ["newTechnicalTicket", "newCommercialTicket", "newAdministrativeTicket"].includes(reference._callReportType))
      .map(reference => {
        return {
          comment: {
            body: reference._ticketComment.comment.body,
            hidden: reference._ticketComment.comment.hidden?reference._ticketComment.comment.hidden:false,
            send_notification: reference._ticketComment.comment.send_notification?reference._ticketComment.comment.send_notification:false,
            close_ticket: reference._ticketComment.comment.close_ticket?reference._ticketComment.comment.close_ticket:false,
            ticket: {
              client: reference.client,
              client_account: reference.client_account,
              criticity: reference.criticity,
              description: reference.description,
              place: reference.place,
              referee_clients: reference.referee_clients,
              request_type: reference.request_type,
              ticket_origin: reference.ticket_origin
            }
          }
        }
      })

    newItem.new_ticket_appointments = this.props.callReport[this.getUuid()].references
      .filter(reference => reference._actionType === "ticketAppointment" && ["newTechnicalTicket", "newCommercialTicket", "newAdministrativeTicket"].includes(reference._callReportType))
      .map(reference => {
        return {
          appointment: {
            secondary_tickets: reference._ticketAppointment.appointment.secondary_tickets?reference._ticketAppointment.appointment.secondary_tickets.map(secondaryTicket => { return { id: secondaryTicket.id } }):[],
            referee_client: reference._ticketAppointment.appointment.referee_client,
            cause: reference._ticketAppointment.appointment.cause,
            result: reference._ticketAppointment.appointment.result,
            appointment_type: reference._ticketAppointment.appointment.appointment_type,
            appointment_products: reference._ticketAppointment.appointment.appointment_products,
            time_slot_estimate: reference._ticketAppointment.appointment.time_slot_estimate,
            date_start: reference._ticketAppointment.appointment.date_start,
            date_end: reference._ticketAppointment.appointment.date_end,
            close_ticket: reference._ticketAppointment.appointment.close_ticket,
            yousign_operation_mode: reference._ticketAppointment.appointment.yousign_operation_mode,
            ticket: {
              client: reference.client,
              client_account: reference.client_account,
              criticity: reference.criticity,
              description: reference.description,
              place: reference.place,
              referee_clients: reference.referee_clients,
              request_type: reference.request_type,
              ticket_origin: reference.ticket_origin
            }
          }
        }
      })
    }

    this.setState({
      newItemLoading: true,
      checkLoading: false
    }, () => {
      this.newItem(newItem);
    })
  }



  defineGridRef = ref => {
    this.gridRef.current = ref
    this.setState({
      gridRef: this.state.gridRef+1
    })
  }

  getTotalColumn = () => {
    if(this.gridRef.current) {
      return this.gridRef.current.getTotalColumn();
    }
    return 8;
  }

  getBoxWidth = () => {
    if(this.gridRef.current) {
      return this.gridRef.current.getBaseWidth()+'px';
    }
    return '12.5%';
  }

  referenceRefCallback = (ref, i) => {
    if(this.referenceRefs[i] === undefined) {
      this.referenceRefs[i] = React.createRef();
    }
    this.referenceRefs[i].current = ref;
  }



  addReference = (type, item = {}) => {
    let references = []
    if(this.props.callReport[this.getUuid()].references) {
      references = [...this.props.callReport[this.getUuid()].references]
    }

    if(!item.id || !references.find(reference => reference._callReportType === type && reference.id === item.id)) {
      let newItem = Object.assign({
        _callReportType: type
      }, item)
      references.push(newItem)
      this.props.dispatch(modifyCallReportAction(this.getUuid(), "references", references))
    }
  }

  deleteReference = (reference) => {
    let references = []
    if(this.props.callReport[this.getUuid()].references) {
      references = this.props.callReport[this.getUuid()].references.filter(
        ref => ref !== reference
      )
    }
    this.props.dispatch(modifyCallReportAction(this.getUuid(), "references", references))
  }

  modifyReference = (reference, key, value) => {
    let references = []
    if(this.props.callReport[this.getUuid()].references) {
      references = [...this.props.callReport[this.getUuid()].references]
    }

    let index = references.indexOf(reference);
    if(index || index === 0) {
      references[index][key] = value
      this.props.dispatch(modifyCallReportAction(this.getUuid(), "references", references))
    }
  }

  modifyHistoryTab = (identifier) => {
    this.setState({
      historySelected: identifier,
      historyLoading: true
    }, () => {
      this.loadCallReports();
    })
  }


  onScrollHistory = (currentY, totalHeight) => {
    if(
      this.historyLoading === false
      && this.state.historyItems.length !== this.state.historyTotal
      && totalHeight !== 0
      && currentY > (totalHeight - CallReport.THRESHOLD_INFINITE_SCROLL_HISTORY)
    ) {
      this.historyLoading = true
      this.setState({
        historyLoading: true
      }, () => {
        this.indexCallReports(this.state.historyPage+1, 15, this.getCallReportSearch(), 'DESC', 'main');
      })
    }
  }


  displayGeneralData() {
    return (
      <Box key="general_data" position={0} column={2} row={4}>
        <div className="call-report-box call-report-box-form" style={{height:"100%"}}>
          <ScrollContainer height="100%">
            <div>
              <div className="d-flex justify-content-center m-1">
                <Button variant="none" className={`btn-temp-stick${(this.props.callReport[this.getUuid()].generalDataUserType === "referee")?" btn-temp-stick-selected":""}`} onClick={() => this.props.dispatch(modifyCallReportAction(this.getUuid(), "generalDataUserType", "referee"))}><i className="icon-user"/></Button>
                <Button variant="none" className={`btn-temp-stick${(this.props.callReport[this.getUuid()].generalDataUserType === "temporaryUser")?" btn-temp-stick-selected":""}`} onClick={() => this.props.dispatch(modifyCallReportAction(this.getUuid(), "generalDataUserType", "temporaryUser"))}><i className="icon-user"/><i className="icon-hourglass"/></Button>
              </div>
              <MyForm
                ref={this.generalDataFormRef}
                formKey="call-report-general-data"
                fields={[
                  {
                    fieldKey: "number",
                    type: "text",
                    icons: ["phone"],
                    label: strings.callReport.generalData.form.number.label,
                    validators: [
                      {
                        id: 'assertEmpty',
                        type: 'regex',
                        conf: {
                          regex: /\+\d+/
                        },
                        invalidFeedback: strings.callReport.generalData.form.number.invalid.assertEmpty,
                        defaultValue: false
                      },
                    ]
                  },
                  (this.props.callReport[this.getUuid()].generalDataUserType === "referee")?{
                    fieldKey: "referee",
                    icons: ["user"],
                    label: strings.callReport.generalData.form.user.label,
                    type: "dropdownEntity",

                    validators: [],

                    showEmpty: true,
                    loader: {
                      id: "main_users",
                      loader: fetchPartnerUsers,
                      loaderRequire: () => true,
                      loaderArguments: () => [],
                    },

                    adapter: {
                      requirement: (data) => data["id"] && data["displayname"],
                      getValue: (data) => data["id"],
                      getLabel: (data) => data["displayname"],
                    },
                  }:undefined,
                  (this.props.callReport[this.getUuid()].generalDataUserType === "temporaryUser")?
                  /*{
                    fieldKey: "temporaryUser",
                    type: "text",
                    icons: ["user", "hourglass"],
                    label: strings.callReport.generalData.form.temporaryUser.label,
                    validators: []
                  }*/{
                    fieldKey: "temporaryUser",
                    type: "dropdownEntity",
                    icons: ["user", "hourglass"],
                    label: strings.callReport.generalData.form.temporaryUser.label,

                    ignoreValidation: true,
                    validators: [],

                    showEmpty: true,
                    loader: {
                      id: "temporaryUserName",
                      loader: fetchTemporaryUser,
                      loaderRequire: () => true,
                      loaderArguments: () => [],
                    },

                    adapter: {
                      requirement: (data) => typeof data === 'object' && data["name"],
                      getValue: (data) => data["name"],
                      getLabel: (data) => data["name"],
                      isFinalValue: true
                    },
                  }:undefined,
                  (this.props.callReport[this.getUuid()].generalDataUserType === "temporaryUser")?{
                    fieldKey: "temporaryUserFunction",
                    type: "text",
                    icons: ["information", "hourglass"],
                    label: strings.callReport.generalData.form.temporaryUserFunction.label,
                    validators: []
                  }:undefined,
                  {
                    fieldKey: "partner",
                    icons: ["clients"],
                    label: strings.callReport.generalData.form.partner.label,
                    type: "dropdownEntity",

                    validators: [],

                    showEmpty: true,
                    loader: {
                      id: "client",
                      loader: fetchPartners,
                      loaderRequire: () => true,
                      loaderArguments: () => [],
                    },

                    adapter: {
                      requirement: (data) => data["id"] && data["name"],
                      getValue: (data) => data["id"],
                      getLabel: (data) => data["name"],
                    },
                  },
                  {
                    fieldKey: "beginAt",
                    type: "datetime",
                    icons: ["clock"],
                    label: strings.callReport.generalData.form.beginAt.label,

                    validators: []
                  },
                  {
                    fieldKey: "inout",
                    type: "dropdown",
                    icons: ["swap"],
                    label: strings.callReport.generalData.form.result.label,
                    validators: [],

                    input: 'options',
                    showEmpty: true,
                    options: [
                      {
                        value: 'in',
                        label: strings.callReport.generalData.form.inout.options.in
                      },
                      {
                        value: 'out',
                        label: strings.callReport.generalData.form.inout.options.out
                      }
                    ]
                  },
                  {
                    fieldKey: "result",
                    type: "dropdown",
                    icons: ["phone"],
                    label: strings.callReport.generalData.form.result.label,
                    validators: [],

                    input: 'options',
                    showEmpty: true,
                    options: [
                      {
                        value: 'finished',
                        label: strings.callReport.generalData.form.result.options.finished
                      },
                      {
                        value: 'failed',
                        label: strings.callReport.generalData.form.result.options.failed
                      },
                      {
                        value: 'callbackRequest',
                        label: strings.callReport.generalData.form.result.options.callbackRequest
                      },
                      {
                        value: 'callback',
                        label: strings.callReport.generalData.form.result.options.callback
                      },
                      {
                        value: 'transfer',
                        label: strings.callReport.generalData.form.result.options.transfer
                      }
                    ]
                  },
                  {
                    fieldKey: "mainComment",
                    type: "textarea",
                    icons: ["pen"],
                    label: strings.callReport.result.form.comment.label,
                    validators: []
                  },
                ].filter(item => !!item)}
                disabled={false}
                values={this.props.callReport[this.getUuid()].generalData}
                onChangeField={(key, value) => {
                  let generalData = Object.assign({}, this.props.callReport[this.getUuid()].generalData)
                  generalData[key] = value
                  this.props.dispatch(modifyCallReportAction(this.getUuid(), "generalData", generalData))
                }}

                displaySubmitButton={false}
                onSubmit={this.saveCounterDecrement}
                onSubmitFailed={this.saveCounterFailed}

                formExtraClassName="call-report-references-form"
                fieldVariant="callReport"
                formSizeMd={12}
              />
            </div>
          </ScrollContainer>
        </div>
      </Box>
    )
  }

  /*
  displayResult() {
    return (
      <Box key="result" position={16} column={2} row={1}>
        <div className="call-report-box call-report-box-form">
          <ScrollContainer height="100%">
            <MyForm
              ref={this.resultDataFormRef}
              formKey="call-report-general-data"
              fields={[
                {
                  fieldKey: "mainComment",
                  type: "textarea",
                  icons: ["pen"],
                  label: strings.callReport.result.form.comment.label,
                  validators: []
                },
              ]}
              disabled={false}
              values={this.props.callReport[this.getUuid()].generalData}
              onChangeField={(key, value) => {
                let generalData = Object.assign({}, this.props.callReport[this.getUuid()].generalData)
                generalData[key] = value
                this.props.dispatch(modifyCallReportAction(this.getUuid(), "generalData", generalData))
              }}

              displaySubmitButton={false}
              onSubmit={this.saveCounterDecrement}
              onSubmitFailed={this.saveCounterFailed}

              formExtraClassName="call-report-references-form"
              fieldVariant="callReport"
              formSizeMd={12}
            />
          </ScrollContainer>
        </div>
      </Box>
    )
  }
  */

  displayHistory() {
    return (
      <Box key="history" position={2} column={3} row={4}>
        <div className="call-report-box">
          <ScrollContainer height="100%" onScroll={this.onScrollHistory}>
            <div className="d-flex justify-content-center m-1">
              <Button variant="none" className={`btn-temp-stick${(this.state.historySelected === "number")?" btn-temp-stick-selected":""}`} onClick={() => this.modifyHistoryTab("number")}>Numéro</Button>
              <Button variant="none" className={`btn-temp-stick${(this.state.historySelected === "user")?" btn-temp-stick-selected":""}`} onClick={() => this.modifyHistoryTab("user")}>Utilisateur</Button>
              <Button variant="none" className={`btn-temp-stick${(this.state.historySelected === "partner")?" btn-temp-stick-selected":""}`} onClick={() => this.modifyHistoryTab("partner")}>Partenaire</Button>
            </div>
            {this.state.historyItems?this.state.historyItems.map((historyItem, i) => {
              return (
                <HistoryElement
                  key={i}
                  element={historyItem}
                  addReference={this.addReference}
                />
              )
            }):null}
            {this.state.historyLoading?<Loading container="parent" />:null}
            <div style={{height:"70px"}}/>
          </ScrollContainer>
        </div>
      </Box>
    )
  }

  // displayReferenceSearchDatalist() {
  //   return (
  //     <datalist id="reference-search">
  //       <option value="">Group 1</option>
  //       <option value="test1">Test 1</option>
  //       <option value="test2">Test 2</option>
  //       <option value="test3">Test 3</option>
  //       <option value="test4">Test 4</option>
  //       <option value="test5">Test 5</option>
  //     </datalist>
  //   )
  // }

  displayReference() {
    return (
      <Box key="reference" position={5} column={3} row={4}>
        {/*this.displayReferenceSearchDatalist()*/}
        <div className="call-report-box">
          <ScrollContainer height="100%">
            <div className="d-flex justify-content-center m-1">
              <Button variant="my-information" className="call-report-reference-button" onClick={() => this.setState({displaySearchModal: true})}><i className="icon-search"/></Button>
              {/*
              <input placeholder="Recherche" list="reference-search"/>
              <Button variant="my-information" className="call-report-reference-button" onClick={() => console.log("search button")}><i className="icon-send"/></Button>
              */}
              <DropdownButton variant="my-information" className="call-report-reference-button-container" as={ButtonGroup} title="+" id="call-report-reference">
                <Dropdown.Item eventKey="1" onClick={() => this.addReference("newTechnicalTicket", { client: this.props.callReport[this.getUuid()].generalData.partner })}>Ticket technique</Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={() => this.addReference("newCommercialTicket", { client: this.props.callReport[this.getUuid()].generalData.partner })}>Ticket commercial</Dropdown.Item>
                <Dropdown.Item eventKey="3" onClick={() => this.addReference("newAdministrativeTicket", { client: this.props.callReport[this.getUuid()].generalData.partner })}>Ticket administrative</Dropdown.Item>
              </DropdownButton>
            </div>
            {this.props.callReport[this.getUuid()].references && this.props.callReport[this.getUuid()].references.map((reference, i) => {
              return (
                <ReferenceSelector
                  forwardedRef={(ref) => this.referenceRefCallback(ref, i)}
                  key={i}
                  innerKey={i}
                  reference={reference}
                  modify={(key, value) => this.modifyReference(reference, key, value)}
                  delete={() => this.deleteReference(reference)}

                  validationCounterDecrement={this.saveCounterDecrement}
                  validationCounterFail={this.saveCounterFailed}/>
              )
            })}
            <div style={{height:"70px"}}/>
          </ScrollContainer>
        </div>
      </Box>
    )
  }

  displayGrid() {
    return (
      <Grid ref={this.defineGridRef}>
        {this.displayGeneralData()}
        {/*this.displayResult()*/}
        {this.displayHistory()}
        {this.displayReference()}
      </Grid>
    )
  }

  displaySubFooter() {
    return (
      <SubFooter>
        <Col xs={12}>
          <Row className="d-flex justify-content-center">
            {this.displayAskForSignature()}
          </Row>
          <Row className="d-flex justify-content-center">
            <Button variant="my-danger" className="btn-sub-footer" onClick={this.deleteCallReport}><i className="icon-cross"/> SUPPRIMER</Button>
            <Button variant="my-validated" className="btn-sub-footer" onClick={this.startSaveCallReport}><i className="icon-checkmark"/> ENREGISTRER</Button>
          </Row>
        </Col>
      </SubFooter>
    )
  }

  displayReferenceSearchModalIndex() {
    let conf = null
    switch (this.state.searchModalSelected) {
      case "bill":
        conf = billConf.index;
        break;
      case "quote":
        conf = quoteConf.index;
        break;
      case "order":
        conf = orderConf.index;
        break;
      case "installationTicket":
        conf = installationTicketConf.index;
        break;
      case "terminationTicket":
        conf = terminationTicketConf.index;
        break;
      case "technicalTicket":
        conf = technicalTicketConf.index;
        break;
      case "commercialTicket":
        conf = commercialTicketConf.index;
        break;
      case "administrativeTicket":
        conf = administrativeTicketConf.index;
        break;
      case "service":
        conf = serviceConf.index;
        break;
      default:
    }

    let render = false;

    if(conf !== null && this.state.initializedIndex === null) {
      render = true
      Promise.resolve().then(() => {
        this.setState({
          initializedIndex: this.state.searchModalSelected
        })
      })
    }
    else if(conf !== null && this.state.initializedIndex === this.state.searchModalSelected) {
      render = true
    }
    else if(conf !== null && this.state.initializedIndex !== this.state.searchModalSelected) {
      Promise.resolve().then(() => {
        this.setState({
          initializedIndex: null
        })
      })
    }

    if(render) {
      return (
        <Index
          pageTitle={conf.pageTitle}
          id={conf.id}
          index={conf.api}
          searchForm={conf.searchForm}
          computeSearch={conf.computeSearch}
          availableItemDisplay={conf.availableItemDisplay}
          itemDisplay={conf.itemDisplay}
          onClickItem={(id, item) => this.addReference(this.state.searchModalSelected, item)}
          sizing={conf.sizing}/>
      )
    }
  }

  displayReferenceSearchModal() {
    return (
      <Modal show={this.state.displaySearchModal} centered={true} className="call-report-modal" backdropClassName="call-report-modal-backdrop" onHide={() => this.setState({displaySearchModal: false})}>
        <Modal.Body>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-center m-1">
                <Button variant="none" className={`btn-temp-stick${(this.state.searchModalSelected === "bill")?" btn-temp-stick-selected":""}`} onClick={() => this.setState({searchModalSelected: "bill"})}>Facture</Button>
                <Button variant="none" className={`btn-temp-stick${(this.state.searchModalSelected === "quote")?" btn-temp-stick-selected":""}`} onClick={() => this.setState({searchModalSelected: "quote"})}>Devis</Button>
                <Button variant="none" className={`btn-temp-stick${(this.state.searchModalSelected === "order")?" btn-temp-stick-selected":""}`} onClick={() => this.setState({searchModalSelected: "order"})}>Commande</Button>
                <Button variant="none" className={`btn-temp-stick${(this.state.searchModalSelected === "installationTicket")?" btn-temp-stick-selected":""}`} onClick={() => this.setState({searchModalSelected: "installationTicket"})}>Ticket Installation</Button>
                <Button variant="none" className={`btn-temp-stick${(this.state.searchModalSelected === "terminationTicket")?" btn-temp-stick-selected":""}`} onClick={() => this.setState({searchModalSelected: "terminationTicket"})}>Ticket Résiliation</Button>
                <Button variant="none" className={`btn-temp-stick${(this.state.searchModalSelected === "technicalTicket")?" btn-temp-stick-selected":""}`} onClick={() => this.setState({searchModalSelected: "technicalTicket"})}>Ticket Technique</Button>
                <Button variant="none" className={`btn-temp-stick${(this.state.searchModalSelected === "commercialTicket")?" btn-temp-stick-selected":""}`} onClick={() => this.setState({searchModalSelected: "commercialTicket"})}>Ticket Commercial</Button>
                <Button variant="none" className={`btn-temp-stick${(this.state.searchModalSelected === "administrativeTicket")?" btn-temp-stick-selected":""}`} onClick={() => this.setState({searchModalSelected: "administrativeTicket"})}>Ticket Administratif</Button>
                <Button variant="none" className={`btn-temp-stick${(this.state.searchModalSelected === "service")?" btn-temp-stick-selected":""}`} onClick={() => this.setState({searchModalSelected: "service"})}>Service</Button>
              </div>
              {this.displayReferenceSearchModalIndex()}
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    )
  }

  displayCallReportLoading() {
    if(this.state.callReportLoading) {
      return (
        <Loading container="parent"/>
      )
    }
  }

  displayAskForSignature() {
    if(
      this.props.callReport[this.getUuid()].references
      &&
      this.props.callReport[this.getUuid()].references
        .filter(reference => reference._actionType === "ticketAppointment")
        .some(reference => {
          return reference._ticketAppointment && reference._ticketAppointment.appointment && reference._ticketAppointment.appointment["appointment_type"] && reference._ticketAppointment.appointment["appointment_type"]["ask_for_signature"]
        })
    ) {
      return (
        <div className="call-report-ask-for-signature">{strings.callReport.askForSignature}</div>
      )
    }
  }

  displayLoading() {
    if(this.state.newItemLoading || this.state.checkLoading) {
      return (
        <Loading/>
      )
    }
  }

  render() {
    if(this.getUuid() && this.props.callReport[this.getUuid()]) {
      return (
        <MainLayout>
          {this.getKeepDimension()?
            <React.Suspense fallback={<></>}>
              <CallReportKeepDimensionTheme />
            </React.Suspense>
            :undefined
          }
          {this.displayLoading()}
          <div className="call-report-container">
            {this.displayGrid()}
          </div>
          {this.displayReferenceSearchModal()}
          {this.displaySubFooter()}
        </MainLayout>
      );
    }
    return <div/>
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials,
  callReport: state.persisted.callReport
})

export default connect(mapStateToProps)(withModalHandler(CallReport));
