import strings from '../../../Localization/Localization';

import { conf as quoteConf } from '../../Configuration/quotes';
import { conf as orderConf } from '../../Configuration/orders';
import { conf as serviceConf } from '../../Configuration/services';
import { conf as billConf } from '../../Configuration/bills';

import {
  technicalTicketConf,
  commercialTicketConf,
  administrativeTicketConf
} from '../../Configuration/tickets';

import {
  quotes as quotesRoutes,
  orders as ordersRoutes,
  commercialTicket as commercialTicketRoutes,
  services as serviceRoutes,
  technicalTicket as technicalTicketRoutes,
  administrativeTicket as administrativeTicketRoutes,
  bills as billRoutes
} from '../../Route/routes';

import { ParameterTypes } from '../../../Dashboard/Constant';

import { transformFieldToParamaterChoice } from './common';

// import { technicalTicketRoute } from '../../Route/main';

// const techincalTicketSearchForm = ticketSearchForm('technical');

export const list = {
  id: "list",
  name: strings.dashboard.widget.list.name,
  icon: "icon-view-list",
  column: {
    min: 2,
    max: 4
  },
  row: {
    min: 2,
    max: 4
  },
  elementSettings: {
    quote: {
      name: "Devis",
      fetch: quoteConf.index.api,
      link: () => quotesRoutes.routes.index.createPath(),
      entityLink: (id) => quotesRoutes.routes.show.createPath(id),
      headers: quoteConf.index.itemDisplay.tab.headers,
      filters: {
        formKey: quoteConf.index.searchForm.formKey,
        fields: quoteConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "state":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: quoteConf.index.computeSearch,

      role: "ROLE_QUOTE"
    },
    order: {
      name: "Commande",
      fetch: orderConf.index.api,
      link: () => ordersRoutes.routes.index.createPath(),
      entityLink: (id) => ordersRoutes.routes.show.createPath(id),
      headers: orderConf.index.itemDisplay.tab.headers,
      filters: {
        formKey: orderConf.index.searchForm.formKey,
        fields: orderConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "state":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: orderConf.index.computeSearch,

      role: "ROLE_ORDER"
    },
    commercialTicket: {
      name: "Ticket commercial",
      fetch: commercialTicketConf.index.api,
      link: () => commercialTicketRoutes.routes.index.createPath(),
      entityLink: (id) => commercialTicketRoutes.routes.show.createPath(id),
      headers: commercialTicketConf.index.itemDisplay.tab.headers,
      filters: {
        formKey: commercialTicketConf.index.searchForm.formKey,
        fields: commercialTicketConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "criticity":
            case "state":
            case "escalate":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            case "referee":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.USER, true);
            default:
          }
          return field
        })
      },
      computeFilters: commercialTicketConf.index.computeSearch,

      role: ["ROLE_USER", "ROLE_NYUKOM"]
    },
    service: {
      name: "Parc",
      fetch: serviceConf.index.api,
      link: () => serviceRoutes.routes.index.createPath(),
      entityLink: (id) => serviceRoutes.routes.show.createPath(id),
      headers: serviceConf.index.itemDisplay.tab.headers,
      filters: {
        formKey: serviceConf.index.searchForm.formKey,
        fields: serviceConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "serialNumber":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "state":
              return transformFieldToParamaterChoice(field);
            case "partner":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: serviceConf.index.computeSearch,

      role: ["ROLE_USER", "ROLE_NYUKOM"]
    },
    technicalTicket: {
      name: "Ticket technique",
      fetch: technicalTicketConf.index.api,
      link: () => technicalTicketRoutes.routes.index.createPath(),
      entityLink: (id) => technicalTicketRoutes.routes.show.createPath(id),
      headers: technicalTicketConf.index.itemDisplay.tab.headers,
      filters: {
        formKey: technicalTicketConf.index.searchForm.formKey,
        fields: technicalTicketConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "criticity":
            case "state":
            case "escalate":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            case "referee":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.USER, true);
            default:
          }
          return field
        })
      },
      computeFilters: technicalTicketConf.index.computeSearch,

      role: ["ROLE_USER", "ROLE_NYUKOM"]
    },
    administrativeTicket: {
      name: "Ticket administratif",
      fetch: administrativeTicketConf.index.api,
      link: () => administrativeTicketRoutes.routes.index.createPath(),
      entityLink: (id) => administrativeTicketRoutes.routes.show.createPath(id),
      headers: administrativeTicketConf.index.itemDisplay.tab.headers,
      filters: {
        formKey: administrativeTicketConf.index.searchForm.formKey,
        fields: administrativeTicketConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "criticity":
            case "state":
            case "escalate":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            case "referee":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.USER, true);
            default:
          }
          return field
        })
      },
      computeFilters: administrativeTicketConf.index.computeSearch,

      role: ["ROLE_USER", "ROLE_NYUKOM"]
    },
    bill: {
      name: "Facture",
      fetch: billConf.index.api,
      link: () => billRoutes.routes.index.createPath(),
      entityLink: (id) => billRoutes.routes.show.createPath(id),
      headers: billConf.index.itemDisplay.tab.headers,
      filters: {
        formKey: billConf.index.searchForm.formKey,
        fields: billConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "partner":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: billConf.index.computeSearch,

      role: "ROLE_ACCOUNTING_BILL"
    },
  },
  visualSettings: [
    {
      fieldKey: "headers",
      label: "Entête",
      type: "dropdownMulti",
      showOnDisabled: () => false,
      validators: [],

      input: 'formValues',
      finalValues: 'values',
      compute: (getValues, extraValues) => {
        let element = getValues().element;
        if(element && list.elementSettings[element]) {
          return list.elementSettings[element].headers;
        }
        return []
      },
      adapter: {
        requirement: (data) => data["id"] && data["label"],
        getValue: (data) => data["id"],
        getLabel: (data) => data["label"]
      },
    },
    {
      fieldKey: "order",
      label: "Ordre d'affichage",
      type: "dropdown",

      validators: [],

      input: 'options',
      options: [
        {
          value: "ASC",
          label: "Ascendant"
        },
        {
          value: "DESC",
          label: "Descendant"
        },
      ],
    }
  ],
  dataSettings: [
    {
      fieldKey: "filters",
      type: "configurableField",
      label: "",
      validators: [],

      subField: (value, getValues, getExtraValues) => {
        let element = getValues().element;
        if(element && list.elementSettings[element]) {
          return {
            subForm: list.elementSettings[element].filters
          }
        }
        return {
          subForm: {
            fields: {}
          }
        }
      },
      subFieldConf: {
        label: () => "",
        type: () => "subform",
        disabled: () => false,
        validators: () => [],
      }
    }
  ]
}
