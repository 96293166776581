import common from './common';
import form from './form';
import echart from './echart';

import dashboard from './dashboard';

import callReport from './callReport';

import login from './login';
import user from './user';
import profil from './profil';

import partner from './partner';
import partnerType from './partnerType';
import place from './place';

import product from './product';
import productFamily from './productFamily';
import productSubFamily from './productSubFamily';
import productDependency from './productDependency';
import dependency from './dependency';
import constructor from './constructor';
import cart from './cart';
import order from './order';
import quote from './quote';
import service from './service';
import amendment from './amendment';

import accountProduct from './accountProduct';
import accountAnalytic from './accountAnalytic';
import accountVat from './accountVat';
import accountingType from './accountingType';
import bill from './bill';

import billing from './billing';

import ticketForm from './ticketForm';
import ticketType from './ticketType';
import ticket from './ticket';
import technicalTicket from './technicalTicket';
import commercialTicket from './commercialTicket';
import administrativeTicket from './administrativeTicket';
import appointmentType from './appointmentType';
import preFillComment from './preFillComment';
import ticketTimeZone from './ticketTimeZone';

import phoneCDR from './phoneCDR';
import phonePackage from './phonePackage';
import phonePrefix from './phonePrefix';
import phonePriceList from './phonePriceList';
import phoneZoneGroup from './phoneZoneGroup';
import phoneZone from './phoneZone';

import commercialBonus from './commercialBonus';
import commercialBonusThreshold from './commercialBonusThreshold';

import _document from './document';

import parameter from './parameter';
import log from './log';

import yousign from './yousign';

const fr = {
  common: common,
  form: form,
  echart: echart,

  dashboard: dashboard,

  callReport: callReport,

  login: login,
  user: user,
  profil: profil,

  partner: partner,
  partnerType: partnerType,
  place: place,

  product: product,
  productFamily: productFamily,
  productSubFamily: productSubFamily,
  productDependency: productDependency,
  dependency: dependency,
  constructor: constructor,
  cart: cart,
  order: order,
  quote: quote,
  service: service,
  amendment: amendment,

  accountProduct: accountProduct,
  accountAnalytic: accountAnalytic,
  accountVat: accountVat,
  accountingType: accountingType,
  bill: bill,

  billing: billing,

  ticketForm: ticketForm,
  ticketType: ticketType,
  ticket: ticket,
  technicalTicket: technicalTicket,
  commercialTicket: commercialTicket,
  administrativeTicket: administrativeTicket,
  appointmentType: appointmentType,
  preFillComment: preFillComment,
  ticketTimeZone: ticketTimeZone,

  phoneCDR: phoneCDR,
  phonePackage: phonePackage,
  phonePrefix: phonePrefix,
  phonePriceList: phonePriceList,
  phoneZoneGroup: phoneZoneGroup,
  phoneZone: phoneZone,

  commercialBonus: commercialBonus,
  commercialBonusThreshold: commercialBonusThreshold,

  document: _document,

  parameter: parameter,
  log: log,

  yousign: yousign,
};

export default fr;
