import React from 'react';
import BasicFormField from '../../BasicFormField';

import { Form, Row, Col } from 'react-bootstrap';
import '../../../../../css/form.css';

/**
 * FormFieldColor
 *
 * This class handles HTML form field.
 *
 * The particular field is for color picker.
 *
 * Specific configuration attribute : none
 */
class DashboardFormFieldSwitch extends BasicFormField {

  getValue() {
    switch (super.getValue()) {
      case false:
        return 0;
      case null:
        return 1;
      case true:
        return 2;
      default:
      return 1;
    }
  }

  onChange(value) {
    switch (value) {
      case '0':
        return super.onChange(false);
      case '1':
        return super.onChange(null);
      case '2':
        return super.onChange(true);
      default:
        return super.onChange(null);
    }
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    switch (this.getValue()) {
      case 0:
        return false;
      case 1:
        return null;
      case 2:
        return true;
      default:
      return null;
    }
  }

  getColorClassValue() {
    switch (this.getValue()) {
      case 0:
        return "form-control-range-triswitch-false";
      case 2:
        return "form-control-range-triswitch-true";
      case 1:
      default:
      return "";
    }
  }

  displayInput() {
    return (
      <Form.Control
        className={`form-control-range-triswitch ${this.getColorClassValue(this.getValue())}`}
        placeholder={this.props.label}
        aria-label={this.props.label}
        aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
        type="range"
        min={0}
        max={2}
        disabled={this.props.disabled || this.props.submitting}
        value={this.getValue()}
        onChange={(event) => this.onChange(event.target.value)}
        isValid={(this.props.disabled)?null:this.isValid()}
        isInvalid={(this.props.disabled)?null:this.isInvalid()}
      />
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className={`dashboard-form-row${!this.props.skipSeparator?" dashboard-form-row-separator":""}`}>
        <Row className="d-flex align-items-center no-gutters">
          {this.props.label !== ""?
            <Col xs="auto" className="no-gutters dashboard-field-triswitch-label">
              {this.props.label}&nbsp;{this.displayValidating()}&nbsp;&nbsp;&nbsp;{this.shouldDisplayMiddlePart()?<>&nbsp;&nbsp;&nbsp;{this.displayMiddlePart()}</>:''}
            </Col>
          :undefined}
          {this.props.label === "" && this.shouldDisplayMiddlePart()?
            <>
              <Col xs={12} className="no-gutters dashboard-field-triswitch-label">
                {this.displayValidating()}{this.displayMiddlePart()}
              </Col>
            </>
          :undefined}
          <Col xs={this.props.label === ""?12:""} className="no-gutters">
            {this.displayInput()}
          </Col>
        </Row>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    )
  }
}

export default DashboardFormFieldSwitch;
