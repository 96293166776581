import _default from './form/default';

import _common from './form/common';

import login from './form/login';
import user from './form/user';
import profil from './form/profil';

import partner from './form/partner';
import partnerType from './form/partnerType';
import partnerAccount from './form/partnerAccount';
import specificPrice from './form/specificPrice';
import place from './form/place';
import bankInformation from './form/bankInformation';

import product from './form/product';
import buyingPrice from './form/buyingPrice';
import productDependency from './form/productDependency';
import productFamily from './form/productFamily';
import productFamilyVat from './form/productFamilyVat';
import productSubFamily from './form/productSubFamily';
import datasheet from './form/datasheet';
import technicalData from './form/technicalData';
import dependency from './form/dependency';
import constructor from './form/constructor';
import contract from './form/contract';
import order from './form/order';
import quote from './form/quote';
import service from './form/service';
// import client from './form/client';
import cart from './form/cart';
import cartItem from './form/cartItem';
import amendment from './form/amendment';

import accountProduct from './form/accountProduct';
import accountAnalytic from './form/accountAnalytic';
import accountVat from './form/accountVat';
import accountingType from './form/accountingType';
import bill from './form/bill';

import billing from './form/billing';

import ticketType from './form/ticketType';
import ticket from './form/ticket';
import ticketForm from './form/ticketForm';
import ticketFormField from './form/ticketFormField';
import ticketFormFieldDropdownElement from './form/ticketFormFieldDropdownElement';
import appointment from './form/appointment';
import appointmentType from './form/appointmentType';
import appointmentProduct from './form/appointmentProduct';
import comment from './form/comment';
import preFillComment from './form/preFillComment';
import ticketFile from './form/ticketFile';
import ticketFormAnswer from './form/ticketFormAnswer';
import ticketTimeZone from './form/ticketTimeZone';

import phoneCDR from './form/phoneCDR';
import phonePackage from './form/phonePackage';
import phonePackageProduct from './form/phonePackageProduct';
import phonePackageOrder from './form/phonePackageOrder';
import phonePrefix from './form/phonePrefix';
import phonePriceList from './form/phonePriceList';
import phonePrice from './form/phonePrice';
import phoneZoneGroup from './form/phoneZoneGroup';
import phoneZone from './form/phoneZone';
import specificPhonePriceList from './form/specificPhonePriceList';

import commercialBonus from './form/commercialBonus';
import commercialBonusThreshold from './form/commercialBonusThreshold';

import _document from './form/document';

import callReport from './form/callReport';

import centreonChart from './form/centreonChart';

import notification from './form/notification';
import log from './form/log';
import parameter from './form/parameter';

const form = {
  default: _default,
  common: _common,

  login: login,
  user: user,
  profil: profil,

  partner: partner,
  partnerType: partnerType,
  partnerAccount: partnerAccount,
  specificPrice: specificPrice,
  place: place,
  bankInformation: bankInformation,

  product: product,
  buyingPrice: buyingPrice,
  productDependency: productDependency,
  productFamily: productFamily,
  productFamilyVat: productFamilyVat,
  productSubFamily: productSubFamily,
  datasheet: datasheet,
  technicalData: technicalData,
  dependency: dependency,
  constructor: constructor,
  contract: contract,
  order: order,
  service: service,
  quote: quote,
  // client: client,
  cart: cart,
  cartItem: cartItem,
  amendment: amendment,

  accountProduct: accountProduct,
  accountAnalytic: accountAnalytic,
  accountVat: accountVat,
  accountingType: accountingType,
  bill: bill,

  billing: billing,

  ticketType: ticketType,
  ticket: ticket,
  ticketForm: ticketForm,
  ticketFormField: ticketFormField,
  ticketFormFieldDropdownElement: ticketFormFieldDropdownElement,
  appointment: appointment,
  appointmentType: appointmentType,
  appointmentProduct: appointmentProduct,
  comment: comment,
  preFillComment: preFillComment,
  ticketFile: ticketFile,
  ticketFormAnswer: ticketFormAnswer,
  ticketTimeZone: ticketTimeZone,

  phoneCDR: phoneCDR,
  phonePackage: phonePackage,
  phonePackageProduct: phonePackageProduct,
  phonePackageOrder: phonePackageOrder,
  phonePrefix: phonePrefix,
  phonePriceList: phonePriceList,
  phonePrice: phonePrice,
  phoneZoneGroup: phoneZoneGroup,
  phoneZone: phoneZone,
  specificPhonePriceList: specificPhonePriceList,

  commercialBonus: commercialBonus,
  commercialBonusThreshold: commercialBonusThreshold,

  document: _document,

  callReport: callReport,

  centreonChart: centreonChart,

  notification: notification,
  log: log,
  parameter: parameter,
};

export default form;
