import strings from '../../../Localization/Localization';

import { fetchPartners } from '../../../API/Partners';
import {
  fetchMainUsers,
  fetchPartnerUsers
} from '../../../API/Users';

import { timeframe } from '../common';

let inout = {
  fieldKey: "inout",
  type: "dropdown",
  label: strings.form.callReport.inout.label,
  showOnDisabled: () => false,

  validators: [],

  input: 'options',
  showEmpty: true,
  options: [
    {
      value: 'in',
      label: strings.form.callReport.inout.options.in
    },
    {
      value: 'out',
      label: strings.form.callReport.inout.options.out
    }
  ]
}

let result = {
  fieldKey: "result",
  type: "dropdown",
  label: strings.form.callReport.result.label,
  showOnDisabled: () => false,

  validators: [],

  input: 'options',
  showEmpty: true,
  options: [
    {
      value: 'finished',
      label: strings.form.callReport.result.options.finished
    },
    {
      value: 'failed',
      label: strings.form.callReport.result.options.failed
    },
    {
      value: 'callbackRequest',
      label: strings.form.callReport.result.options.callbackRequest
    },
    {
      value: 'callback',
      label: strings.form.callReport.result.options.callback
    },
    {
      value: 'transfer',
      label: strings.form.callReport.result.options.transfer
    }
  ]
}

let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.callReport.number.label,
  showOnDisabled: () => false,
  validators: []
}

let operator = {
  fieldKey: "operator",
  type: "dropdownEntity",
  label: strings.form.callReport.operator.label,
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "operator",
    loader: fetchMainUsers,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let user = {
  fieldKey: "user",
  type: "dropdownEntity",
  label: strings.form.callReport.user.label,
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "user",
    loader: fetchPartnerUsers,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let partner = {
  fieldKey: "partner",
  label: strings.form.callReport.partner.label,
  type: "dropdownEntity",
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "partner",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let beginAtMin = {
  fieldKey: "beginAtMin",
  type: "date",
  label: strings.form.callReport.beginAtMin.label,
  showOnDisabled: () => false,
  validators: []
}

let beginAtMax = {
  fieldKey: "beginAtMax",
  type: "date",
  label: strings.form.callReport.beginAtMax.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "callReportSearch",
  fields: [
    inout,
    result,
    operator,
    number,
    user,
    partner,
    Object.assign({}, timeframe, {
      fieldKey: "beginAtTimeframe",
      label: `${strings.form.common.timeframe.label} ${strings.form.callReport.beginAtTimeframe.label}`
    }),
    beginAtMin,
    beginAtMax
  ]
}
