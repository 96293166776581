const common = {
  mainMenu: {
    home: 'Home',
    dashboard: 'Dashboard',
    profil: 'Profil',
    user: {
      index: 'Utilisateurs',
      indexClient: "Mes Utilisateurs"
    },
    callReport: {
      index: "Rapport d'appel",
      new: "Nouveau",
      archive: "Historique"
    },
    partner: {
      global: 'Société',
      index: 'Partenaires',
      globalClient: "Mon Profil Entreprise",
      indexClient: "Mes Entreprises"
    },
    place: {
      index: 'Sites',
      indexClient: "Mes Sites"
    },
    profils: {
      index: 'Profils Utilisateur',
    },
    product: {
      global: 'Catalogue',
      index: 'Produits',
      indexClient: "Mon Catalogue Produit"
    },
    productFamily: {
      index: 'Familles de produit'
    },
    productSubFamily: {
      index: 'Sous-familles de produit'
    },
    dependencies: {
      index: 'Dépendances'
    },
    productDependencies: {
      index: 'Dépendances produit'
    },
    phoneSystem: {
      global: 'Téléphonie'
    },
    phonePackage: {
      index: 'Forfaits'
    },
    phonePriceList: {
      index: 'Grilles tarifaires'
    },
    phoneZone: {
      index: 'Zones'
    },
    phoneZoneGroup: {
      index: 'Groupes de zones'
    },
    phonePrefix: {
      index: 'Préfixes'
    },
    phoneErrorProcessing: {
      index: 'Traitement erreur'
    },
    order: {
      global: 'Suivi Commercial',
      index: 'Commandes',
      indexClient: 'Mes Commandes en cours'
    },
    quote: {
      index: 'Devis',
      indexClient: 'Mes Devis'
    },
    commercialBonus: {
      index: 'Commissions',
    },
    commercialBonusThreshold: {
      index: 'Seuil de commissions',
    },
    service: {
      index: 'Parc',
      indexClient: 'Mon Parc'
    },
    cdr: {
      index: 'CDRs',
      // indexClient: 'Mon Parc'
    },
    accounting: {
      global: "Comptabilité"
    },
    bill: {
      index: 'Facture',
      indexClient: 'Mes Factures'
    },
    billing: {
      index: 'Facturation'
    },
    accountProduct: {
      index: 'Comptes Généraux'
    },
    accountAnalytic: {
      index: 'Comptes Analytiques'
    },
    accountVat: {
      index: 'Comptes TVA'
    },
    accountingType: {
      index: 'Type'
    },
    ticket: {
      global: "Suivi",
      globalClient: "Mes Demandes"
    },
    websiteAdministration: {
      global: 'Administration du site'
    },
    technicalTicket: {
      menu: 'Support Technique',
      index: 'Tickets',
      typeIndex: 'Types de ticket',
      appointmentTypeIndex: 'Types d\'intervention',
      formIndex: 'Formulaires',
      preFillCommentIndex: 'Commentaires pré-rempli',
      ticketTimeZone: 'Zones horaires'
    },
    technicalGlobalTicket: {
      menu: 'Support Technique G',
      index: 'Tickets',
      typeIndex: 'Types de ticket',
      appointmentTypeIndex: 'Types d\'intervention',
      formIndex: 'Formulaires',
      preFillCommentIndex: 'Commentaires pré-rempli',
      ticketTimeZone: 'Zones horaires'
    },
    commercialTicket: {
      menu: 'Support Commercial',
      index: 'Tickets',
      typeIndex: 'Types de ticket',
      appointmentTypeIndex: 'Types d\'intervention',
      formIndex: 'Formulaires',
      preFillCommentIndex: 'Commentaires pré-rempli',
    },
    installationTicket: {
      menu: 'Installation',
      index: 'Tickets',
      typeIndex: 'Types de ticket',
      appointmentTypeIndex: 'Types d\'intervention',
      formIndex: 'Formulaires',
      preFillCommentIndex: 'Commentaires pré-rempli',
    },
    document: {
      index: "Document",
      indexClient: "Document"
    },
    administrativeTicket: {
      menu: 'Support Administratif',
      index: 'Tickets',
      typeIndex: 'Types de ticket',
      appointmentTypeIndex: 'Types d\'intervention',
      formIndex: 'Formulaires',
      preFillCommentIndex: 'Commentaires pré-rempli',
    },
    logIndex: 'Logs',
    partnerType: {
      index: "Type de partenaire"
    },
    constructor: {
      index: "Constructeur"
    }
    /*
    home: 'Home',
    profil: 'Profil',
    user: {
      menu: 'Utilisateurs',
      create: 'Nouvel Utilisateur',
      index: 'Liste des Utilisateurs',
    },
    partner: {
      global: 'Société',
      menu: 'Partenaires',
      create: 'Nouveau Partenaire',
      index: 'Liste des Partenaires',
    },
    profils: {
      menu: 'Profils Utilisateur',
      create: 'Nouveau Profil',
      index: 'Liste des Profils',
    },
    product: {
      global: 'Catalogue',
      menu: 'Produits',
      create: 'Nouveau Produit',
      index: 'Liste des Produits'
    },
    productFamily: {
      menu: 'Famille de produit',
      create: 'Nouvelle Famille de produit',
      index: 'Liste des Familles de produit'
    },
    productSubFamily: {
      menu: 'Sous-famille de produit',
      create: 'Nouvelle Sous-famille de produit',
      index: 'Liste des Sous-familles de produit'
    },
    productDepdencies: {
      menu: 'Dépendance produit',
      create: 'Nouvelle Dépendance produit',
      index: 'Liste des Dépendances produit'
    },
    phoneSystem: {
      global: 'Téléphonie'
    },
    phonePackage: {
      menu: 'Forfait',
      create: 'Nouveau Forfait',
      index: 'Liste des Forfaits'
    },
    phonePriceList: {
      menu: 'Grille tarifaire',
      create: 'Nouvelle Grille tarifaire',
      index: 'Liste des Grilles tarifaires'
    },
    phoneZone: {
      menu: 'Zone',
      create: 'Nouvelle Zone',
      index: 'Liste des Zones'
    },
    phoneZoneGroup: {
      menu: 'Groupe de zones',
      create: 'Nouveau Groupe de zones',
      index: 'Liste des Groupes de zones'
    },
    phonePrefix: {
      menu: 'Préfixe',
      create: 'Nouveau Préfixe',
      index: 'Liste des Préfixes'
    },
    order: {
      global: 'Commandes',
      index: 'Liste des Commandes'
    },
    quote: {
      index: 'Liste des Devis'
    },
    service: {
      index: 'Parc'
    },
    accounting: {
      global: "Comptabilité"
    },
    bill: {
      menu: 'Facture',
      create: 'Nouvelle Facture',
      index: 'Liste des Factures'
    },
    billing: {
      index: 'Facturation'
    },
    account: {
      menu: 'Comptabilité',
      create: 'Nouveau Compte comptable',
      index: 'Liste des Comptes comptables'
    },
    accountProduct: {
      menu: 'Comptes Généraux',
      create: 'Nouveau',
      index: 'Liste'
    },
    accountAnalytic: {
      menu: 'Comptes Analytiques',
      create: 'Nouveau',
      index: 'Liste'
    },
    accountVat: {
      menu: 'Comptes TVA',
      create: 'Nouveau',
      index: 'Liste'
    },
    accountingType: {
      menu: 'Type',
      create: 'Nouveau',
      index: 'Liste'
    },
    ticket: {
      global: "Demandes et suivis"
    },
    websiteAdministration: {
      global: 'Administration du site'
    },
    technicalTicket: {
      menu: 'Support Technique',
      create: 'Nouveau Ticket',
      index: 'Liste des Tickets',
      typeCreate: 'Nouveau Type de ticket',
      typeIndex: 'Liste des Types de ticket',
      appointmentTypeCreate: 'Nouveau type d\'intervention',
      appointmentTypeIndex: 'Liste des types d\'intervention',
      formCreate: 'Nouveau Formulaire',
      formIndex: 'Liste des formulaires',
      preFillCommentCreate: 'Nouveau Commentaire pré-rempli',
      preFillCommentIndex: 'Liste des commentaires pré-rempli',
      ticketTimeZone: 'Liste des Zones horaires'
    },
    commercialTicket: {
      menu: 'Support Commercial',
      create: 'Nouveau Ticket',
      index: 'Liste des Tickets',
      typeCreate: 'Nouveau Type de ticket',
      typeIndex: 'Liste des Types de ticket',
      appointmentTypeCreate: 'Nouveau type d\'intervention',
      appointmentTypeIndex: 'Liste des types d\'intervention',
      formCreate: 'Nouveau Formulaire',
      formIndex: 'Liste des formulaires',
      preFillCommentCreate: 'Nouveau Commentaire pré-rempli',
      preFillCommentIndex: 'Liste des commentaires pré-rempli',
    },
    administrativeTicket: {
      menu: 'Support Administratif',
      create: 'Nouveau Ticket',
      index: 'Liste des Tickets',
      typeCreate: 'Nouveau Type de ticket',
      typeIndex: 'Liste des Types de ticket',
      appointmentTypeCreate: 'Nouveau type d\'intervention',
      appointmentTypeIndex: 'Liste des types d\'intervention',
      formCreate: 'Nouveau Formulaire',
      formIndex: 'Liste des formulaires',
      preFillCommentCreate: 'Nouveau Commentaire pré-rempli',
      preFillCommentIndex: 'Liste des commentaires pré-rempli',
    },
    notification: 'Liste des notifications',
    logIndex: 'Liste des logs',
    partnerType: {
      menu: "Type de partenaire",
      create: "Nouveau type de partenaire",
      index: "Liste des types de partenaires"
    },
    constructor: {
      menu: "Constructeur",
      create: "Nouveau constructeur",
      index: "Liste des constructeurs"
    }
    */
  },
  homepage: {
    users: "Utilisateurs",
    profil: "Mon profil",
    profils: "Profils utilisateur",

    partners: "Partenaires",
    places: "Sites",
    partnerTypes: "Types de partenaire",

    products: "Produits",
    productFamilies: "Familles de produit",
    productSubFamilies: "Sous familles de produit",
    productDependencies: "Dépendances produit",
    dependencies: "Dépendances",
    constructors: "Constructeurs produit",
    carts: "Paniers",
    orders: "Commandes",
    quotes: "Devis",
    services: "Services",

    accountAnalytics: "Comptes Analytiques",
    accountingTypes: "Types de compte comptable",
    accountVats: "Comptes de T.V.A.",
    accountProducts: "Comptabilité produit",
    bills: "Factures",
    billings: "Facturation",

    technicalTicket: "Support technique",
    technicalAppointmentType: "Type d'intervention technique",
    technicalPreFillComment: "Commentaire technique pré-rempli",
    technicalTicketForm: "Formulaire de ticket technique",
    technicalTicketTypes: "Type de ticket technique",
    ticketTimeZone: "Zone horaire des tickets",

    commercialTicket: "Suivi commercial",
    commercialAppointmentType: "Type d'intervention commerciale",
    commercialPreFillComment: "Commentaire commercial pré-rempli",
    commercialTicketForm: "Formulaire de ticket commercial",
    commercialTicketTypes: "Type de ticket commercial",

    administrativeTicket: "Suivi administratif",
    administrativeAppointmentType: "Type d'intervention administrative",
    administrativePreFillComment: "Commentaire administratif pré-rempli",
    administrativeTicketForm: "Formulaire de ticket administratif",
    administrativeTicketTypes: "Type de ticket administratif",

    installationTicket: "Suivi installation",
    installationAppointmentType: "Type d'intervention installation",
    installationPreFillComment: "Commentaire installation pré-rempli",
    installationTicketForm: "Formulaire de ticket installation",
    installationTicketTypes: "Type de ticket installation",

    terminationTicket: "Suivi des résiliation",
    terminationAppointmentType: "Type d'intervention résiliation",
    terminationPreFillComment: "Commentaire résiliation pré-rempli",
    terminationTicketForm: "Formulaire de ticket résiliation",
    terminationTicketTypes: "Type de ticket résiliation",

    technicalGlobalTicket: "Support technique global",
    technicalGlobalAppointmentType: "Type d'intervention technique global",
    technicalGlobalPreFillComment: "Commentaire technique global pré-rempli",
    technicalGlobalTicketForm: "Formulaire de ticket technique global",
    technicalGlobalTicketTypes: "Type de ticket technique global",

    phoneCDR: "CDRs",
    phonePackage: "Forfaits téléphonique",
    phonePrefix: "Préfixes téléphoniques",
    phonePriceList: "Grilles tarifaires",
    phoneZone: "Zones téléphoniques",
    phoneZoneGroup: "Groupes de zones téléphoniques",

    commercialBonus: "Commission (commercial)",
    commercialBonusThreshold: "Palier de Commission (commercial)",

    document: "Document",

    callReport: "Rapport d'appel",

    notifications: "Notifications",

    parameters: "Paramètres",
  },
  notification: {
    _empty: 'Aucune notification',
    _button: {
      allMarkAsSeen: "Tout marquer comme lu",
      index: "Toutes les notifications"
    },

    bill_new_client: 'Nouvelle facture {0}',

    ticket_new_client: 'Nouveau ticket {0}',
    ticket_new_nyukom: 'Nouveau ticket {0}',
    ticket_edit_client: 'Le ticket {0} a été modifié',
    ticket_comment_mixed: 'Nouveau commentaire sur le ticket {0}',
    ticket_appointment_client: 'Nouvelle intervention sur le ticket {0}',
    ticket_file_mixed: 'Nouveau fichier sur le ticket {0}',
    ticket_close_client: 'Le ticket {0} a été fermé',
    ticket_close_nyukom: 'Le ticket {0} a été fermé',
    ticket_assign_nyukom: 'Assignation au ticket {0}',
    ticket_escalate_nyukom: 'Le ticket {0} a été escaladé',
    ticket_escalate_client: 'Le ticket {0} a été escaladé',
    ticket_gfrt_nyukom: 'Notification GTR {1} ticket {0}',
    ticket_gfrt_timeout_nyukom: 'Dernière notification GTR ticket {0}',

    order_new_client: 'Nouvelle commande {0}',
    order_new_nyukom: 'Nouvelle commande {0}',
    order_validate_client: 'La commande {0} a été validée',
    order_cancellation_period_end_client: 'Le délai de retractation de la commande {0} est terminé',
    order_start_delivery_nyukom: 'La livraison de la commande {0} a commencé',
    order_deliver_client: 'La commande {0} a été livrée',
    order_cancel_client: 'La commande {0} a été annulée',
    order_cancel_nyukom: 'La commande {0} a été annulée',
    order_cancel_validated_client: 'La commande {0} a été annulée',
    order_cancel_validated_nyukom: 'La commande {0} a été annulée',

    quote_new_client: 'Nouveau devis {0}',
    quote_validated_client: 'Le devis {0} a été validé',
    quote_signed_nyukom: 'Le devis {0} a été signé',

    service_termination_client: "Service en cours de résiliation {0}",
    service_terminated_client: "Service résilié {0}",
    service_amendment_new_client: "Nouvel avenant sur service {0}",
    service_amendment_apply_client: "Avenant sur service appliqué {0}",
    service_amendment_new_apply_client: "Avenant sur service créé et appliqué {0}",

    user_new: 'Votre compte a été créé',
    user_reset_password: 'Un mail a été envoyé à votre adresse avec votre nouveau mot de passe.',

    report_new_nyukom: "Un nouveau rapport est arrivé",
  },
  header: {
    userName: "{0} {1}",
    profil: "Mon profil",
    logout: "Déconnexion"
  },
  footer: {
    copyright: "© NYUKOM - 2014 à 2024",
    version: "Version "
  },
  errorHandler: {
    check: {
      title: 'Vérification demandée',
      body: 'Une vérification est demandée pour l\'action que vous voulez effectuer.',
      buttons: {
        close: 'Annuler',
        validate: 'Valider'
      }
    },
    auth: {
      title: 'Authentification requise',
      body: '',
      buttons: {
        close: 'Fermer',
        validate: 'Valider'
      }
    },
    else: {
      title: 'Problème de serveur',
      body: 'Un problème est survenu au niveau de serveur, vous êtes invité à réessayer dans quelques minutes.',
      buttons: {
        close: 'Fermer',
        retry: 'Réessayer'
      }
    }
  },

  itemIndex: {
    totalDisplay: '{0} résultat(s)',
  },
  new: "Nouveau",

  units: {
    quote: "devis",
    order: "commande(s)",
    service: "service(s)",
    ticket: "ticket(s)",
    bill: "facture(s)",

    days: "jour(s)",
    euro: "€",
    none: ""
  },

  //snake case because given by backend API
  role_group: {
    admin: 'Groupe Administrateur',
    user: 'Groupe Utilisateur',
    partner: 'Groupe Partenaire',
    marketing: 'Groupe Marketing',
    technical_installation: 'Groupe Installation',
    technical_support: 'Groupe Support',
    accounting: 'Groupe Comptabilité',
    cart: 'Groupe Panier/Commande',
    cdr: 'Groupe CDR'
  },
  role: {
    //admin
    super_admin: 'Super Administrateur',
    admin: 'Administrateur (du site)',
    nyukom: 'Nyukom',
    user: 'Utilisateur',

    //user
    user_admin: 'Administrateur',
    user_create_inner: 'Création d\'utilisateur NK',
    user_create_partner: 'Création d\'utilisateur partenaire',
    user_index: 'Accès à l\'index',
    user_anonymize: 'Anonymisation',
    user_delete: 'Suppression',
    user_profils: 'Accès aux droits NK',
    user_profils_edit: 'Accès en écriture droits NK',
    user_partners: 'Accès aux partenaires',
    user_partners_edit: 'Accès en écriture aux partenaires',
    user_main_edit: 'Accès en écriture aux informations générales',
    user_escalate_level_technical: "Accès au niveaux d'escalade technique des utilisateurs",
    user_escalate_level_commercial: "Accès au niveaux d'escalade commercial des utilisateurs",
    user_escalate_level_administrative: "Accès au niveaux d'escalade administratif des utilisateurs",

    //partner
    partner_bank_information: 'Accès aux informations bancaires',
    partner_bank_information_edit: 'Accès en écriture aux informations bancaires',
    partner_specific_prices: 'Accès aux prix spécifiques',
    partner_specific_prices_edit: 'Accès en écriture aux prix spécifiques',
    accounting_partner: 'Accès à la comptabilité partenaire',
    accounting_partner_edit: 'Accès en écriture à la comptabilité partenaire',
    partner_accounts_edit: 'Accès en écriture aux comptes',
    partner_create: 'Création de nouveau partenaire',

    //product
    marketing_product_configuration: 'Accès à la configuration du produit',
    marketing_product_configuration_edit: 'Accès en écriture à la configuration du produit',
    marketing_product_price: 'Accès aux prix du produit',
    marketing_product_price_edit: 'Accès en écriture aux prix du produit',
    marketing_product_edit: 'Accès en écriture aux informations générales du produit',
    marketing_product_product_dependency_edit: 'Accès en écriture aux dépendances du produit',
    marketing_product_specific_conditions_of_sale_edit: 'Accès en écriture aux CPV du produit',
    marketing_product_create: 'Création d\'un produit',
    //product_sub_family
    marketing_product_sub_family_configuration: 'Accès à la configuration de la sous-famille de produit',
    marketing_product_sub_family_configuration_edit: 'Accès en écriture à la configuration de la sous-famille de produit',
    technical_product_sub_family_datasheet: 'Accès à la datasheet de la sous-famille de produit',
    technical_product_sub_family_datasheet_edit: 'Accès en écriture à la datasheet de la sous-famille de produit',
    technical_product_sub_family_technical_data: 'Accès aux données techniques de la sous-famille de produit',
    technical_product_sub_family_technical_data_edit: 'Accès en écriture aux données techniques de la sous-famille de produit',
    marketing_product_sub_family_specific_conditions_of_sale_edit: 'Accès en écriture aux CPV de la sous-famille de produit',
    marketing_product_sub_family_edit: 'Accès en écriture aux informations générales de la sous-famille de produit',
    marketing_product_sub_family_create: 'Création d\'une sous-famille de produit',
    //product_family
    marketing_product_family_configuration: 'Accès à la configuration de la famille de produit',
    marketing_product_family_configuration_edit: 'Accès en écriture à la configuration de la famille de produit',
    accounting_product_family_accounting: 'Accès aux comptes comptables de la famille de produit',
    accounting_product_family_accounting_edit: 'Accès en écriture aux comptes comptables de la famille de produit',
    marketing_product_family_edit: 'Accès en écriture aux informations générales de la famille de produit',
    marketing_product_family_create: 'Création d\'une famille de produit',
    //product_dependency
    marketing_product_dependency_edit: 'Accès en écriture aux dépendances inter produit',
    marketing_product_dependency_create: 'Création d\'une nouvelle dépendance inter produit',
    //cart
    cart: 'Accès aux paniers',
    //order
    order: 'Accès aux commandes',
    order_create: 'Création d\'une commande',
    quote: 'Accès aux devis',
    quote_create: 'Création d\'un devis',
    quote_create_with_discount: 'Création d\'un devis (avec promotion)',
    project_create: 'Création d\'un projet',
    quote_adding_agreeement: 'Ajout d\'un BpA',
    quote_validating_agreement: 'Validation d\'un BpA',
    quote_cancel_quote: 'Annulation d\'un devis',
    quote_uncancel_quote: 'Désannulation d\'un devis',
    quote_invalidating_agreement: 'Suppression d\'un BpA',
    order_validating_order: 'Validation d\'une commande',
    order_cancellation_period_end: 'Validation de la fin de la période de retractation de commande',
    order_advance_payment_paid: 'Validation de la première facture',
    order_cancel_order: 'Annulation d\'une commande',
    order_cancel_validated_order: 'Annulation d\'une commande validée',
    order_cancel_validated_order_no_bill: 'Annulation d\'une commande validée (sans frais)',
    order_deliver: 'Livraison d\'une commande',
    quote_create_amendment: 'Création d\'avenant (devis)',
    order_create_amendment: 'Création d\'avenant (commande)',
    //service
    service_phone_package_api: 'Accès aux clé API de téléphonie',
    service_disable: 'Désactivation d\'un service',
    service_termination: 'Lancer la résiliation d\'un service',
    service_cancel_termination: 'Annuler la résiliation d\'un service',
    service_terminated: 'Valider la résiliation d\'un service',
    service_terminated_no_bill: 'Valider la résiliation d\'un service (sans frais)',
    service_amendment: 'Création d\'avenant (global)',
    service_amendment_service_price: 'Création d\'avenant (Service - Prix)',

    service_phone_package_order: 'Accès au données des forfaits téléphoniques (dans les services)',
    service_phone_package_order_consumption: 'Accès au données des forfaits téléphoniques - Consommations (dans les services)',
    service_phone_package_order_specific_price_list: 'Accès au données des forfaits téléphoniques - Grille tarifaire spécifique (dans les services)',
    service_phone_package_order_specific_price_list_edit: 'Accès en écriture des données des forfaits téléphoniques - Grille tarifaire spécifique (dans les services)',
    service_phone_package_order_activation: 'Accès à la gestion des données des forfaits téléphoniques - création/désactivation/réactivation (dans les services)',

    //ticket
    //technical_ticket
    technical_ticket_create: 'Création d\'un ticket',
    technical_ticket_edit: 'Accès en écriture aux tickets',
    technical_ticket_comment_inner_create: 'Création d\'un commentaire interne à NK',
    technical_ticket_comment_create: 'Création d\'un commentaire',
    technical_ticket_comment_inner_publish: 'Publier un commentaire interne',
    technical_ticket_comment_inner_unpublish: 'Dé-publier un commentaire interne',
    technical_ticket_pre_fill_comment: 'Accès aux commentaires pré-construit',
    technical_ticket_task_create: 'Création d\'une tâche',
    technical_ticket_task_edit: 'Modification d\'une tâche',
    technical_ticket_task_remove: 'Suppression d\'une tâche',
    technical_ticket_file_create: 'Création d\'un fichier',
    technical_ticket_file_remove: 'Suppression d\'un fichier',
    technical_ticket_appointment_create: 'Création d\'une intervention',
    technical_ticket_appointment_sign: 'Signature d\'une intervention',
    technical_ticket_form_answer_create: 'Création d\'un formulaire de réponse',
    technical_ticket_form_answer_edit: 'Complétion d\'un formulaire de réponse',
    technical_ticket_type: 'Accès aux types de ticket',
    technical_ticket_appointment_type: 'Accès aux types d\'intervention',
    ticket_time_zone: 'Accès aux zones temporelles',
    technical_ticket_form_create: 'Création d\'un formulaire',
    technical_ticket_form_edit: 'Modification d\'un formulaire',
    technical_ticket_form_remove: 'Suppression d\'un formulaire',
    technical_ticket_notification: 'Notification des tickets',
    technical_ticket_escalate: 'Escalade des tickets',
    technical_ticket_freeze_gfrt_notification: "Accès au gel des notifications de GTR",
    service_technical_data: 'Accès aux données techniques',
    service_technical_data_edit: 'Accès en écriture aux données techniques',
    technical_ticket_manager: 'Manager des tickets',
    //global_ticket
    global_technical_ticket_create: 'Création d\'un ticket',
    global_technical_ticket_edit: 'Accès en écriture aux tickets',
    global_technical_ticket_comment_inner_create: 'Création d\'un commentaire interne à NK',
    global_technical_ticket_comment_create: 'Création d\'un commentaire',
    global_technical_ticket_comment_inner_publish: 'Publier un commentaire interne',
    global_technical_ticket_comment_inner_unpublish: 'Dé-publier un commentaire interne',
    global_technical_ticket_task_create: 'Création d\'une tâche',
    global_technical_ticket_task_edit: 'Modification d\'une tâche',
    global_technical_ticket_task_remove: 'Suppression d\'une tâche',
    //commercial_ticket
    commercial_ticket_create: 'Création d\'un ticket',
    commercial_ticket_edit: 'Accès en écriture aux tickets',
    commercial_ticket_comment_inner_create: 'Création d\'un commentaire interne à NK',
    commercial_ticket_comment_create: 'Création d\'un commentaire',
    commercial_ticket_comment_inner_publish: 'Publier un commentaire interne',
    commercial_ticket_comment_inner_unpublish: 'Dé-publier un commentaire interne',
    commercial_ticket_pre_fill_comment: 'Accès aux commentaires pré-construit',
    commercial_ticket_task_create: 'Création d\'une tâche',
    commercial_ticket_task_edit: 'Modification d\'une tâche',
    commercial_ticket_task_remove: 'Suppression d\'une tâche',
    commercial_ticket_file_create: 'Création d\'un fichier',
    commercial_ticket_file_remove: 'Suppression d\'un fichier',
    commercial_ticket_appointment_create: 'Création d\'une intervention',
    commercial_ticket_appointment_sign: 'Signature d\'une intervention',
    commercial_ticket_form_answer_create: 'Création d\'un formulaire de réponse',
    commercial_ticket_form_answer_edit: 'Complétion d\'un formulaire de réponse',
    commercial_ticket_type: 'Accès aux types de ticket',
    commercial_ticket_appointment_type: 'Accès aux types d\'intervention',
    commercial_ticket_form_create: 'Création d\'un formulaire',
    commercial_ticket_form_edit: 'Modification d\'un formulaire',
    commercial_ticket_form_remove: 'Suppression d\'un formulaire',
    commercial_ticket_notification: 'Notification des tickets',
    commercial_ticket_manager: 'Manager des tickets',
    commercial_ticket_escalate: 'Escalade des tickets',
    //administrative_ticket
    administrative_ticket_create: 'Création d\'un ticket',
    administrative_ticket_edit: 'Accès en écriture aux tickets',
    administrative_ticket_comment_inner_create: 'Création d\'un commentaire interne à NK',
    administrative_ticket_comment_create: 'Création d\'un commentaire',
    administrative_ticket_comment_inner_publish: 'Publier un commentaire interne',
    administrative_ticket_comment_inner_unpublish: 'Dé-publier un commentaire ',
    administrative_ticket_pre_fill_comment: 'Accès aux commentaires pré-construit',
    administrative_ticket_task_create: 'Création d\'une tâche',
    administrative_ticket_task_edit: 'Modification d\'une tâche',
    administrative_ticket_task_remove: 'Suppression d\'une tâche',
    administrative_ticket_file_create: 'Création d\'un fichier',
    administrative_ticket_file_remove: 'Suppression d\'un fichier',
    administrative_ticket_appointment_create: 'Création d\'une intervention',
    administrative_ticket_appointment_sign: 'Signature d\'une intervention',
    administrative_ticket_form_answer_create: 'Création d\'un formulaire de réponse',
    administrative_ticket_form_answer_edit: 'Complétion d\'un formulaire de réponse',
    administrative_ticket_type: 'Accès aux types de ticket',
    administrative_ticket_appointment_type: 'Accès aux types d\'intervention',
    administrative_ticket_form_create: 'Création d\'un formulaire',
    administrative_ticket_form_edit: 'Modification d\'un formulaire',
    administrative_ticket_form_remove: 'Suppression d\'un formulaire',
    administrative_ticket_notification: 'Notification des tickets',
    administrative_ticket_manager: 'Manager des tickets',
    administrative_ticket_escalate: 'Escalade des tickets',

    //centreon
    centreon: "Accès à la supervision",
    centreon_configuration: "Accès à la configuration de la supervision",
    centreon_admin: "Accès à l'administration de la supervision",

    //accounting
    accounting_account: 'Accès aux comptes comptables',
    accounting_bill: 'Accès aux factures',
    accounting_bill_account_line: 'Accès aux lignes comptables des factures',
    accounting_bill_received_payment: 'Accès aux paiements effectués sur une facture',
    accounting_bill_received_payment_edit: 'Accès en écriture aux paiements effectués sur une facture',
    accounting_bill_create: 'Création global de facture',
    accounting_bill_create_from_order: 'Création de facture à partir d\'une commande',
    accounting_bill_create_from_ticket: 'Création de facture à partir d\'un ticket',
    accounting_bill_create_free_line: 'Création de facture à ligne libre',
    accounting_bill_create_credit_note: 'Création d\'un avoir à partir d\'une facture',
    accounting_bill_extract: 'Accès aux extracts comptables',

    //cdr
    cdr: 'Accès au journal des consommations',
    cdr_extract: 'Accès à l\'extract du journal des consommations',
    cdr_phone_package: 'Accès aux forfaits téléphoniques',
    cdr_phone_package_create: 'Création d\'un forfait téléphonique',
    cdr_phone_package_edit: 'Accès en écriture aux forfaits téléphoniques',
    cdr_configuration: 'Accès à la configuration des préfixes, zones et groupes de zones',
    cdr_price_list: 'Accès à la liste des grilles tarifaires',
    cdr_price_list_create: 'Création d\'une grille tarifaire',
    cdr_price_list_edit: 'Accès en écriture aux grilles tarifaires',
    cdr_price_list_price_edit: 'Accès en écriture aux prix des grilles tarifaires',
    cdr_tranfer: 'Accès aux transferts de CDRs',
    cdr_wipe_calculation: 'Accès à la demande de refacturation des CDRs',
    cdr_tech_error_processing: 'Accès à la correction d\'erreur des CDRs',
    cdr_tech_error_report: 'Notification des rapports des CDRs',
  }
};

export default common;
