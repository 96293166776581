import strings from '../../Localization/Localization';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.ticketType.name.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.ticketType.name.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let billable = {
  fieldKey: "billable",
  type: "switch",
  label: strings.form.ticketType.billable.label,
  validators: []
}

let escalatable = {
  fieldKey: "escalatable",
  type: "switch",
  label: strings.form.ticketType.escalatable.label,
  validators: []
}

let hiddenFromPartner = {
  fieldKey: "hidden_from_partner",
  type: "switch",
  label: strings.form.ticketType.hiddenFromPartner.label,
  validators: []
}

let enableGfrtNotification = {
  fieldKey: "enable_gfrt_notification",
  type: "switch",
  label: strings.form.ticketType.enableGfrtNotification.label,
  validators: []
}

let description = {
  fieldKey: "description",
  type: "textarea",
  label: strings.form.ticketType.description.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.ticketType.description.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

export const form = {
  formKey: "ticketType",
  fields: [
    name,
    // group,
    billable,
    escalatable,
    hiddenFromPartner,
    enableGfrtNotification,
    description
  ]
}
