import React from 'react';
import DashboardFormFieldDropdownMulti from './DashboardFormFieldDropdownMulti';
import strings from '../../../../../Localization/Localization';

import { Row, Col } from 'react-bootstrap';
import '../../../../../css/form.css';

import Loading from '../../../../Layout/Loading';

import { v4 as uuid } from 'uuid';

import { connect } from 'react-redux';
import {
  addOption,
  startLoading,
  addItemsToOption,
  reloadOption
} from '../../../../../Store/Action/optionsContainer';

/**
 * DashboardFormFieldDropdownMultiEntity
 *
 * Specific configuration attribute : none
 */
class DashboardFormFieldDropdownMultiEntity extends DashboardFormFieldDropdownMulti {

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the class.
   */
  static defaultProps = {
    autoComplete: false,
    input: 'load',
    storage: 'state',
    shareStorage: true,
    finalValues: 'objects', // or labels or values
    options: []
  }

  constructor(props) {
    super(props);


    this.uuid = uuid();
    this.loading = false;
    if(props.loader && props.loader.loader) {
      this.loader = this.props.modalHandler.addVerificationWithCallback(props.loader.loader, this.postLoad, this.postLoadFailure, true);
      this.storageId = `${props.loader.id}${!props.shareStorage?'-'+this.uuid:''}`;
    }
    else {
      this.loader = () => console.log("No loader was configured for the field : "+props.fieldKey);
      this.storageId = "";
    }

    //the state of the component
    //options are the options coming from the call
    this.state = Object.assign(this.state, {
      loading: false,
      loaded: false,
      loadingFailure: false,
      options: [],
    })
  }

  componentDidMount() {
    if(!this.props.disabled) {
      this.checkOptions();
    }
  }

  componentDidUpdate() {
    if(!this.props.disabled) {
      this.checkOptions();
    }
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    let results = this.getFilteredOptions().filter((item) => {
      let {value} = this.applyAdapter(item);
      return Object.keys(this.state.selected).includes(value.toString()) && this.state.selected[value] === true;
    });
    switch (this.props.finalValues) {
      case 'values':
        return results.map((item) => {
          let {value} = this.applyAdapter(item);
          return value;
        });
      case 'labels':
        return results.map((item) => {
          let {label} = this.applyAdapter(item);
          return label;
        });
      case 'objects':
      default:
        return results;
    }
  }

  reloadData() {
    Promise.resolve().then(() => {
      this.onChange('', true);
    }).then(() => {
      switch (this.props.storage) {
        case 'state':
          this.setState({
            loading: false,
            loaded: false,
            options: [],
          })
          break;
        case 'redux':
          this.props.dispatch(reloadOption(this.storageId));
          break;
        default:
      }
    })
  }

  loadData() {
    if(this.props.loader.loaderRequire(this.props.value, this.props.getValues, this.props.getExtraValues)) {
      let myArguments = this.props.loader.loaderArguments(this.props.value, this.props.getValues, this.props.getExtraValues);
      switch (this.props.storage) {
        case 'state':
          Promise.resolve().then(() => {
            if(!this.state.loading) {
              this.setState({
                loading: true,
              }, () => {
                this.loader(...myArguments);
              });
            }
          })
          break;
        case 'redux':
          Promise.resolve().then(() => {
            this.props.dispatch(startLoading(this.storageId));
          }).then(() => {
            this.loader(...myArguments);
          })
          break;
        default:
          console.log('wrong storage used in FormFieldDropdownEntity');
      }

    }
  }

  /**
   * callback collecting the result of the call
   */
  postLoad = (data) => {
    this.loading = false;
    let items = null;
    if(this.props.loader.dataTransformation) {
      items = this.props.loader.dataTransformation(data);
    }
    else {
      items = data.items;
    }
    this.setOptions(items);
  }

  /**
   * callback collecting the result of the call
   */
  postLoadFailure = (msg) => {
    this.setState({
      loaded: true,
      loading: false,
      loadingFailure: true,
    })
  }

  /**
   * @inheritdoc
   */
  resetOptions() {
    switch (this.props.storage) {
      case 'state':
        this.setState({
          loaded: true,
          loading: false,
          options: []
        })
        break;
      case 'redux':
        this.props.dispatch(addItemsToOption(this.storageId, []));
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
  }

  /**
   * @inheritdoc
   */
  getOptions() {
    switch (this.props.storage) {
      case 'state':
        if(this.state.loaded && this.state.options) {
          return this.state.options;
        }
        break;
      case 'redux':
        if(this.props.optionsContainer[this.storageId]
          && this.props.optionsContainer[this.storageId].loaded && this.props.optionsContainer[this.storageId].options) {
          return this.props.optionsContainer[this.storageId].options;
        }
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
    return [];
  }

  /**
   * @inheritdoc
   */
  setOptions(options = []) {
    switch (this.props.storage) {
      case 'state':
        this.setState({
          loaded: true,
          loading: false,
          loadingFailure: false,
          options: options
        })
        break;
      case 'redux':
        this.props.dispatch(addItemsToOption(this.storageId, options));
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
  }

  checkOptions() {
    switch (this.props.storage) {
      case 'state':
        if(!this.state.loaded && !this.state.loading) {
          this.loadData();
        }
        break;
      case 'redux':
        if(!this.props.optionsContainer[this.storageId]) {
          this.props.dispatch(addOption(this.storageId, this.uuid));
        }
        else if(this.props.optionsContainer[this.storageId]
          && this.props.optionsContainer[this.storageId].owner === this.uuid
          && !this.props.optionsContainer[this.storageId].loading
          && !this.props.optionsContainer[this.storageId].loaded
          && !this.loading) {
          this.loading = true;
          this.loadData();
        }
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
  }

  displayLoadingError() {
    if(this.state.loadingFailure) {
      return (
        <div className="invalid-feedback" style={{display:"block"}}>
          {strings.form.default.field.dropdownEntity.error.loadingFailure}
        </div>
      )
    }
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <Loading container="parent" containerClassName="loading-form-row" size="small"/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className={`dashboard-form-row${!this.props.skipSeparator?" dashboard-form-row-separator":""}`}>
        {this.props.label !== ""?<Row className="no-gutters">{this.props.label}{this.displayMiddlePart()}{this.displayValidating()}</Row>:undefined}
        {(this.props.label === "" && this.props.middlePart)?<Row className="no-gutters">{this.displayMiddlePart()}</Row>:undefined}
        {this.displayOptions()}
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    )
  }
}

const mapStateToProps = state => ({
  optionsContainer: state.optionsContainer,
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(DashboardFormFieldDropdownMultiEntity);
