import React from 'react';

import { Col } from 'react-bootstrap';

import AbstractReference from './AbstractReference';

class OrderReference extends AbstractReference {

  displayOrder() {
    if(this.props.order) {
      return (
        <div>
          {this.displayTitle(`Commande ${this.props.order.number}`)}
        </div>
      )
    }
  }

  render() {
    return (
      <div className="call-report-history-container">
        <Col>
          {this.displayOrder()}
        </Col>
      </div>
    );
  }
}


export default OrderReference;
