import React from 'react';

import { Badge } from 'react-bootstrap';

import { connect } from 'react-redux';
//import { toggleMenuAction } from '../Store/Action/collapseMenu';

import '../../../css/main_menu.css';

import { menu } from '../../../MetaData/Menu/leftMenu';

/**
 * Menu
 *
 * This menu is the one the left side of the application.
 * This menu is configurated from a file in the MetaData folder.
 *
 * This menu has two display mode :
 * - not collapsed, visible.
 * - collapsed, hidden.
 *
 * The mode is stored inside the global state of the application as others
 * components should be able to modify it.
 */
class Menu extends React.Component {

  constructor(props){
    super(props);

    //state of the component, including :
    // - collapseActiveId : the id of the current nested menu selected
    //   only one nested "path" can be displayed at a time, since you can nested on
    //   several level
    this.state = {
      activeId: null,
    }
  }

  isCollapsed(menuItem) {
    if(this.state.activeId === menuItem.id
      || menuItem.children.some((child) => (child.type==="submenu" && !this.isCollapsed(child)) || (child.type==="link" && this.isSelected(child)))
    ) {
      return false;
    }
    return true;
  }

  isSelected(menuItem) {
    let location = window.location.pathname;
    const regex = /show\/\d+/;
    location = location.replace(regex, 'index');
    if(menuItem.type==="link" && menuItem.path === location) {
      return true;
    }
    return false;
  }

  displayMenu(menu, depth = 0, parent = null) {
    return (
      <div className={`${depth === 0?"left-menu-list":""} ${depth > 0?"left-submenu-list":""}`}>
        {menu
          .map(item => {
            if(typeof item === 'function') {
              return item(this.props.state)
            }
            return item
          })
          .filter(item => !item.authRoles
            || (typeof item.authRoles === 'string' && this.props.credentials.roles.includes(item.authRoles))
            || (Array.isArray(item.authRoles) && item.authRoles.some((authRole) => this.props.credentials.roles.includes(authRole)))
          )
          .filter(item => !item.mandatoryRoles
            || (typeof item.mandatoryRoles === 'string' && this.props.credentials.roles.includes(item.mandatoryRoles))
            || (Array.isArray(item.mandatoryRoles) && item.mandatoryRoles.every((mandatoryRole) => this.props.credentials.roles.includes(mandatoryRole)))
          )
          .filter(item => !item.excludedRoles
            || (typeof item.excludedRoles === 'string' && !this.props.credentials.roles.includes(item.excludedRoles))
            || (Array.isArray(item.excludedRoles) && item.excludedRoles.every((excludedRole) => !this.props.credentials.roles.includes(excludedRole)))
          )
          .sort((a, b) => a.order - b.order)
          .map((item) => {
          return this.displayMenuItem(item, depth, parent);
        })}
      </div>
    );
  }

  displayMenuItem(menuItem, depth = 0, parent = null) {
    return (
      <div key={menuItem.id} className={`${depth === 0?"left-menu-list-item":""} ${depth > 0?"left-submenu-list-item":""}`}>
        {menuItem.type==="link"?
          <a href={menuItem.path} className={`left-menu-list-item-link${this.isSelected(menuItem)?" left-menu-list-item-link-selected":""}`}>
            <div className="d-flex row no-gutters justify-content-between align-items-center">
              <div className="d-flex col no-gutters align-items-center">
                <i className={`${menuItem.icon} left-menu-list-item-icon`}/>
                <div>{menuItem.label}</div>
              </div>
              {menuItem.badge?<Badge pill variant={menuItem.badge.variant}>{menuItem.badge.text}</Badge>:null}
            </div>
          </a>
          :<></>
        }
        {menuItem.type==="submenu"?
          <div onClick={() => this.setState({ activeId: this.state.activeId !== menuItem.id?menuItem.id:(parent && parent.id?parent.id:null) })} className="left-menu-list-item-button">
            <div className="d-flex row no-gutters justify-content-between align-items-center">
              <div className="d-flex col no-gutters align-items-center">
                <i className={`${menuItem.icon} left-menu-list-item-icon`}/>
                <div>{menuItem.label}</div>
              </div>
              {menuItem.badge?<Badge pill variant={menuItem.badge.variant}>{menuItem.badge.text}</Badge>:null}
            </div>
          </div>
          :<></>
        }
        {menuItem.type==="submenu" && !this.isCollapsed(menuItem)?
          this.displayMenu(menuItem.children, depth+1, menuItem)
          :<></>
        }
      </div>
    )
  }

  /**
   * Menu render method for React Component
   */
  render() {
    return (
      <div className="left-menu-color">
        {this.displayMenu(menu)}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  state: state,
  credentials: state.persisted.credentials,
  isCollapse: state.persisted.collapseMenu.isCollapse
})

export default connect(mapStateToProps)(Menu);
