import strings from '../../Localization/Localization';

import { processTimeframe } from './common';

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import YousignAppointment from '../../Component/Yousign/Holder/YousignAppointment';
import CountdownTimer from '../../Component/Basic/CountdownTimer';

import { snakeToCamel } from '../../Utils/SnakeToCamel';

import {
  index,
  getFromId,
  getIdentificationFromId,
  getHistoryFromId,
  getAssignRefereeFromId,
  editFromId,
  editFormAnswerFromId,
  editAssignRefereeFromId,
  newCommentFromId,
  newFileFromId,
  newAppointmentFromId,
  newFormAnswerFromId,
  newItem,
  workInProgressTransition,
  waitTransition,
  closeTransition,

  escalate,
  freezeGfrtNotification,
  unfreezeGfrtNotification
} from '../../API/Tickets';
import {
  form,
  newForm,
  newCommentForm,
  newAppointmentForm,
  assignRefereeForm
} from '../Form/tickets';
import {
  form as commentForm
} from '../Form/comments';
import {
  form as fileForm,
  newForm as newFileForm
} from '../Form/ticketFiles';
import {
  form as appointmentForm
} from '../Form/appointments';
import {
  form as formAnswerForm,
  newForm as newFormAnswerForm
} from '../Form/ticketFormAnswers';
import { form as searchForm } from '../Form/Search/tickets';

import {
  technicalTicket as technicalTicketRoutes,
  commercialTicket as commercialTicketRoutes,
  administrativeTicket as administrativeTicketRoutes,
  installationTicket as installationTicketRoutes,
  terminationTicket as terminationTicketRoutes,
  technicalGlobalTicket as technicalGlobalTicketRoutes
} from '../Route/routes';

import {
  FLASHBAG_TYPE_SUCCESS,
  FLASHBAG_TYPE_DANGER
} from '../../Store/Action/flashbag';

export function getRoutes(identifier) {
  if(identifier === 'technical') {
    return technicalTicketRoutes
  }
  else if(identifier === 'commercial') {
    return commercialTicketRoutes
  }
  else if(identifier === 'administrative') {
    return administrativeTicketRoutes
  }
  else if(identifier === 'installation') {
    return installationTicketRoutes
  }
  else if(identifier === 'termination') {
    return terminationTicketRoutes
  }
  else if(identifier === 'technical_global') {
    return technicalGlobalTicketRoutes
  }
  else {
    console.error("missing identifier for ticket creation : "+identifier);
  }
}

function createConf(identifier, roleIdentifier, hasOrigin = false, newAvailable = false, isGlobal = false) {

  let identifierCC = snakeToCamel(identifier);

  const _form = form(identifier, hasOrigin, !isGlobal);
  const _newForm = newForm(identifier, hasOrigin, !isGlobal);
  const _newCommentForm = newCommentForm(identifier);
  const _newAppointmentForm = newAppointmentForm(identifier, !isGlobal);
  const _appointmentForm = appointmentForm(identifier);
  const _assignRefereeForm = assignRefereeForm(identifier);
  const _newFormAnswerForm = newFormAnswerForm(identifier);
  const _searchForm = searchForm(identifier);

  const _index = index(identifier);
  const _getFromId = getFromId(identifier);
  const _getIdentificationFromId = getIdentificationFromId(identifier);
  const _getHistoryFromId = getHistoryFromId(identifier);
  const _getAssignRefereeFromId = getAssignRefereeFromId(identifier);
  const _editFromId = editFromId(identifier);
  const _editFormAnswerFromId = editFormAnswerFromId(identifier);
  const _editAssignRefereeFromId = editAssignRefereeFromId(identifier);
  const _newCommentFromId = newCommentFromId(identifier);
  const _newFileFromId = newFileFromId(identifier);
  const _newAppointmentFromId = newAppointmentFromId(identifier);
  const _newFormAnswerFromId = newFormAnswerFromId(identifier);
  const _newItem = newItem(identifier);
  const _workInProgressTransition = workInProgressTransition(identifier);
  const _waitTransition = waitTransition(identifier);
  const _closeTransition = closeTransition(identifier);
  const _escalate = escalate(identifier);
  const _freezeGfrtNotification = freezeGfrtNotification(identifier);
  const _unfreezeGfrtNotification = unfreezeGfrtNotification(identifier);

  let indexConf = {
    pageTitle: strings.common.homepage[identifierCC+"Ticket"],

    id: identifierCC+"Ticket",
    api: _index,
    onClickItem: {
      redirect: true,
      path: (id) => getRoutes(identifier).routes.show.createPath(id)
    },

    searchForm: _searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.number) {
        result["number"] = search.number;
      }
      if(typeof search.requestType === 'object' && search.requestType.length > 0) {
        result["request_type_id"] = search.requestType.map((item) => item.id).toString();
      }
      if(typeof search.state === 'object' && search.state.length > 0) {
        result["state"] = search.state.filter(item => !!item).map((item) => item.value).toString();
      }
      if(search.criticity) {
        result["criticity"] = search.criticity;
      }
      if(search.escalateLevel) {
        result["escalate_level"] = search.escalateLevel;
      }
      if(typeof search.referee === 'object') {
        result["referee_id"] = search.referee.id;
      }
      if(!isGlobal && typeof search.client === 'object') {
        result["client_id"] = search.client.id;
      }
      if(!isGlobal && typeof search.clientAccount === 'object') {
        result["client_account_id"] = search.clientAccount.id;
      }
      if(!isGlobal && typeof search.place === 'object') {
        result["place_id"] = search.place.id;
      }
      if(!isGlobal && typeof search.refereeClient === 'object') {
        result["referee_client_id"] = search.refereeClient.id;
      }
      if(search.createdAtTimeframe) {
        let createdAtTimeframe = processTimeframe(search.createdAtTimeframe);

        result["created_at_min"] = createdAtTimeframe.min;
        result["created_at_max"] = createdAtTimeframe.max;
      }
      if(search.createdAt) {
        result["created_at"] = search.createdAt;
      }
      if(typeof search.appointmentType === 'object') {
        result["appointment_type_id"] = search.appointmentType.id;
      }
      if(search.appointmentNumber) {
        result["appointment_number"] = search.appointmentNumber;
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        enableHeaderEdit: false,
        // defaultHeader: [
        //   0, 1, 2, 3, 4, 5, 6
        // ],
        headers: [
          {
            id: "state",
            label: <>&nbsp;&nbsp;<i className="icon-flag-1"/>&nbsp;&nbsp;</>,
            value: item => {
              switch (item["state"]) {
                case 'new':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.state.options.new}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-info-icon"><i className="icon-ticket-new"/></div>
                    </OverlayTrigger>
                  );
                case 'waiting':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.state.options.waiting}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-warning-icon"><i className="icon-ticket"/></div>
                    </OverlayTrigger>
                  );
                case 'in_progress':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.state.options.in_progress}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-info-icon"><i className="icon-ticket"/></div>
                    </OverlayTrigger>
                  );
                case 'closed':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.state.options.closed}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-validated-icon"><i className="icon-checkmark"/></div>
                    </OverlayTrigger>
                  );
                default:

              }
            }
          },
          {
            id: "criticity",
            label: <>&nbsp;&nbsp;<i className="icon-clock"/>&nbsp;&nbsp;</>,
            value: item => {
              switch (item["criticity"]) {
                case 1:
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.criticity.options.urgent}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-danger-icon"><i className="icon-clock"/></div>
                    </OverlayTrigger>
                  );
                case 2:
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.criticity.options.medium}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-warning-icon"><i className="icon-clock"/></div>
                    </OverlayTrigger>
                  );
                case 3:
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.criticity.options.noturgent}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-info-icon"><i className="icon-clock"/></div>
                    </OverlayTrigger>
                  );
                default:

              }
            }
          },
          {
            id: "escalateLevel",
            label: <>&nbsp;&nbsp;<i className="icon-broadcast"/>&nbsp;&nbsp;</>,
            value: item => {
              switch (item["escalate_level"]) {
                case 1:
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.escalateLevel.options._1}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-info-icon"><i className="icon-broadcast"/></div>
                    </OverlayTrigger>
                  );
                case 2:
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.escalateLevel.options._2}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-warning-icon"><i className="icon-broadcast"/></div>
                    </OverlayTrigger>
                  );
                case 3:
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.escalateLevel.options._3}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-danger-icon"><i className="icon-broadcast"/></div>
                    </OverlayTrigger>
                  );
                default:

              }
            }
          },
          {
            id: "number",
            label: "Numéro",
            value: "number"
          },
          {
            id: "title",
            label: "Titre",
            value: "title"
          },
          !isGlobal?{
            id: "partner",
            label: "Partenaire",
            value: item => item["client"]["partner"]["name"]
          }:null,
          !isGlobal?{
            id: "place",
            label: "Site",
            value: item => item["place"]["name"]
          }:null,
        ].filter(header => !!header)
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  }

  if(newAvailable) {
    indexConf.newLink = {
      link: () => getRoutes(identifier).routes.new.createPath(),
      auth: 'ROLE_'+roleIdentifier+'_TICKET_CREATE'
    }
  }

  if(hasOrigin) {
    indexConf.itemDisplay.tab.headers.push({
      id: "service",
      label: "Service",
      value: item => {
        switch (item["ticket_origin"]["origin_type"]) {
          case 'product_sub_family':
            return item["ticket_origin"]["product_sub_family"]["name"];
          case 'service':
            return item["ticket_origin"]["service"]["cart_item"]["product"]["name"];
          case 'order':
            return item["ticket_origin"]["order"]["number"];
          default:
            return "";
        }
      }
    });
  }

  let showConf = {
    pageTitle: strings.common.homepage[identifierCC+"Ticket"],
    pageTitleReturnPath: () => getRoutes(identifier).routes.index.createPath(),
    breadcrumbIdentifier: "number",

    apiGet: _getIdentificationFromId,

    extendedHeader: true,
    header: {
      imgUrl: (item) => null,
      placeholderTitle: (item) => "NK",
      label: (item) => `${item.number}${item.title?" > "+item.title:""}`,
      additionalData1: (item) => !isGlobal?`${item["client"]["partner"]["name"]} > ${item["place"]["name"]}`:"",
      additionalData2: (item) => !isGlobal?`${item["created_at"]}${item["closed_at"]?` > ${item["closed_at"]}`:""}`:"",
      additionalData3: (item) => !isGlobal?`${item["request_type"]["name"]}${item["request_type"]["escalatable"]?`, ${strings.ticket.tabs.header.escalate}${item["escalate_level"]}`:''}`:""
    },

    menu: (item) => {
      let buttons = [];
      if(item.state !== 'closed') {
        if(item.state !== 'in_progress') {
          buttons.push({
            id: 'workInProgress',
            type: 'redirection',
            auth: 'ROLE_'+roleIdentifier+'_TICKET_EDIT',
            path: getRoutes(identifier).routes.transition.createPath(item.id, 'workInProgress'),
            label: strings.ticket.tabs.buttons.workInProgress,
            variant: "my-validated"
          });
        }
        if(item.state !== 'waiting') {
          buttons.push({
            id: 'wait',
            type: 'redirection',
            auth: 'ROLE_'+roleIdentifier+'_TICKET_EDIT',
            path: getRoutes(identifier).routes.transition.createPath(item.id, 'wait'),
            label: strings.ticket.tabs.buttons.wait,
            variant: "my-information"
          });
        }
        buttons.push({
          id: 'close',
          type: 'redirection',
          auth: 'ROLE_'+roleIdentifier+'_TICKET_EDIT',
          path: getRoutes(identifier).routes.transition.createPath(item.id, 'close'),
          addVerification: true,
          label: strings.ticket.tabs.buttons.close,
          variant: "my-warning",
          alwaysDisplay: true
        });
        if(item["request_type"]["escalatable"] && item["escalate_level"] < 3) {
          buttons.push({
            id: 'escalate',
            type: 'modal',
            label: strings.ticket.tabs.buttons.escalate.label,
            hoverLabel: () => {
              let date = new Date(item["next_escalated_minimum_date"]["date"]);
              if(date > Date.now()) {
                return (
                  <CountdownTimer targetDate={new Date(item["next_escalated_minimum_date"]["date"])}/>
                )
              }
              else {
                return "Disponible";
              }
            },
            auth: 'ROLE_'+roleIdentifier+'_TICKET_ESCALATE',
            variant: "my-warning",
            request: (form) => _escalate(item["id"], {
              id: item["id"],
              escalate_comment: form.comment
            }),
            postRequestCallback: [
              {
                type: "flashbag",
                message: strings.ticket.tabs.buttons.escalate.flashbagMessage,
                flashbagType: FLASHBAG_TYPE_SUCCESS,
              },
              {
                type: "updateItem",
                key: "escalate_level",
                value: (data) => data["item"]["escalate_level"]
              }
            ],
            postRequestCallbackFailure: {
              type: "flashbag",
              message: strings.ticket.tabs.buttons.escalate.flashbagMessageFailure,
              flashbagType: FLASHBAG_TYPE_DANGER,
            },
            modalData: (context) => {
              let body = undefined;
              let form = undefined;
              let date = new Date(item["next_escalated_minimum_date"]["date"]);

              if(date > Date.now() && !context.credentials.roles.includes('ROLE_NYUKOM')) {
                body = (
                  <>
                    Prochaine escalade disponible dans <CountdownTimer targetDate={new Date(item["next_escalated_minimum_date"]["date"])} onFinish={context.reload}/>
                  </>
                );
              }
              else {
                form = {
                  formKey: "escalate",
                  fields: [
                    {
                      fieldKey: "comment",
                      type: "textarea",
                      label: strings.ticket.tabs.buttons.escalate.form.comment.label,
                      showOnDisabled: (values) => true,
                      validators: []
                    },
                  ]
                };
              }

              return {
                title: strings.ticket.tabs.buttons.escalate.title,
                form: form,
                body: body,
                successButton: strings.ticket.tabs.buttons.escalate.successButton,
                successButtonOnClick: (item) => {
                  return {
                    request: true,
                    arguments: [item]
                  }
                },
              }
            },
          })
        }
        if(item["request_type"]["enable_gfrt_notification"]) {
          if(item["freeze_gfrt_notification"]) {
            buttons.push({
              id: 'unfreezeGfrtNotification',
              type: 'request',
              auth: 'ROLE_'+roleIdentifier+'_TICKET_FREEZE_GFRT_NOTIFICATION',
              request: () => _unfreezeGfrtNotification(item["id"]),
              postRequestCallback: [
                {
                  type: "flashbag",
                  message: strings.ticket.tabs.buttons.unfreezeGfrtNotification.flashbagMessage,
                  flashbagType: FLASHBAG_TYPE_SUCCESS,
                },
                {
                  type: "updateItem",
                  key: "freeze_gfrt_notification",
                  value: (data) => false
                }
              ],
              postRequestCallbackFailure: {
                type: "flashbag",
                message: strings.ticket.tabs.buttons.unfreezeGfrtNotification.flashbagMessageFailure,
                flashbagType: FLASHBAG_TYPE_DANGER,
              },
              label: strings.ticket.tabs.buttons.unfreezeGfrtNotification.label,
              variant: "my-validated"
            });
          }
          else {
            buttons.push({
              id: 'freezeGfrtNotification',
              type: 'request',
              auth: 'ROLE_'+roleIdentifier+'_TICKET_FREEZE_GFRT_NOTIFICATION',
              request: () => _freezeGfrtNotification(item["id"]),
              postRequestCallback: [
                {
                  type: "flashbag",
                  message: strings.ticket.tabs.buttons.freezeGfrtNotification.flashbagMessage,
                  flashbagType: FLASHBAG_TYPE_SUCCESS,
                },
                {
                  type: "updateItem",
                  key: "freeze_gfrt_notification",
                  value: (data) => true
                }
              ],
              postRequestCallbackFailure: {
                type: "flashbag",
                message: strings.ticket.tabs.buttons.freezeGfrtNotification.flashbagMessageFailure,
                flashbagType: FLASHBAG_TYPE_DANGER,
              },
              label: strings.ticket.tabs.buttons.freezeGfrtNotification.label,
              variant: "my-validated"
            });
          }
        }
      }
      return buttons;
    },

    tabs: {
      main: {
        title: strings.ticket.tab.main,
        type: 'form',
        form: _form,

        onUpdateItem: (data) => {
          data.item['client_account'] = data.item['client'];
          data.item['client'] = data.item['client_account']['partner'];
        },
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: _getFromId,
        apiEdit: _editFromId,

        role: ['ROLE_USER', 'ROLE_NYUKOM'],
        editable: (item, credentials) => item && item.state !== 'closed' && credentials.roles.includes('ROLE_'+roleIdentifier+'_TICKET_EDIT')
      },
      history: {
        title: strings.ticket.tab.history,
        type: 'ticket_history',
        subType: identifierCC,

        commentForm: commentForm,
        fileForm: fileForm,
        appointmentForm: _appointmentForm,
        formAnswerForm: formAnswerForm,

        newCommentForm: _newCommentForm,
        newFileForm: newFileForm,
        newFormAnswerForm: _newFormAnswerForm,
        newAppointmentForm: _newAppointmentForm,

        postEdit: {
          type: 'tab',
          tabId: 'history'
        },
        postEditAppointment: (data) => {
          if(data && data["item"] && data["item"]["appointment"] && data["item"]["appointment"]["appointment_type"]["ask_for_signature"]) {
            return {
              type: 'redirection',
              path: getRoutes(identifier).routes.yousign.createPath(data["item"]["appointment"]["id"])
            }
          }
          else {
            return {
              type: 'tab',
              tabId: 'history'
            }
          }
        },

        apiHistory: _getHistoryFromId,
        apiNewComment: _newCommentFromId,
        // apiPublishComment: _editFromId,
        // apiUnpublishComment: _editFromId,
        apiNewFile: _newFileFromId,
        apiNewFormAnswer: _newFormAnswerFromId,
        apiEditFormAnswer: _editFormAnswerFromId,
        apiNewAppointment: _newAppointmentFromId,

        role: ['ROLE_USER', 'ROLE_NYUKOM'],
      },
      assignReferee: {
        title: strings.ticket.tab.assignReferee,
        type: 'form',
        form: _assignRefereeForm,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: _getAssignRefereeFromId,
        apiEdit: _editAssignRefereeFromId,

        shouldDisplay: (item) => item && item.state !== 'closed',
        role: ['ROLE_'+roleIdentifier+'_TICKET_MANAGER'],
        editable: true,
      }
    }
  }

  let newConf = undefined
  if(newAvailable) {

    newConf = {
      pageTitle: strings.ticket.new.title,

      api: _newItem,
      empty: {},

      // form: _newForm,
      type: 'process',
      processId: 'new'+identifierCC.charAt(0).toUpperCase()+identifierCC.substring(1)+'Ticket',

      steps: [
        !isGlobal?{
          id: "_general_data",
          label: strings.ticket.new.steps.partnerData,
          type: "form",
          valueOrigin: "transparent",
          form: {
            ..._newForm,
            fields: _newForm.fields.filter((item) => ['client', 'client_account', 'place', 'referee_clients'].includes(item.fieldKey)),
          },
          onSubmitStep: () => {}
        }:null,
        {
          id: "_general_data",
          label: strings.ticket.new.steps.generalData,
          type: "form",
          valueOrigin: "transparent",
          form: {
            ..._newForm,
            fields: _newForm.fields.filter((item) => ['ticket_origin', 'title', 'description', 'request_type', 'criticity'].includes(item.fieldKey)), //TODO maybe problem with ticket_origin
          },
          onSubmitStep: () => {}
        },
      ].filter(step => !!step),

      onSubmit: (item) => {
        if(!isGlobal) {
          item["client"] = item["client_account"];
          item["client_account"] = undefined;
        }

        if(hasOrigin) {
          if(item["ticket_origin"]["origin_type"] === "product_sub_family") {
            item["ticket_origin"]["service"] = undefined;
          }
          else if(item["ticket_origin"]["origin_type"] === "service") {
            item["ticket_origin"]["product_sub_family"] = undefined;
          }
        }
      },
      postNew: {
        type: 'path',
        path: (id) => getRoutes(identifier).routes.show.createPath(id)
      }
    }
  }

  let transitionConf = {
    workInProgress: {
      type: 'direct',
      api: _workInProgressTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => getRoutes(identifier).routes.show.createPath(data.item.id)
      },
    },
    wait: {
      type: 'direct',
      api: _waitTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => getRoutes(identifier).routes.show.createPath(data.item.id)
      },
    },
    close: {
      type: 'direct',
      api: _closeTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => getRoutes(identifier).routes.index.createPath()
      },
    },
  }

  let customConf = {
    yousign: {
      path: '/yousign/:id',
      createPath: (id) => `/yousign/${id}`,
      type: 'render',
      getRender: (props) => <YousignAppointment {...props} ticketType={identifier}/>,
    }
  }

  return {
    prefix: "tickets/"+identifierCC,
    index: indexConf,
    show: showConf,
    new: newConf,
    transition: transitionConf,
    custom: customConf
  }
}



export const technicalTicketConf = createConf('technical', 'TECHNICAL', true, true);
export const commercialTicketConf = createConf('commercial', 'COMMERCIAL', false, true);
export const administrativeTicketConf = createConf('administrative', 'ADMINISTRATIVE', false, true);

export const installationTicketConf = createConf('installation', 'TECHNICAL', true);
export const terminationTicketConf = createConf('termination', 'TECHNICAL', true);

export const technicalGlobalTicketConf = createConf('technical_global', 'TECHNICAL', true, true, true);
