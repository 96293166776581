import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  editFromId,
  newItem
} from '../../API/TicketPreFillComments';
import { form } from '../Form/preFillComment';
import { form as searchForm } from '../Form/Search/preFillComment';

import {
  technicalPreFillComment as technicalPreFillCommentsRoutes,
  commercialPreFillComment as commercialPreFillCommentsRoutes,
  administrativePreFillComment as administrativePreFillCommentsRoutes,
  installationPreFillComment as installationPreFillCommentsRoutes,
  terminationPreFillComment as terminationPreFillCommentsRoutes,
  technicalGlobalPreFillComment as technicalGlobalPreFillCommentsRoutes,
} from '../Route/routes';

import { snakeToCamel } from '../../Utils/SnakeToCamel';

function getRoutes(identifier) {
  if(identifier === 'technical') {
    return technicalPreFillCommentsRoutes
  }
  else if(identifier === 'commercial') {
    return commercialPreFillCommentsRoutes
  }
  else if(identifier === 'administrative') {
    return administrativePreFillCommentsRoutes
  }
  else if(identifier === 'installation') {
    return installationPreFillCommentsRoutes
  }
  else if(identifier === 'termination') {
    return terminationPreFillCommentsRoutes
  }
  else if(identifier === 'technical_global') {
    return technicalGlobalPreFillCommentsRoutes
  }
  else {
    console.error("missing identifier for ticket creation : "+identifier);
  }
}

function createConf(identifier, roleIdentifier) {

  let identifierCC = snakeToCamel(identifier);

  const _index = index(identifier);
  const _getFromId = getFromId(identifier);
  const _editFromId = editFromId(identifier);
  const _newItem = newItem(identifier);

  return {
    prefix: "preFillComment/"+identifierCC,
    index: {
      pageTitle: strings.common.homepage[identifierCC+"PreFillComment"],

      id: "preFillComment",
      api: _index,
      onClickItem: {
        redirect: true,
        path: (id) => getRoutes(identifier).routes.show.createPath(id)
      },

      newLink: {
        link: () => getRoutes(identifier).routes.new.createPath(),
        auth: 'ROLE_NYUKOM'
      },

      searchForm: searchForm,
      computeSearch: (search) => {
        let result = {};
        if(search.name) {
          result["name"] = search.name;
        }
        return result;
      },

      availableItemDisplay:['line'],
      itemDisplay: {
        line: {
          label: (item) => item["title"],
        },
      },

      sizing: {
        numberColumn: 2,
        formSizeMd: 12
      },

      buttons: () => []
    },
    show: {
      pageTitle: strings.common.homepage[identifierCC+"PreFillComment"],
      pageTitleReturnPath: () => getRoutes(identifier).routes.index.createPath(),
      breadcrumbIdentifier: "title",

      apiGet: _getFromId,

      extendedHeader: false,

      tabs: {
        main: {
          title: strings.preFillComment.tab.main,
          type: 'form',
          form: form,

          onUpdateItem: (data) => {},
          onSubmit: (entity) => {},
          postEdit: {
            type: 'tab',
            tabId: 'main'
          },

          apiGet: _getFromId,
          apiEdit: _editFromId,

          role: ['ROLE_'+roleIdentifier+'_TICKET_PRE_FILL_COMMENT'],
          editable: ['ROLE_'+roleIdentifier+'_TICKET_PRE_FILL_COMMENT']
        }
      }
    },
    new: {
      pageTitle: strings.preFillComment.new.title,

      api: _newItem,
      empty: {},
      form: form,
      onSubmit: (item) => {},
      postNew: {
        type: 'path',
        path: (id) => getRoutes(identifier).routes.show.createPath(id)
      }
    }
  }
}

export const technicalTicketPreFillCommentConf = createConf('technical', 'TECHNICAL');
export const commercialTicketPreFillCommentConf = createConf('commercial', 'COMMERCIAL');
export const administrativeTicketPreFillCommentConf = createConf('administrative', 'ADMINISTRATIVE');

export const installationTicketPreFillCommentConf = createConf('installation', 'TECHNICAL');
export const terminationTicketPreFillCommentConf = createConf('termination', 'TECHNICAL');

export const technicalGlobalTicketPreFillCommentConf = createConf('technical_global', 'TECHNICAL');
