import React from 'react';
import DashboardFormFieldDropdown from './DashboardFormFieldDropdown';
import strings from '../../../../../Localization/Localization';

import { Form, Row, Col } from 'react-bootstrap';
import '../../../../../css/form.css';

import { v4 as uuid } from 'uuid';

import Loading from '../../../../Layout/Loading';

import { connect } from 'react-redux';
import {
  addOption,
  startLoading,
  addItemsToOption,
  reloadOption
} from '../../../../../Store/Action/optionsContainer';

/**
 * DashboardFormFieldDropdownEntity
 *
 * Specific configuration attribute : none
 */
class DashboardFormFieldDropdownEntity extends DashboardFormFieldDropdown {

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the class.
   */
  static defaultProps = {
    showEmpty: false,
    autoComplete: true,
    input: 'load',
    storage: 'state',
    shareStorage: true,

    assertObjectDefaultValue: false
  }

  constructor(props) {
    super(props);

    this.uuid = uuid();

    if(props.loader && props.loader.loader) {
      this.loader = this.props.modalHandler.addVerificationWithCallback(props.loader.loader, this.postLoad, this.postLoadFailure, true);
      this.storageId = `${props.loader.id}${!props.shareStorage?'-'+this.uuid:''}`;
    }
    else {
      this.loader = () => console.log("No loader was configured for the field : "+props.fieldKey);
      this.storageId = "";
    }

    //the state of the component
    this.state = Object.assign(this.state, {
      loading: false,
      loaded: false,
      loadingFailure: false,
      options: [],
    })
  }

  componentDidMount() {
    if(!this.props.disabled) {
      this.checkOptions();
    }
  }

  componentDidUpdate() {
    if(!this.props.disabled) {
      this.checkOptions();
    }
  }

  getDatalistId() {
    return this.uuid;
  }

  /**
   * @inheritdoc
   */
  getSpecificValidators() {
    let validators = [];
    if(this.state && this.getFilteredOptions().length !== 0) {
      validators.push({
        id: 'assertObject',
        type: 'function',
        conf: {
          function: (value) => {
            return value !== ""?typeof value === 'object':null
          }
        },
        invalidFeedback: strings.form.default.field.dropdownEntity.invalid.assertObject,
        defaultValue: this.props.assertObjectDefaultValue,
      });
    }
    return validators;
  }

  reloadData() {
    Promise.resolve().then(() => {
      this.onChange('', true);
    }).then(() => {
      switch (this.props.storage) {
        case 'state':
          this.setState({
            loading: false,
            loaded: false,
            options: [],
          })
          break;
        case 'redux':
          this.props.dispatch(reloadOption(this.storageId));
          break;
        default:
      }
    })
  }

  loadData() {
    if(this.props.loader.loaderRequire(this.props.value, this.props.getValues, this.props.getExtraValues)) {
      let myArguments = this.props.loader.loaderArguments(this.props.value, this.props.getValues, this.props.getExtraValues);
      switch (this.props.storage) {
        case 'state':
          Promise.resolve().then(() => {
            if(!this.state.loading) {
              this.setState({
                loading: true,
              }, () => {
                this.loader(...myArguments);
              });
            }
          })
          break;
        case 'redux':
          Promise.resolve().then(() => {
            this.props.dispatch(startLoading(this.storageId));
          }).then(() => {
            this.loader(...myArguments);
          })
          break;
        default:
          console.log('wrong storage used in FormFieldDropdownEntity');
      }

    }
  }

  /**
   * callback collecting the result of the call
   */
  postLoad = (data) => {
    let items = null;
    if(this.props.loader.dataTransformation) {
      items = this.props.loader.dataTransformation(data);
    }
    else {
      items = data.items;
    }
    this.setOptions(items);
  }

  /**
   * callback collecting the result of the call
   */
  postLoadFailure = (msg) => {
    this.setState({
      loaded: true,
      loading: false,
      loadingFailure: true,
    })
  }

  /**
   * @inheritdoc
   */
  resetOptions() {
    switch (this.props.storage) {
      case 'state':
        this.setState({
          loaded: true,
          loading: false,
          options: []
        })
        break;
      case 'redux':
        this.props.dispatch(addItemsToOption(this.storageId, []));
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
  }

  /**
   * @inheritdoc
   */
  getOptions() {
    switch (this.props.storage) {
      case 'state':
        if(this.state.loaded && this.state.options) {
          return this.state.options;
        }
        break;
      case 'redux':
        if(this.props.optionsContainer[this.storageId]
          && this.props.optionsContainer[this.storageId].loaded && this.props.optionsContainer[this.storageId].options) {
          return this.props.optionsContainer[this.storageId].options;
        }
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
    return [];
  }

  /**
   * @inheritdoc
   */
  setOptions(options = []) {
    switch (this.props.storage) {
      case 'state':
        this.setState({
          loaded: true,
          loading: false,
          loadingFailure: false,
          options: options
        })
        break;
      case 'redux':
        this.props.dispatch(addItemsToOption(this.storageId, options));
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
  }

  checkOptions() {
    switch (this.props.storage) {
      case 'state':
        if(!this.state.loaded && !this.state.loading) {
          this.loadData();
        }
        break;
      case 'redux':
        if(!this.props.optionsContainer[this.storageId]) {
          this.props.dispatch(addOption(this.storageId, this.uuid));
        }
        else if(this.props.optionsContainer[this.storageId]
          && this.props.optionsContainer[this.storageId].owner === this.uuid
          && !this.props.optionsContainer[this.storageId].loading
          && !this.props.optionsContainer[this.storageId].loaded) {
          this.loadData();
        }
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
  }



  displayLoadingError() {
    if(this.state.loadingFailure) {
      return (
        <div className="invalid-feedback" style={{display:"block"}}>
          {strings.form.default.field.dropdownEntity.error.loadingFailure}
        </div>
      )
    }
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <div>
          <Loading container="parent" containerClassName="loading-form-row" size="small"/>
        </div>
      )
    }
  }

  /**
   * Display all options of the field
   */
  displayOptions() {
    return (
      <>
        {this.props.showEmpty?
          <option value=''/>
          :null
        }
        {this.getFilteredOptions().map((option, i) => {
          let {value, label} = this.applyAdapter(option);
          return (
            <option key={i} value={value} label={label}>{label}</option>
          )
        })}
      </>
    )
  }

  displayOptionsAsDatalist() {
    if(this.props.autoComplete) {
      return (
        <datalist id={this.getDatalistId()}>
          {this.displayOptions()}
        </datalist>
      )
    }
  }

  displayOptionsAsSelect() {
    if(this.props.shareStorage
      && this.props.optionsContainer[this.storageId]
      && this.props.optionsContainer[this.storageId].owner === this.uuid
      && !this.props.autoComplete
    ) {
      return this.displayOptions();
    }
  }

  getMiddlePartAdditionalClassname() {
    return "dashboard-field-middle-part"
  }

  displayInput() {
    return (
      <Form.Control
        as={this.props.autoComplete?"input":"select"}
        className="d-flex dashboard-field-dropdown-entity"
        placeholder={this.props.placeholder !== ""?this.props.placeholder:this.props.label}
        aria-label={this.props.label}
        aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
        type="text"
        list={this.props.autoComplete?this.getDatalistId():null}
        disabled={this.props.disabled || this.props.submitting || this.state.loading || this.getFilteredOptions().length === 0}
        value={this.getValue()}
        onChange={(event) => this.onChange(event.target.value)}
        isValid={(this.props.disabled)?null:this.isValid()}
        isInvalid={(this.props.disabled)?null:this.isInvalid()}
      >
        {this.displayOptionsAsSelect()}
      </Form.Control>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className={`dashboard-form-row${!this.props.skipSeparator?" dashboard-form-row-separator":""}`}>
        {this.displayOptionsAsDatalist()}
        <Row className="d-flex align-items-center no-gutters">
          {this.props.label !== ""?
            <Col xs="auto" className="no-gutters dashboard-field-date-label">
              {this.props.label}&nbsp;{this.displayValidating()}&nbsp;&nbsp;&nbsp;{this.shouldDisplayMiddlePart()?<>&nbsp;&nbsp;&nbsp;{this.displayMiddlePart()}</>:''}
            </Col>
          :undefined}
          {this.props.label === "" && this.shouldDisplayMiddlePart()?
            <>
              <Col xs={12} className="no-gutters dashboard-field-date-label">
                {this.displayValidating()}{this.displayMiddlePart()}
              </Col>
            </>
          :undefined}
          <Col xs={this.props.label === ""?12:""} className="no-gutters">
            {this.displayInput()}
          </Col>
        </Row>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    )
  }
}

const mapStateToProps = state => ({
  optionsContainer: state.optionsContainer,
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(DashboardFormFieldDropdownEntity);
