import strings from '../../../Localization/Localization';

import { conf as quoteConf } from '../../Configuration/quotes';
import { statistic as quoteStatistic } from '../../../API/Quotes';

import { conf as orderConf } from '../../Configuration/orders';
import { statistic as orderStatistic } from '../../../API/Orders';

import { conf as serviceConf } from '../../Configuration/services';
import { statistic as serviceStatistic } from '../../../API/Services';

import { conf as billConf } from '../../Configuration/bills';
import { statistic as billStatistic } from '../../../API/Bills';

import {
  technicalTicketConf,
  commercialTicketConf,
  administrativeTicketConf
} from '../../Configuration/tickets';
import { statistic as ticketStatisitc } from '../../../API/Tickets';

import { conf as commercialBonusConf } from '../../Configuration/commercialBonus';
import { statistic as commercialBonusStatistic } from '../../../API/CommercialBonus';

import {
  quotes as quotesRoutes,
  orders as ordersRoutes,
  commercialTicket as commercialTicketRoutes,
  services as serviceRoutes,
  technicalTicket as technicalTicketRoutes,
  administrativeTicket as administrativeTicketRoutes,
  bills as billRoutes,
  commercialBonus as commercialBonusRoutes
} from '../../Route/routes';

import { ParameterTypes } from '../../../Dashboard/Constant';

import { transformFieldToParamaterChoice } from './common';

export const listStat = {
  id: "listStat",
  name: strings.dashboard.widget.listStat.name,
  icon: "icon-view-list",
  column: {
    min: 2,
    max: 2
  },
  row: {
    min: 2,
    max: 2
  },
  elementSettings: {
    quote: {
      name: strings.dashboard.entity.quote,
      fetch: quoteStatistic,
      link: () => quotesRoutes.routes.index.createPath(),
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.quote,
              role: ["ROLE_USER", "ROLE_NYUKOM"]
            }
          },
        },
        average: {
          label: strings.dashboard.widget.listStat.stat.average,
          attribute: {
            signature_days: {
              label: strings.dashboard.widget.listStat.attribute.quote.signatureDays,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        minimum: {
          label: strings.dashboard.widget.listStat.stat.minimum,
          attribute: {
            signature_days: {
              label: strings.dashboard.widget.listStat.attribute.quote.signatureDays,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        maximum: {
          label: strings.dashboard.widget.listStat.stat.maximum,
          attribute: {
            signature_days: {
              label: strings.dashboard.widget.listStat.attribute.quote.signatureDays,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
      },
      units: {
        id: "devis",
        signature_days: strings.common.units.days
      },

      filters: {
        formKey: quoteConf.index.searchForm.formKey,
        fields: quoteConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "state":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: quoteConf.index.computeSearch,

      role: "ROLE_QUOTE"
    },
    order: {
      name: strings.dashboard.entity.order,
      fetch: orderStatistic,
      link: () => ordersRoutes.routes.index.createPath(),
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.order,
              role: ["ROLE_USER", "ROLE_NYUKOM"]
            }
          },
        },
        average: {
          label: strings.dashboard.widget.listStat.stat.average,
          attribute: {
            delivery_days: {
              label: strings.dashboard.widget.listStat.attribute.order.deliveryDays,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        minimum: {
          label: strings.dashboard.widget.listStat.stat.minimum,
          attribute: {
            delivery_days: {
              label: strings.dashboard.widget.listStat.attribute.order.deliveryDays,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        maximum: {
          label: strings.dashboard.widget.listStat.stat.maximum,
          attribute: {
            delivery_days: {
              label: strings.dashboard.widget.listStat.attribute.order.deliveryDays,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
      },

      filters: {
        formKey: orderConf.index.searchForm.formKey,
        fields: orderConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "state":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: orderConf.index.computeSearch,

      role: "ROLE_ORDER"
    },
    commercialTicket: {
      name: strings.dashboard.entity.commercialTicket,
      fetch: ticketStatisitc("commercial"),
      link: () => commercialTicketRoutes.routes.index.createPath(),
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.ticket,
              role: ["ROLE_USER", "ROLE_NYUKOM"]
            }
          },
        },
        average: {
          label: strings.dashboard.widget.listStat.stat.average,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        minimum: {
          label: strings.dashboard.widget.listStat.stat.minimum,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        maximum: {
          label: strings.dashboard.widget.listStat.stat.maximum,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
      },

      filters: {
        formKey: commercialTicketConf.index.searchForm.formKey,
        fields: commercialTicketConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "criticity":
            case "state":
            case "escalate":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            case "referee":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.USER, true);
            default:
          }
          return field
        })
      },
      computeFilters: commercialTicketConf.index.computeSearch,

      role: ["ROLE_USER", "ROLE_NYUKOM"]
    },
    service: {
      name: strings.dashboard.entity.service,
      fetch: serviceStatistic,
      link: () => serviceRoutes.routes.index.createPath(),
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.service,
              role: ["ROLE_USER", "ROLE_NYUKOM"]
            }
          },
        },
      },

      filters: {
        formKey: serviceConf.index.searchForm.formKey,
        fields: serviceConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "serialNumber":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "state":
              return transformFieldToParamaterChoice(field);
            case "partner":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: serviceConf.index.computeSearch,

      role: ["ROLE_USER", "ROLE_NYUKOM"]
    },
    technicalTicket: {
      name: strings.dashboard.entity.technicalTicket,
      fetch: ticketStatisitc("technical"),
      link: () => technicalTicketRoutes.routes.index.createPath(),
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.ticket,
              role: ["ROLE_USER", "ROLE_NYUKOM"]
            }
          },
        },
        average: {
          label: strings.dashboard.widget.listStat.stat.average,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        minimum: {
          label: strings.dashboard.widget.listStat.stat.minimum,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        maximum: {
          label: strings.dashboard.widget.listStat.stat.maximum,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
      },

      filters: {
        formKey: technicalTicketConf.index.searchForm.formKey,
        fields: technicalTicketConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "criticity":
            case "state":
            case "escalate":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            case "referee":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.USER, true);
            default:
          }
          return field
        })
      },
      computeFilters: technicalTicketConf.index.computeSearch,

      role: ["ROLE_USER", "ROLE_NYUKOM"]
    },
    administrativeTicket: {
      name: strings.dashboard.entity.administrativeTicket,
      fetch: ticketStatisitc("administrative"),
      link: () => administrativeTicketRoutes.routes.index.createPath(),
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.ticket,
              role: ["ROLE_USER", "ROLE_NYUKOM"]
            }
          },
        },
        average: {
          label: strings.dashboard.widget.listStat.stat.average,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        minimum: {
          label: strings.dashboard.widget.listStat.stat.minimum,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        maximum: {
          label: strings.dashboard.widget.listStat.stat.maximum,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              adapter: (value) => Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          },
        },
      },

      filters: {
        formKey: administrativeTicketConf.index.searchForm.formKey,
        fields: administrativeTicketConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "criticity":
            case "state":
            case "escalate":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            case "referee":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.USER, true);
            default:
          }
          return field
        })
      },
      computeFilters: administrativeTicketConf.index.computeSearch,

      role: ["ROLE_USER", "ROLE_NYUKOM"]
    },
    bill: {
      name: strings.dashboard.entity.bill,
      fetch: billStatistic,
      link: () => billRoutes.routes.index.createPath(),
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.bill,
              role: ["ROLE_NYUKOM"]
            }
          }
        },
        average: {
          label: strings.dashboard.widget.listStat.stat.average,
          attribute: {
            total_ti: {
              label: strings.dashboard.widget.listStat.attribute.bill.totalTi,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            total_tf: {
              label: strings.dashboard.widget.listStat.attribute.bill.total_tf,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            }
          }
        },
        minimum: {
          label: strings.dashboard.widget.listStat.stat.minimum,
          attribute: {
            total_ti: {
              label: strings.dashboard.widget.listStat.attribute.bill.totalTi,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            total_tf: {
              label: strings.dashboard.widget.listStat.attribute.bill.total_tf,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            }
          }
        },
        maximum: {
          label: strings.dashboard.widget.listStat.stat.maximum,
          attribute: {
            total_ti: {
              label: strings.dashboard.widget.listStat.attribute.bill.totalTi,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            total_tf: {
              label: strings.dashboard.widget.listStat.attribute.bill.total_tf,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            }
          }
        },
        sum: {
          label: strings.dashboard.widget.listStat.stat.sum,
          attribute: {
            total_ti: {
              label: strings.dashboard.widget.listStat.attribute.bill.totalTi,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            total_tf: {
              label: strings.dashboard.widget.listStat.attribute.bill.total_tf,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            }
          }
        },
      },

      filters: {
        formKey: billConf.index.searchForm.formKey,
        fields: billConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "partner":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: billConf.index.computeSearch,

      role: "ROLE_ACCOUNTING_BILL"
    },

    commercialBonus: {
      name: strings.dashboard.entity.commercialBonus,
      fetch: commercialBonusStatistic, //TODO
      link: () => commercialBonusRoutes.routes.index.createPath(),
      stat: {
        sum: {
          label: strings.dashboard.widget.listStat.stat.sum,
          attribute: {
            flat_bonus_value: {
              label: strings.dashboard.widget.listStat.attribute.commercialBonus.flatBonusValue,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
          }
        },
      },

      filters: {
        formKey: commercialBonusConf.index.searchForm.formKey,
        fields: commercialBonusConf.index.searchForm.fields
      },
      computeFilters: commercialBonusConf.index.computeSearch,

      role: ["ROLE_COMMERCIAL_BONUS"]
    },
  },
  visualSettings: [

  ],
  dataSettings: [
    {
      fieldKey: "stats",
      type: "array",
      label: "",
      validators: [],

      defaultNumberOfField: 4,
      allowMoreField: false,

      subField: {
        type: "subform",
        label: "Statistique #key#",

        validators: [],

        subForm: {
          formKey: "stats",
          fields: [
            {
              fieldKey: "title",
              type: "text",
              label: "Titre",
              showOnDisabled: () => false,
              validators: []
            },
            {
              fieldKey: "stat",
              type: "dropdown",
              label: "Statistique",
              showOnDisabled: () => false,
              validators: [],

              input: 'formValues',
              finalValues: 'values',
              compute: (getValues, extraValues) => {
                let element = getValues().element;
                if(element && listStat.elementSettings[element]) {
                  return Object.keys(listStat.elementSettings[element].stat).map(stat => {
                    return {
                      id: stat,
                      label: listStat.elementSettings[element].stat[stat].label
                    }
                  })
                }
                return []
              },
              adapter: {
                requirement: (data) => data && data["id"] && data["label"],
                getValue: (data) => data["id"],
                getLabel: (data) => data["label"],
                isFinalValue: true
              },
            },

            {
              fieldKey: "attribute",
              type: "dropdown",
              label: "Attribut",
              showOnDisabled: () => false,
              validators: [],

              input: 'formValues',
              finalValues: 'values',
              compute: (getValues, extraValues) => {
                let element = getValues().element;
                let stat = getValues(0).stat;
                if(element && listStat.elementSettings[element] && stat) {
                  return Object.keys(listStat.elementSettings[element].stat[stat.id?stat.id:stat].attribute)
                    .filter(id => {
                      // console.log(listStat.elementSettings[element].stat[stat.id?stat.id:stat].attribute[id]);
                      let credentials = extraValues.credentials();
                      if(credentials) {
                        if(listStat.elementSettings[element].stat[stat.id?stat.id:stat].attribute[id].role) {
                          let roles = listStat.elementSettings[element].stat[stat.id?stat.id:stat].attribute[id].role;
                          // if(typeof roles === 'function') {
                          //   roles = roles(getValues, extraValues);
                          // }
                          if(!Array.isArray(roles)) {
                            roles = [roles];
                          }

                          return roles.some(role => credentials.roles.includes(role));
                        }
                        return true;
                      }
                      return false;
                    })
                    .map(id => {
                      return {
                        id: id,
                        label: listStat.elementSettings[element].stat[stat.id?stat.id:stat].attribute[id].label
                      }
                    })
                }
                return []
              },
              adapter: {
                requirement: (data) => data && data["id"] && data["label"],
                getValue: (data) => data["id"],
                getLabel: (data) => data["label"],
                isFinalValue: true
              },
            },
            {
              fieldKey: "filters",
              type: "configurableField",
              label: "",
              validators: [],

              subField: (value, getValues, getExtraValues) => {
                let element = getValues().element;
                if(element && listStat.elementSettings[element]) {
                  return {
                    subForm: listStat.elementSettings[element].filters
                  }
                }
                return {
                  subForm: {
                    fields: {}
                  }
                }
              },
              subFieldConf: {
                label: () => "",
                type: () => "subform",
                disabled: () => false,
                validators: () => [],
              }
            }
          ]
        }
      },
    }
  ]
}
