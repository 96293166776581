import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  editFromId,
  newItem
} from '../../API/TicketTypes';
import { form } from '../Form/ticketTypes';
import { form as searchForm } from '../Form/Search/ticketTypes';

import {
  technicalTicketTypes as technicalTicketTypesRoutes,
  commercialTicketTypes as commercialTicketTypesRoutes,
  administrativeTicketTypes as administrativeTicketTypesRoutes,
  installationTicketTypes as installationTicketTypesRoutes,
  terminationTicketTypes as terminationTicketTypesRoutes,
  technicalGlobalTicketTypes as technicalGlobalTicketTypesRoutes
} from '../Route/routes';

import { snakeToCamel } from '../../Utils/SnakeToCamel';

function getRoutes(identifier) {
  if(identifier === 'technical') {
    return technicalTicketTypesRoutes
  }
  else if(identifier === 'commercial') {
    return commercialTicketTypesRoutes
  }
  else if(identifier === 'administrative') {
    return administrativeTicketTypesRoutes
  }
  else if(identifier === 'installation') {
    return installationTicketTypesRoutes
  }
  else if(identifier === 'termination') {
    return terminationTicketTypesRoutes
  }
  else if(identifier === 'technical_global') {
    return technicalGlobalTicketTypesRoutes
  }
  else {
    console.error("missing identifier for ticket creation : "+identifier);
  }
}

function createConf(identifier, roleIdentifier, newAvailable = false) {

  let identifierCC = snakeToCamel(identifier);

  const _index = index(identifier);
  const _getFromId = getFromId(identifier);
  const _editFromId = editFromId(identifier);
  const _newItem = newItem(identifier);

  let indexConf = {
    pageTitle: strings.common.homepage[identifierCC+"TicketTypes"],

    id: "ticketType",
    api: _index,
    onClickItem: {
      redirect: true,
      path: (id) => getRoutes(identifier).routes.show.createPath(id)
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.name) {
        result["name"] = search.name;
      }
      return result;
    },

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => item["name"],
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  }

  if(newAvailable) {
    indexConf.newLink = {
      link: () => getRoutes(identifier).routes.new.createPath(),
      auth: 'ROLE_'+roleIdentifier+'_TICKET_TYPE'
    };
  }

  let newConf = undefined;
  if(newAvailable) {
    newConf = {
      pageTitle: strings.ticketType.new.title,

      api: _newItem,
      empty: {},
      form: form,
      onSubmit: (item) => {},
      postNew: {
        type: 'path',
        path: (id) => getRoutes(identifier).routes.show.createPath(id)
      }
    }
  }

  return {
    prefix: "ticketTypes/"+identifierCC,
    index: indexConf,
    show: {
      pageTitle: strings.common.homepage[identifierCC+"TicketTypes"],
      pageTitleReturnPath: () => getRoutes(identifier).routes.index.createPath(),
      breadcrumbIdentifier: "name",

      apiGet: _getFromId,

      extendedHeader: false,

      tabs: {
        main: {
          title: strings.ticketType.tab.main,
          type: 'form',
          form: form,

          onUpdateItem: (data) => {},
          onSubmit: (entity) => {},
          postEdit: {
            type: 'tab',
            tabId: 'main'
          },

          apiGet: _getFromId,
          apiEdit: _editFromId,

          role: ['ROLE_'+roleIdentifier+'_TICKET_TYPE'],
          editable: ['ROLE_'+roleIdentifier+'_TICKET_TYPE']
        }
      }
    },
    new: newConf
  }
}

export const technicalTicketTypeConf = createConf('technical', 'TECHNICAL', true);
export const commercialTicketTypeConf = createConf('commercial', 'COMMERCIAL', true);
export const administrativeTicketTypeConf = createConf('administrative', 'ADMINISTRATIVE', true);

export const installationTicketTypeConf = createConf('installation', 'TECHNICAL');
export const terminationTicketTypeConf = createConf('termination', 'TECHNICAL');

export const technicalGlobalTicketTypeConf = createConf('technical_global', 'TECHNICAL', true);
