import React from 'react';

import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

// let body = {
//   fieldKey: "body",
//   type: "textarea",
//   label: strings.form.comment.body.label,
//   validators: []
// }

let body = {
  fieldKey: "body",
  type: "configurableField",
  label: "",
  validators: [],

  subField: () => {},
  subFieldConf: {
    label: (value, getValues) => {
      return <>{getValues(0)["hidden"]?<><i className="icon-masquercomment"></i>&nbsp;&nbsp;</>:''}{(!getValues(0)["hidden"] && getValues(0)["send_notification"])?<><i className="icon-mail"></i>&nbsp;&nbsp;</>:''}{strings.form.comment.body.label}</>
    },
    type: () => 'textarea',
    disabled: () => false,
    validators: () => [
      {
        id: 'assertEmpty',
        type: 'lengthMin',
        conf: {
          min:1
        },
        invalidFeedback: strings.form.comment.body.invalid.assertEmpty,
        defaultValue: false
      },
    ],
  }
}

let hidden = (type) => {
  let requiredRole_1 = null;
  let requiredRole_2 = null;
  switch (type) {
    case 'technical':
    case 'installation':
    case 'termination':
    case 'technicalGlobal':
    case 'technical_global':
      requiredRole_1 = "ROLE_TECHNICAL_TICKET_COMMENT_INNER_CREATE";
      requiredRole_2 = "ROLE_TECHNICAL_TICKET_COMMENT_CREATE";
      break;
    case 'commercial':
      requiredRole_1 = "ROLE_COMMERCIAL_TICKET_COMMENT_INNER_CREATE";
      requiredRole_2 = "ROLE_COMMERCIAL_TICKET_COMMENT_CREATE";
      break;
    case 'administrative':
      requiredRole_1 = "ROLE_ADMINISTRATIVE_TICKET_COMMENT_INNER_CREATE";
      requiredRole_2 = "ROLE_ADMINISTRATIVE_TICKET_COMMENT_CREATE";
      break;
    default:
  }
  return {
    fieldKey: "hidden",
    type: "switch",
    label: strings.form.comment.hidden.label,
    disabled: (getValues, getExtraValues) => {
      let credentials = getExtraValues().credentials();
      return !credentials || !(credentials.roles.includes(requiredRole_1) && credentials.roles.includes(requiredRole_2));
    },
    showOnDisabled: () => false,
    defaultValue: false,
    validators: []
  }
}

let sendNotification = {
  fieldKey: "send_notification",
  type: "switch",
  label: strings.form.comment.sendNotification.label,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return getValues(0)["hidden"] || !(credentials.roles.includes('ROLE_NYUKOM'));
  },
  showOnDisabled: () => false,
  defaultValue: false,
  validators: []
}

let closeTicket = (type) => {
  let requiredRole = null;
  switch (type) {
    case 'technical':
    case 'installation':
    case 'termination':
    case 'technicalGlobal':
    case 'technical_global':
      requiredRole = "ROLE_TECHNICAL_TICKET_EDIT";
      break;
    case 'commercial':
      requiredRole = "ROLE_COMMERCIAL_TICKET_EDIT";
      break;
    case 'administrative':
      requiredRole = "ROLE_ADMINISTRATIVE_TICKET_EDIT";
      break;
    default:
  }
  return {
    fieldKey: "close_ticket",
    type: "switch",
    label: strings.form.ticket.closeTicket.label,
    disabled: (getValues, getExtraValues) => {
      let credentials = getExtraValues().credentials();
      return !credentials || !(credentials.roles.includes(requiredRole));
    },
    showOnDisabled: () => false,
    defaultValue: false,
    validators: []
  }
}

export const form = {
  formKey: "comment",
  fields: [
    body
  ]
}

export const newForm = (type) => {
  return {
    formKey: "newComment",
    fields: [
      body,
      hidden(type),
      sendNotification,
      closeTicket(type)
    ],
    extraValues: {
      credentials: () => store.getState().persisted.credentials
    }
  }
}
