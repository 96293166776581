import React from 'react';
import BasicFormField from '../../BasicFormField';

import { Form, Row, Col } from 'react-bootstrap';
import '../../../../../css/form.css';

import FormFieldPrice from '../../FormFieldPrice'

/**
 * FormFieldColor
 *
 * This class handles HTML form field.
 *
 * The particular field is for color picker.
 *
 * Specific configuration attribute : none
 */
class DashboardFormFieldPrice extends BasicFormField {

  /**
   * @inheritdoc
   */
  getValue(){
    if(this.props.value === undefined || this.props.value === null) {
      return '';
    }
    let value = super.getValue();

    value = value.toString();
    value = value.replace('.', ',');

    return value;
  }

  getFormattedValue() {
    return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(super.getValue())
  }

  /**
   * @inheritdoc
   */
  getFinalValue(){
    let value = this.getValue();
    value = FormFieldPrice.parseFinalValue(value);

    return (value !== null && value !== undefined)?value:this.props.defaultValue;
  }

  static parseFinalValue(value) {
    if(typeof value === 'string') {
      value = value.replace(',', '.');
      value = value.replaceAll(/\s/g, '');
    }
    return parseFloat(value);
  }

  /**
   * @inheritdoc
   */
  onChange = (value) => {
    //check if the value is a number
    if(value !== undefined && value !== null) {

      value = value.toString();
      let regex = /^-?(\d{0,2})(\d{3})*([.,]\d{0,5})?$/g;
      if(value.match(regex)) {
        super.onChange(value);
      }
    }
  }

  displayInput() {
    return (
      <Form.Control
        className="d-flex dashboard-field-price"
        placeholder={this.props.label}
        aria-label={this.props.label}
        aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
        type="text"
        disabled={this.props.disabled || this.props.submitting}
        value={this.getValue()}
        onChange={(event) => this.onChange(event.target.value)}
        isValid={(this.props.disabled)?null:this.isValid()}
        isInvalid={(this.props.disabled)?null:this.isInvalid()}/>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className={`dashboard-form-row${!this.props.skipSeparator?" dashboard-form-row-separator":""}`}>
        <Row className="d-flex align-items-center no-gutters">
          {this.props.label !== ""?
            <Col xs="auto" className="no-gutters dashboard-field-price-label">
              {this.props.label}&nbsp;{this.displayValidating()}&nbsp;&nbsp;&nbsp;{this.shouldDisplayMiddlePart()?<>&nbsp;&nbsp;&nbsp;{this.displayMiddlePart()}</>:''}
            </Col>
          :undefined}
          {this.props.label === "" && this.shouldDisplayMiddlePart()?
            <>
              <Col xs={12} className="no-gutters dashboard-field-price-label">
                {this.displayValidating()}{this.displayMiddlePart()}
              </Col>
            </>
          :undefined}
          <Col xs={this.props.label === ""?12:""} className="no-gutters">
            {this.displayInput()}
          </Col>
        </Row>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    )
  }
}

export default DashboardFormFieldPrice;
