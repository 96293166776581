import React from 'react';
import FormFieldDropdown from '../../FormFieldDropdown';

import { Form, Col, InputGroup } from 'react-bootstrap';
import '../../../../../css/form.css';
import '../../../../../css/call_report_form.css';

/**
 * CallReportFormFieldDropdown
 *
 * Specific configuration attribute : none
 */
class CallReportFormFieldDropdown extends FormFieldDropdown {

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className="container-form-field container-form-field-call-report">
        {this.displayOptionsAsDatalist()}
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
        <InputGroup.Prepend
          className="d-flex col-auto container-form-prepend">
          <InputGroup.Text
            id={`form-${this.props.formKey}-${this.props.fieldKey}`}
            className="col-md-12">
            {this.props.icons?this.props.icons.map((icon, i) => {
              return (
                <i key={i} className={`icon-${icon}`}/>
              )
            }):undefined}
          </InputGroup.Text>
        </InputGroup.Prepend>
          {this.displayMiddlePart()}
          <Form.Control
            as={this.props.autoComplete?"input":"select"}
            className="d-flex col-border-radius col-md-border-radius"
            placeholder={this.props.label}
            aria-label={this.props.label}
            aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
            type="text"
            list={this.props.autoComplete?this.getDatalistId():null}
            disabled={this.props.disabled || this.props.submitting || this.state.loading}
            value={this.getValue()}
            onChange={(event) => this.onChange(event.target.value)}
            isValid={(this.props.disabled)?null:this.isValid()}
            isInvalid={(this.props.disabled)?null:this.isInvalid()}
          >
            {this.displayOptionsAsSelect()}
          </Form.Control>
          {this.displayValidating()}
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    );
  }
}

export default CallReportFormFieldDropdown;
