import React from 'react';

import { Col, Button } from 'react-bootstrap';

import AbstractReference from './AbstractReference';

import MyForm from '../../Form/MyForm';

import AsyncCounter from '../../../Utils/Counter/AsyncCounter';

import {
  newForm,
  newCommentForm,
  newAppointmentForm
} from '../../../MetaData/Form/tickets';

class NewTicketReference extends AbstractReference {

  constructor(props) {
    super(props);

    this.counter = new AsyncCounter();

    this.newTicketFormRef = React.createRef();
    this.commentFormRef = React.createRef();
    this.appointmentFormRef = React.createRef();

    if(props.ticketType && props.ticketType) {
      this.newTicketForm = newForm(props.ticketType);
    }
    this.newCommentForm = newCommentForm(props.ticketType);
    this.newAppointmentForm = newAppointmentForm(props.ticketType);
  }

  askForValidation = () => {
    this.counter.reset();

    let quantity = 1;
    if(this.props.ticket._actionType === "ticketComment" || this.props.ticket._actionType === "ticketAppointment") {
      quantity += 1;
    }

    this.counter.setValue(quantity);
    this.counter.subscribe(0, this.validate);

    this.newTicketFormRef.current.onSubmit();
    if(this.props.ticket._actionType === "ticketComment") {
      this.commentFormRef.current.onSubmit();
    }
    if(this.props.ticket._actionType === "ticketAppointment") {
      this.appointmentFormRef.current.onSubmit();
    }
  }

  validate = () => {
    if(this.props.validationCounterDecrement) {
      this.props.validationCounterDecrement()
    }
  }

  invalidate = () => {
    this.counter.reset();
    if(this.props.validationCounterDecrement) {
      this.props.validationCounterFail()
    }
  }

  displayNewTicket() {
    if(this.newTicketForm) {
      return (
        <div>
          {this.displayTitle("Nouveau ticket")}
          {this.displaySeparator()}
          <MyForm
            ref={this.newTicketFormRef}
            formKey={`call-report-reference-${this.props.innerKey}-${this.newTicketForm.formKey}`}
            fields={this.newTicketForm.fields}
            extraValues={this.newTicketForm.extraValues}
            extraData={this.newTicketForm.extraData}

            disabled={false}
            values={this.props.ticket}
            onChangeField={(key, value) => this.props.modify(key, value)}

            displaySubmitButton={false}
            onSubmit={this.validate}
            onSubmitFailed={this.invalidate}

            formExtraClassName="call-report-references-form"
            //fieldVariant="callReport"
            formSizeMd={12}
          />
          {this.displayTicketAction()}
        </div>
      )
    }
  }

  displayTicketAction() {
    if(this.props.ticket) {
      return (
        <>
          {this.displaySeparator()}
          <div className="d-flex justify-content-center">
            <Button variant="none" className={`btn-temp-stick${(this.props.ticket._actionType === "ticketComment")?" btn-temp-stick-selected":""}`} onClick={() => this.props.modify("_actionType", (this.props.ticket._actionType !== "ticketComment")?"ticketComment":"")}>Commentaire</Button>
            <Button variant="none" className={`btn-temp-stick${(this.props.ticket._actionType === "ticketAppointment")?" btn-temp-stick-selected":""}`} onClick={() => this.props.modify("_actionType", (this.props.ticket._actionType !== "ticketAppointment")?"ticketAppointment":"")}>Intervention</Button>
          </div>
          {this.displayTicketComment()}
          {this.displayTicketAppointment()}
        </>
      )
    }
  }

  displayTicketComment() {
    if(this.props.ticket._actionType === "ticketComment") {
      return (
        <>
          {this.displaySeparator()}
          <MyForm
            ref={this.commentFormRef}
            formKey={`call-report-reference-${this.props.innerKey}-${this.newCommentForm.formKey}`}
            fields={this.newCommentForm.fields}
            extraValues={{
              ...this.newCommentForm.extraValues,
              type: this.props.ticketType
            }}
            extraData={this.newCommentForm.extraData}

            disabled={false}
            values={this.props.ticket._ticketComment}
            onChangeField={(key, value) => {
              let ticketComment = Object.assign({}, this.props.ticket._ticketComment)
              ticketComment[key] = value;
              this.props.modify("_ticketComment", ticketComment)
            }}

            displaySubmitButton={false}
            onSubmit={this.validate}
            onSubmitFailed={this.invalidate}

            formExtraClassName="call-report-references-form"
            //fieldVariant="callReport"
            formSizeMd={12}
          />
        </>
      )
    }
  }

  displayTicketAppointment() {
    if(this.props.ticket._actionType === "ticketAppointment" && this.props.ticket["request_type"] && this.props.ticket.client) {
      return (
        <>
          {this.displaySeparator()}
          <MyForm
            ref={this.appointmentFormRef}
            formKey={`call-report-reference-${this.props.innerKey}-${this.newAppointmentForm.formKey}`}
            fields={this.newAppointmentForm.fields}
            extraValues={{
              ...this.newAppointmentForm.extraValues,
              type: this.props.ticketType
            }}
            extraData={this.newAppointmentForm.extraData}

            disabled={false}
            values={Object.assign({}, this.props.ticket._ticketAppointment, {
              request_type: this.props.ticket["request_type"],
              client: { partner: this.props.ticket.client }
            })}
            onChangeField={(key, value) => {
              let ticketAppointment = Object.assign({}, this.props.ticket._ticketAppointment)
              ticketAppointment[key] = value;
              this.props.modify("_ticketAppointment", ticketAppointment)
            }}

            displaySubmitButton={false}
            onSubmit={this.validate}
            onSubmitFailed={this.invalidate}

            formExtraClassName="call-report-references-form"
            //fieldVariant="callReport"
            formSizeMd={12}
          />
        </>
      )
    }
  }

  render() {
    return (
      <div className="call-report-history-container">
        <Col>
          {this.displayNewTicket()}
        </Col>
      </div>
    );
  }
}


export default NewTicketReference;
