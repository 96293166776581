export const CREATE_CALL_REPORT = 'create_call_report';
export const MODIFY_CALL_REPORT = 'modify_call_report';
export const RESET_CALL_REPORT = 'reset_call_report';

export function createCallReportAction(id, number = null, inout = "in"){
  return {
    type: CREATE_CALL_REPORT,
    id: id,
    number: number,
    inout: inout
  }
}

export function modifyCallReportAction(id, key, value){
  return {
    type: MODIFY_CALL_REPORT,
    id: id,
    key: key,
    value: value
  }
}

export function resetCallReportAction(id){
  return {
    type: RESET_CALL_REPORT,
    id: id
  }
}
