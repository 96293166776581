import React from 'react';

import strings from '../../Localization/Localization';

import '../../css/banner.css';

import { withModalHandler } from '../../HOC/ModalHandler';

import { fetchActiveTickets } from '../../API/Tickets';

import { getRoutes } from '../../MetaData/Configuration/tickets';

class BannerCenter extends React.Component {

	constructor(props){
    super(props);

		this.fetchTechnicalGlobalActiveTickets = this.props.modalHandler.addVerificationWithCallback(fetchActiveTickets("technical_global"), this.postFetchTechnicalGlobalActiveTickets, this.postFetchTechnicalGlobalActiveTicketsFailure);

    this.state = {
			loadingTechnicalGlobalTicket: false,

			technicalGlobalTickets: [],
    }
  }

	componentDidMount() {
		this.fetchTechnicalGlobalActiveTickets();
	}

	postFetchTechnicalGlobalActiveTickets = (data) => {
		this.setState({
			technicalGlobalTickets: data.items
		})
	}

	postFetchTechnicalGlobalActiveTicketsFailure = (msg) => {
		console.log(msg);
	}

	displayBanner(key, text, link = null) {
		if(link != null) {
			return (
				<div key={key} className="banner-container">
					<a href={link}>
						<div className="banner banner-danger">
				      <div className={`${(text.length>200)?"banner-scrolling-element":"base-banner"}`}>
								{text}
							</div>
						</div>
					</a>
				</div>
	    );
		}
		return (
			<div key={key} className="banner-container">
				<div className="banner banner-danger">
		      <div className={`${(text.length>200)?"banner-scrolling-element":"base-banner"}`}>
						{text}
					</div>
				</div>
			</div>
    );
	}

	displayTechnicalGlobalTicketBanner(technicalGlobalTicket) {
		return this.displayBanner(
			`ticket-${technicalGlobalTicket.id}`,
			`${technicalGlobalTicket["ticket_origin"]["product_sub_family"]["product_family"].name} > ${technicalGlobalTicket["ticket_origin"]["product_sub_family"].name} > ${technicalGlobalTicket.title}`,
			getRoutes("technical_global").routes.show.createPath(technicalGlobalTicket.id)
		);
	}

	displayBanners() {
		return (
			<>
				{this.state.technicalGlobalTickets.map(technicalGlobalTicket => this.displayTechnicalGlobalTicketBanner(technicalGlobalTicket))}
			</>
		)
	}

  render() {
    return (
			<>
				{this.displayBanners()}
			</>
		)
  }
}

export default withModalHandler(BannerCenter);
