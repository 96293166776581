import React from 'react';
import FormFieldDropdown from './FormFieldDropdown';
import strings from '../../../Localization/Localization';

import { Form, InputGroup, Col } from 'react-bootstrap';

import Loading from '../../Layout/Loading';

import { connect } from 'react-redux';
import {
  addOption,
  startLoading,
  addItemsToOption,
  reloadOption
} from '../../../Store/Action/optionsContainer';

import '../../../css/form.css';

/**
 * FormFieldDropdownEntity
 *
 * This class handles HTML form field.
 *
 * The particular field is a Dropdown, it allow to choose from a list.
 *
 * Specific configuration attribute :
 * - showEmpty : inherit from FormFieldDropdown
 * - adapter : inherit from FormFieldDropdown
 * - filterOptions : inherit from FormFieldDropdown
 * - autoComplete : inherit from FormFieldDropdown
 *
 * - loader :
 *    - id : the id for the the storage of the values
 *    - loader : the function that will load the options
 *    - loaderRequire : a function that will test if the arguments are ready
 *                    for the function
 *    - loaderArguments : the arguments of the loader function
 *    - dataTransformation : the transformation required if the options are
 *                         nested in the loader reply
 *
 * - storage : either 'state' (default) or 'redux'
 * - shareStorage : true, the storage will be shared with other dropdown with
 *                the same loader ID (only work with redux storage)
 *                  false, the storage won't be shared
 */
export class FormFieldDropdownEntity extends FormFieldDropdown {

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the class.
   */
  static defaultProps = {
    showEmpty: false,
    autoComplete: true,
    input: 'load',
    storage: 'state',
    shareStorage: true,

    assertObjectDefaultValue: false
  }

  constructor(props) {
    super(props);

    if(props.loader && props.loader.loader) {
      this.loader = this.props.modalHandler.addVerificationWithCallback(props.loader.loader, this.postLoad, this.postLoadFailure, true);
      this.storageId = `${props.loader.id}${!props.shareStorage?'-'+this.uuid:''}`;
    }
    else {
      this.loader = () => console.log("No loader was configured for the field : "+props.fieldKey);
      this.storageId = "";
    }

    //the state of the component
    this.state = Object.assign(this.state, {
      loading: false,
      loaded: false,
      loadingFailure: false,
      options: [],
    })
  }

  componentDidMount() {
    if(!this.props.disabled) {
      this.checkOptions();
    }
  }

  componentDidUpdate() {
    if(!this.props.disabled) {
      this.checkOptions();
    }
  }

  /**
   * @inheritdoc
   */
  getSpecificValidators() {
    let validators = [];
    if(this.state && this.getFilteredOptions().length !== 0) {
      validators.push({
        id: 'assertObject',
        type: 'function',
        conf: {
          function: (value) => {
            return typeof value === 'object'
          }
        },
        invalidFeedback: strings.form.default.field.dropdownEntity.invalid.assertObject,
        defaultValue: this.props.assertObjectDefaultValue,
      });
    }
    return validators;
  }

  reloadData() {
    Promise.resolve().then(() => {
      this.onChange('', true);
    }).then(() => {
      switch (this.props.storage) {
        case 'state':
          this.setState({
            loading: false,
            loaded: false,
            options: [],
          })
          break;
        case 'redux':
          this.props.dispatch(reloadOption(this.storageId));
          break;
        default:
      }
    })
  }

  loadData() {
    if(this.props.loader.loaderRequire(this.props.value, this.props.getValues, this.props.getExtraValues)) {
      let myArguments = this.props.loader.loaderArguments(this.props.value, this.props.getValues, this.props.getExtraValues);
      switch (this.props.storage) {
        case 'state':
          Promise.resolve().then(() => {
            if(!this.state.loading) {
              this.setState({
                loading: true,
              }, () => {
                this.loader(...myArguments);
              });
            }
          })
          break;
        case 'redux':
          Promise.resolve().then(() => {
            this.props.dispatch(startLoading(this.storageId));
          }).then(() => {
            this.loader(...myArguments);
          })
          break;
        default:
          console.log('wrong storage used in FormFieldDropdownEntity');
      }

    }
  }

  /**
   * callback collecting the result of the call
   */
  postLoad = (data) => {
    let items = null;
    if(this.props.loader.dataTransformation) {
      items = this.props.loader.dataTransformation(data);
    }
    else {
      items = data.items;
    }
    this.setOptions(items);
  }

  /**
   * callback collecting the result of the call
   */
  postLoadFailure = (msg) => {
    this.setState({
      loaded: true,
      loading: false,
      loadingFailure: true,
    })
  }

  /**
   * @inheritdoc
   */
  resetOptions() {
    switch (this.props.storage) {
      case 'state':
        this.setState({
          loaded: true,
          loading: false,
          options: []
        })
        break;
      case 'redux':
        this.props.dispatch(addItemsToOption(this.storageId, []));
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
  }

  /**
   * @inheritdoc
   */
  getOptions() {
    switch (this.props.storage) {
      case 'state':
        if(this.state.loaded && this.state.options) {
          return this.state.options;
        }
        break;
      case 'redux':
        if(this.props.optionsContainer[this.storageId]
          && this.props.optionsContainer[this.storageId].loaded && this.props.optionsContainer[this.storageId].options) {
          return this.props.optionsContainer[this.storageId].options;
        }
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
    return [];
  }

  /**
   * @inheritdoc
   */
  setOptions(options = []) {
    switch (this.props.storage) {
      case 'state':
        this.setState({
          loaded: true,
          loading: false,
          loadingFailure: false,
          options: options
        })
        break;
      case 'redux':
        this.props.dispatch(addItemsToOption(this.storageId, options));
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
  }

  checkOptions() {
    switch (this.props.storage) {
      case 'state':
        if(!this.state.loaded && !this.state.loading) {
          this.loadData();
        }
        break;
      case 'redux':
        if(!this.props.optionsContainer[this.storageId]) {
          this.props.dispatch(addOption(this.storageId, this.uuid));
        }
        else if(this.props.optionsContainer[this.storageId]
          && this.props.optionsContainer[this.storageId].owner === this.uuid
          && !this.props.optionsContainer[this.storageId].loading
          && !this.props.optionsContainer[this.storageId].loaded) {
          this.loadData();
        }
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
  }

  displayOptionsAsSelect() {
    if(this.props.shareStorage
      && this.props.optionsContainer[this.storageId]
      && this.props.optionsContainer[this.storageId].owner === this.uuid) {
      return super.displayOptionsAsSelect();
    }
  }

  displayLoadingError() {
    if(this.state.loadingFailure) {
      return (
        <div className="invalid-feedback" style={{display:"block"}}>
          {strings.form.default.field.dropdownEntity.error.loadingFailure}
        </div>
      )
    }
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <InputGroup.Append>
          <Loading container="parent" containerClassName="loading-form-row" size="small"/>
        </InputGroup.Append>
      )
    }
  }

  /**
   * @inheritdoc
   */
  render() {
    let disabledLink = null;
    if(this.props.disabledLink) {
      disabledLink = this.props.disabledLink(this.props.value, this.props.getValues, this.props.getExtraValues)
    }
    return (
      <Col className="container-form-field">
        {this.displayOptionsAsDatalist()}
        <InputGroup
          className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}
        >
          <InputGroup.Prepend
            className="d-none d-md-flex col-md-4 container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          {
            //If the field is not disabled show the field as normal
            !(this.props.disabled && disabledLink)?
            <Form.Control
              as={this.props.autoComplete?"input":"select"}
              className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius"
              placeholder={this.props.label}
              aria-label={this.props.label}
              aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
              type="text"
              list={this.props.autoComplete?this.getDatalistId():null}
              disabled={this.props.disabled || this.props.submitting || this.state.loading || this.getFilteredOptions().length === 0}
              value={this.getValue()}
              onChange={(event) => this.onChange(event.target.value)}
              isValid={(this.props.disabled)?null:this.isValid()}
              isInvalid={(this.props.disabled)?null:this.isInvalid()}
            >
              {this.displayOptionsAsSelect()}
            </Form.Control>
            :
            //If the field is disabled show a link to send a mail
            <a className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius form-control align-items-center form-disabled" href={disabledLink}>{this.getValue()}</a>
          }
          {this.displayValidating()}
          {this.displayLoading()}
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
        {this.displayLoadingError()}
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  optionsContainer: state.optionsContainer,
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(FormFieldDropdownEntity);
