import React from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * AutoTooltip
 */
class AutoTooltip extends React.Component {

  static defaultProps = {
    text: '',
    timeOnScreen: 5,
  }

  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
    }
  }

  toggle = () => {
    this.setState({
      showTooltip: true
    }, () => {
      setTimeout(
        () => {
          this.setState(
            {showTooltip: false}
          )
        },
        this.props.timeOnScreen*1000
      )
    })
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <OverlayTrigger show={this.state.showTooltip} onToggle={() => null} overlay={<Tooltip id="tooltip-disabled">{this.props.text}</Tooltip>}>
        {this.props.children}
      </OverlayTrigger>
    );
  }
}

export default AutoTooltip;
