import React from 'react';

import { Col } from 'react-bootstrap';

import AbstractReference from './AbstractReference';

class QuoteReference extends AbstractReference {

  displayQuote() {
    if(this.props.quote) {
      return (
        <div>
          {this.displayTitle(`Devis ${this.props.quote.number}`)}
        </div>
      )
    }
  }

  render() {
    return (
      <div className="call-report-history-container">
        <Col>
          {this.displayQuote()}
        </Col>
      </div>
    );
  }
}


export default QuoteReference;
