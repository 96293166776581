import {
  index as baseIndex
 } from './Base';

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all call reports to serve in a dropdown form choice
 */
export function fetchTemporaryUser(search) {
  return baseIndex('call_report_temporary_users', 1, 0, search, 'ASC', 'names_only');
}
