import React from 'react';

import {
  Bill,
  Quote,
  Order,
  Ticket,
  NewTicket,
  Service
} from './ReferenceReference';

class ReferenceSelector extends React.Component {

  static defaultProps = {
    reference: null
  }

  displayReference() {
    if(!this.props.reference) {
      return (
        <div/>
      );
    }

    switch (this.props.reference._callReportType) {
      case "bill":
        return (
          <Bill
            ref={this.props.forwardedRef}

            innerKey={this.props.innerKey}
            bill={this.props.reference}
            modify={this.props.modify}
            delete={this.props.delete}

            validationCounterDecrement={this.props.validationCounterDecrement}
            validationCounterFail={this.props.validationCounterFail}/>
        )
      case "quote":
        return (
          <Quote
            ref={this.props.forwardedRef}

            innerKey={this.props.innerKey}
            quote={this.props.reference}
            modify={this.props.modify}
            delete={this.props.delete}

            validationCounterDecrement={this.props.validationCounterDecrement}
            validationCounterFail={this.props.validationCounterFail}/>
        )
      case "order":
        return (
          <Order
            ref={this.props.forwardedRef}

            innerKey={this.props.innerKey}
            order={this.props.reference}
            modify={this.props.modify}
            delete={this.props.delete}

            validationCounterDecrement={this.props.validationCounterDecrement}
            validationCounterFail={this.props.validationCounterFail}/>
        )
      case "installationTicket":
        return (
          <Ticket
            ref={this.props.forwardedRef}

            innerKey={this.props.innerKey}
            ticket={this.props.reference}
            ticketType="installation"
            modify={this.props.modify}
            delete={this.props.delete}

            validationCounterDecrement={this.props.validationCounterDecrement}
            validationCounterFail={this.props.validationCounterFail}/>
        )
      case "terminationTicket":
        return (
          <Ticket
            ref={this.props.forwardedRef}

            innerKey={this.props.innerKey}
            ticket={this.props.reference}
            ticketType="termination"
            modify={this.props.modify}
            delete={this.props.delete}

            validationCounterDecrement={this.props.validationCounterDecrement}
            validationCounterFail={this.props.validationCounterFail}/>
        )
      case "technicalTicket":
        return (
          <Ticket
            ref={this.props.forwardedRef}

            innerKey={this.props.innerKey}
            ticket={this.props.reference}
            ticketType="technical"
            modify={this.props.modify}
            delete={this.props.delete}

            validationCounterDecrement={this.props.validationCounterDecrement}
            validationCounterFail={this.props.validationCounterFail}/>
        )
      case "commercialTicket":
        return (
          <Ticket
            ref={this.props.forwardedRef}

            innerKey={this.props.innerKey}
            ticket={this.props.reference}
            ticketType="commercial"
            modify={this.props.modify}
            delete={this.props.delete}

            validationCounterDecrement={this.props.validationCounterDecrement}
            validationCounterFail={this.props.validationCounterFail}/>
        )
      case "administrativeTicket":
        return (
          <Ticket
            ref={this.props.forwardedRef}

            innerKey={this.props.innerKey}
            ticket={this.props.reference}
            ticketType="administrative"
            modify={this.props.modify}
            delete={this.props.delete}

            validationCounterDecrement={this.props.validationCounterDecrement}
            validationCounterFail={this.props.validationCounterFail}/>
        )
      case "newTechnicalTicket":
        return (
          <NewTicket
            ref={this.props.forwardedRef}

            innerKey={this.props.innerKey}
            ticket={this.props.reference}
            ticketType="technical"
            modify={this.props.modify}
            delete={this.props.delete}

            validationCounterDecrement={this.props.validationCounterDecrement}
            validationCounterFail={this.props.validationCounterFail}/>
        )
      case "newCommercialTicket":
        return (
          <NewTicket
            ref={this.props.forwardedRef}

            innerKey={this.props.innerKey}
            ticket={this.props.reference}
            ticketType="commercial"
            modify={this.props.modify}
            delete={this.props.delete}

            validationCounterDecrement={this.props.validationCounterDecrement}
            validationCounterFail={this.props.validationCounterFail}/>
        )
      case "newAdministrativeTicket":
        return (
          <NewTicket
            ref={this.props.forwardedRef}

            innerKey={this.props.innerKey}
            ticket={this.props.reference}
            ticketType="administrative"
            modify={this.props.modify}
            delete={this.props.delete}

            validationCounterDecrement={this.props.validationCounterDecrement}
            validationCounterFail={this.props.validationCounterFail}/>
        )
      case "service":
        return (
          <Service
            ref={this.props.forwardedRef}

            innerKey={this.props.innerKey}
            service={this.props.reference}
            modify={this.props.modify}
            delete={this.props.delete}

            validationCounterDecrement={this.props.validationCounterDecrement}
            validationCounterFail={this.props.validationCounterFail}/>
        )
      default:

    }
  }

  render() {
    return (
      <div>
        {this.displayReference()}
      </div>
    );
  }
}


export default ReferenceSelector;
