const callReport = {
  index: {
    tab: {
      headers: {
        number: "Numéro",
      }
    }
  },
  show: {
    tab: {
      main: "Rapport",
      reference: "Références"
    }
  },
  search: {
    inout: "Sens",
    result: "Résultat",
    operator: "Opérateur",
    number: "Numéro",
    user: "Utilisateur",
    partner: "Partenaire",
    beginAtMin: "Date (min)",
    beginAtMax: "Date (max)",
    beginAtTimeframe: "Date",
  },
  generalData: {
    form: {
      number: {
        label: "Numéro",
        invalid: {
          assertEmpty: "Le numéro doit etre au format E164 (+{pays}{numéro})"
        }
      },
      user: {
        label: "Utilisateur"
      },
      temporaryUser: {
        label: "Utilisateur temporaire"
      },
      temporaryUserFunction: {
        label: "Fonction utilisateur temporaire"
      },
      partner: {
        label: "Partenaire"
      },
      beginAt: {
        label: "Départ"
      },
      inout: {
        label: "Sens de l'appel",
        options: {
          in: "Entrant",
          out: "Sortant"
        }
      },
      result: {
        label: "Résultat",
        options: {
          finished: "Terminé",
          failed: "Non abouti",
          callbackRequest: "Nous rappelle",
          callback: "demande de rappel",
          transfer: "Tranféré",
        }
      },
    }
  },
  result: {
    form: {
      comment: {
        label: "Note"
      },
    }
  },
  askForSignature: "Ce rapport va nécessiter une signature",
  signature: {
    appointment: "Intervention"
  }
};

export default callReport;
