const ticket = {
  title: {
    label: "Titre",
    invalid: {
      assertEmpty: "Le titre ne peut pas être vide."
    }
  },
  description: {
    label: "Description",
    invalid: {
      assertEmpty: "La description ne peut pas être vide."
    }
  },
  ticketType: {
    label: "Type de ticket",
    invalid: {
      assertEmpty: "Le type de ticket ne peut pas être vide."
    }
  },
  criticity: {
    label: "Urgence",
    options: {
      urgent: "Urgent",
      medium: "Moyen",
      noturgent: "Non urgent"
    },
    invalid: {
      assertEmpty: "Ce champ ne peut pas être vide."
    }
  },
  escalateLevel: {
    label: "Escalade",
    options: {
      _1: "Niveau 1",
      _2: "Niveau 2",
      _3: "Niveau 3"
    },
    invalid: {
      assertEmpty: "Ce champ ne peut pas être vide."
    }
  },
  originType: {
    label: "Type",
    options: {
      // nothing: "Aucun",
      productSubFamily: "Sous famille de produit",
      service: "Service",
      order: "Commande"
    },
    invalid: {
      assertEmpty: "Ce champ ne peut pas être vide."
    }
  },
  originService: {
    label: "Service"
  },
  originOrder: {
    label: "Commande"
  },
  originProductSubFamily: {
    label: "Sous famille de produit"
  },
  number: {
    label: "Numéro"
  },
  client: {
    label: "Partenaire"
  },
  clientAccount: {
    label: "Compte client"
  },
  place: {
    label: "Site de livraison"
  },
  refereeClients: {
    label: "Référent client",
    allowMoreField: {
      addLabel: 'Ajouter',
      removeLabel: 'Enlever',
      removingLabel: 'Supprimer',
    }
  },
  referee: {
    label: "Référent NK"
  },
  refereeClient: {
    label: "Référent client",
  },
  state: {
    label: "Etat",
    options: {
      new: 'Nouveau',
      waiting: 'En attente',
      in_progress: 'En cours de progrès',
      closed: 'Fermé',
    }
  },
  createdAtTimeframe: {
    label: "Création"
  },
  createdAt: {
    label: "Date de création"
  },
  appointmentNumber: {
    label: "Numéro d'intervention"
  },
  closeTicket: {
    label: "Fermer le ticket ?"
  }
};

export default ticket;
