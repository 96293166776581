import {
  CREATE_CALL_REPORT,
  MODIFY_CALL_REPORT,
  RESET_CALL_REPORT
} from '../Action/callReport'

const initialState = {}

export default function reduce(state = initialState, action){
  let newState = Object.assign({}, state);

  switch (action.type) {
    case CREATE_CALL_REPORT:
      let beginAt = new Date();
      // "Y-m-d\TH:i"
      console.log(beginAt.getFullYear()+"-"+String(beginAt.getMonth()+1).padStart(2, '0')+"-"+String(beginAt.getDate()).padStart(2, '0')+"\T"+String(beginAt.getHours()).padStart(2, '0')+":"+String(beginAt.getMinutes()).padStart(2, '0'));
      newState[action.id] = {
        generalDataUserType: "referee",
        generalData: {
          number: action.number,
          beginAt: beginAt.getFullYear()+"-"+String(beginAt.getMonth()+1).padStart(2, '0')+"-"+beginAt.getDate()+"\T"+beginAt.getHours()+":"+beginAt.getMinutes(),
          inout: (["in", "out"].includes(action.inout))?action.inout:"in"
        }
      }
      break;
    case MODIFY_CALL_REPORT:
      if(!newState[action.id]) {
        console.warn('trying to modify an unexisting call report');
        break;
      }
      let callReport = Object.assign({}, newState[action.id]);
      callReport[action.key] = action.value;
      newState[action.id] = callReport;
      break;
    case RESET_CALL_REPORT:
      let tempState = {}
      Object.keys(newState).filter(key => newState[action.id] !== newState[key]).forEach((key) => {
        tempState[key] = newState[key];
      });
      newState = tempState;
      break;
    default:
      newState = state;
  }
  return newState;
}
