import strings from '../../Localization/Localization';

import { fetchTicketTypes } from '../../API/TicketTypes';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.appointmentType.name.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.appointmentType.name.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let askForSignature = {
  fieldKey: "ask_for_signature",
  type: "switch",
  label: strings.form.appointmentType.askForSignature.label,
  validators: []
}

let onPremise = {
  fieldKey: "on_premise",
  type: "switch",
  label: strings.form.appointmentType.onPremise.label,
  validators: []
}

let description = {
  fieldKey: "description",
  type: "textarea",
  label: strings.form.appointmentType.description.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.appointmentType.description.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let ticketType = {
  fieldKey: "ticket_type",
  label: strings.form.appointmentType.ticketType.label,
  type: "dropdownEntity",

  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.appointmentType.ticketType.invalid.assertEmpty,
      defaultValue: false
    },
  ],

  showEmpty: true,
  loader: {
    id: "ticketType",
    loader: null,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

export const form = (type) => {

  let ticketTypesLoader = fetchTicketTypes(type);

  return {
    formKey: "appointmentType",
    fields: [
      name,
      onPremise,
      askForSignature,
      description,
      {
        ...ticketType,
        loader: {
          ...ticketType.loader,
          loader: ticketTypesLoader
        }
      }
    ]
  }
}
