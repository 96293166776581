import strings from '../../../Localization/Localization';

import { conf as quoteConf } from '../../Configuration/quotes';
import { stateMachineStatistic as quoteStateMachineStatistic } from '../../../API/Quotes';
import { state as quoteStateField } from '../../Form/Search/quotes';

import { conf as orderConf } from '../../Configuration/orders';
import { stateMachineStatistic as orderStateMachineStatistic } from '../../../API/Orders';
import { state as orderStateField } from '../../Form/Search/orders';

import { conf as serviceConf } from '../../Configuration/services';
import { stateMachineStatistic as serviceStateMachineStatistic } from '../../../API/Services';
import { state as serviceStateField } from '../../Form/Search/services';

import {
  technicalTicketConf,
  commercialTicketConf,
  administrativeTicketConf
} from '../../Configuration/tickets';
import { stateMachineStatistic as ticketStateMachineStatistic } from '../../../API/Tickets';
import { state as ticketStateField } from '../../Form/Search/tickets';

// import { conf as billConf } from '../../Configuration/bills';

import {
  quotes as quotesRoutes,
  orders as ordersRoutes,
  commercialTicket as commercialTicketRoutes,
  services as servicesRoutes,
  technicalTicket as technicalTicketRoutes,
  administrativeTicket as administrativeTicketRoutes,
  // bills as billRoutes
} from '../../Route/routes';

// import { ParameterTypes } from '../../../Dashboard/Constant';

// import { transformFieldToParamaterChoice } from './common';


import { ParameterTypes } from '../../../Dashboard/Constant';

import { transformFieldToParamaterChoice } from './common';

export const stateMachine = {
  id: "stateMachine",
  name: strings.dashboard.widget.stateMachine.name,
  icon: "icon-graph-pie",
  column: {
    min: 1,
    max: 2
  },
  row: {
    min: 1,
    max: 2
  },
  elementSettings: {
    quote: {
      name: strings.dashboard.entity.quote,
      fetch: quoteStateMachineStatistic,
      link: () => quotesRoutes.routes.index.createPath(),

      filters: {
        formKey: quoteConf.index.searchForm.formKey,
        fields: quoteConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "state":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: quoteConf.index.computeSearch,

      dataset: {
        state: {
          label: "Etat du devis",
          available: {
            waitingAgreement: {
              id: 'q_w_agreement',
              label: strings.form.cart.state.options.quote.waitingAgreement,
              filters: () => {
                return {
                  state: [quoteStateField.options.find(option => option.value === 'q_w_agreement')]
                }
              }
            },
            waitingValidation: {
              id: 'q_w_validation',
              label: strings.form.cart.state.options.quote.waitingValidation,
              filters: () => {
                return {
                  state: [quoteStateField.options.find(option => option.value === 'q_w_validation')]
                }
              }
            },
            validated: {
              id: 'q_validated',
              label: strings.form.cart.state.options.quote.validated,
              filters: () => {
                return {
                  state: [quoteStateField.options.find(option => option.value === 'q_validated')]
                }
              }
            },
            canceled: {
              id: 'q_canceled',
              label: strings.form.cart.state.options.quote.canceled,
              filters: () => {
                return {
                  state: [quoteStateField.options.find(option => option.value === 'q_canceled')]
                }
              }
            },
            yousign: {
              id: 'q_w_yousign',
              label: strings.form.cart.state.options.quote.yousign,
              filters: () => {
                return {
                  state: [quoteStateField.options.find(option => option.value === 'q_w_yousign')]
                }
              }
            }
          }
        }
      },

      role: "ROLE_QUOTE"
    },
    order: {
      name: strings.dashboard.entity.order,
      fetch: orderStateMachineStatistic,
      link: () => ordersRoutes.routes.index.createPath(),

      filters: {
        formKey: orderConf.index.searchForm.formKey,
        fields: orderConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "state":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: orderConf.index.computeSearch,

      dataset: {
        state: {
          label: "Etat de la commande",
          available: {
            waitingValidation: {
              id: 'o_w_validation',
              label: strings.form.cart.state.options.order.waitingValidation,
              filters: () => {
                return {
                  state: [orderStateField.options.find(option => option.value === 'o_w_validation')]
                }
              }
            },
            cancellationPeriod: {
              id: 'o_c_period',
              label: strings.form.cart.state.options.order.cancellationPeriod,
              filters: () => {
                return {
                  state: [orderStateField.options.find(option => option.value === 'o_c_period')]
                }
              }
            },
            waitingAdvancePayment: {
              id: 'o_w_a_payment',
              label: strings.form.cart.state.options.order.waitingAdvancePayment,
              filters: () => {
                return {
                  state: [orderStateField.options.find(option => option.value === 'o_w_a_payment')]
                }
              }
            },
            deliveryInProgress: {
              id: 'o_d_i_progress',
              label: strings.form.cart.state.options.order.deliveryInProgress,
              filters: () => {
                return {
                  state: [orderStateField.options.find(option => option.value === 'o_d_i_progress')]
                }
              }
            },
            delivered: {
              id: 'o_delivered',
              label: strings.form.cart.state.options.order.delivered,
              filters: () => {
                return {
                  state: [orderStateField.options.find(option => option.value === 'o_delivered')]
                }
              }
            },
            canceled: {
              id: 'o_canceled',
              label: strings.form.cart.state.options.order.canceled,
              filters: () => {
                return {
                  state: [orderStateField.options.find(option => option.value === 'o_canceled')]
                }
              }
            },
          }
        }
      },

      role: "ROLE_ORDER"
    },
    commercialTicket: {
      name: strings.dashboard.entity.commercialTicket,
      fetch: ticketStateMachineStatistic("commercial"),
      link: () => commercialTicketRoutes.routes.index.createPath(),

      filters: {
        formKey: commercialTicketConf.index.searchForm.formKey,
        fields: commercialTicketConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "criticity":
            case "state":
            case "escalate":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            case "referee":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.USER, true);
            default:
          }
          return field
        })
      },
      computeFilters: commercialTicketConf.index.computeSearch,

      dataset: {
        state: {
          label: "Etat du ticket",
          available: {
            new: {
              id: 'new',
              label: strings.form.ticket.state.options.new,
              filters: () => {
                return {
                  state: [ticketStateField.options.find(option => option.value === 'new')]
                }
              }
            },
            waiting: {
              id: 'waiting',
              label: strings.form.ticket.state.options.waiting,
              filters: () => {
                return {
                  state: [ticketStateField.options.find(option => option.value === 'waiting')]
                }
              }
            },
            inProgress: {
              id: 'in_progress',
              label: strings.form.ticket.state.options.in_progress,
              filters: () => {
                return {
                  state: [ticketStateField.options.find(option => option.value === 'in_progress')]
                }
              }
            },
            closed: {
              id: 'closed',
              label: strings.form.ticket.state.options.closed,
              filters: () => {
                return {
                  state: [ticketStateField.options.find(option => option.value === 'closed')]
                }
              }
            }
          }
        },
        criticity: {
          label: "Urgence",
          available: {
            1: {
              id: 1,
              label: strings.form.ticket.criticity.options.urgent,
              filters: () => {
                return {
                  criticity: 1
                }
              }
            },
            2: {
              id: 2,
              label: strings.form.ticket.criticity.options.medium,
              filters: () => {
                return {
                  criticity: 2
                }
              }
            },
            3: {
              id: 3,
              label: strings.form.ticket.criticity.options.noturgent,
              filters: () => {
                return {
                  criticity: 3
                }
              }
            }
          }
        }
      },

      role: "ROLE_USER"
    },
    service: {
      name: strings.dashboard.entity.service,
      fetch: serviceStateMachineStatistic,
      link: () => servicesRoutes.routes.index.createPath(),

      filters: {
        formKey: serviceConf.index.searchForm.formKey,
        fields: serviceConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "state":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: serviceConf.index.computeSearch,

      dataset: {
        state: {
          label: "Etat du service",
          available: {
            active: {
              id: 'active',
              label: strings.form.service.state.options.active,
              filters: () => {
                return {
                  state: [serviceStateField.options.find(option => option.value === 'active')]
                }
              }
            },
            inactive: {
              id: 'inactive',
              label: strings.form.service.state.options.inactive,
              filters: () => {
                return {
                  state: [serviceStateField.options.find(option => option.value === 'inactive')]
                }
              }
            },
            termination: {
              id: 'termination',
              label: strings.form.service.state.options.termination,
              filters: () => {
                return {
                  state: [serviceStateField.options.find(option => option.value === 'termination')]
                }
              }
            },
            terminated: {
              id: 'terminated',
              label: strings.form.service.state.options.terminated,
              filters: () => {
                return {
                  state: [serviceStateField.options.find(option => option.value === 'terminated')]
                }
              }
            },
          }
        }
      },

      // role: "ROLE_ORDER"
    },
    technicalTicket: {
      name: strings.dashboard.entity.technicalTicket,
      fetch: ticketStateMachineStatistic("technical"),
      link: () => technicalTicketRoutes.routes.index.createPath(),

      filters: {
        formKey: technicalTicketConf.index.searchForm.formKey,
        fields: technicalTicketConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "criticity":
            case "state":
            case "escalate":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            case "referee":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.USER, true);
            default:
          }
          return field
        })
      },
      computeFilters: technicalTicketConf.index.computeSearch,

      dataset: {
        state: {
          label: "Etat du ticket",
          available: {
            new: {
              id: 'new',
              label: strings.form.ticket.state.options.new,
              filters: () => {
                return {
                  state: [ticketStateField.options.find(option => option.value === 'new')]
                }
              }
            },
            waiting: {
              id: 'waiting',
              label: strings.form.ticket.state.options.waiting,
              filters: () => {
                return {
                  state: [ticketStateField.options.find(option => option.value === 'waiting')]
                }
              }
            },
            inProgress: {
              id: 'in_progress',
              label: strings.form.ticket.state.options.in_progress,
              filters: () => {
                return {
                  state: [ticketStateField.options.find(option => option.value === 'in_progress')]
                }
              }
            },
            closed: {
              id: 'closed',
              label: strings.form.ticket.state.options.closed,
              filters: () => {
                return {
                  state: [ticketStateField.options.find(option => option.value === 'closed')]
                }
              }
            }
          }
        },
        criticity: {
          label: "Urgence",
          available: {
            1: {
              id: 1,
              label: strings.form.ticket.criticity.options.urgent,
              filters: () => {
                return {
                  criticity: 1
                }
              }
            },
            2: {
              id: 2,
              label: strings.form.ticket.criticity.options.medium,
              filters: () => {
                return {
                  criticity: 2
                }
              }
            },
            3: {
              id: 3,
              label: strings.form.ticket.criticity.options.noturgent,
              filters: () => {
                return {
                  criticity: 3
                }
              }
            }
          }
        }
      },

      role: "ROLE_USER"
    },
    administrativeTicket: {
      name: strings.dashboard.entity.administrativeTicket,
      fetch: ticketStateMachineStatistic("administrative"),
      link: () => administrativeTicketRoutes.routes.index.createPath(),

      filters: {
        formKey: administrativeTicketConf.index.searchForm.formKey,
        fields: administrativeTicketConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "criticity":
            case "state":
            case "escalate":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            case "referee":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.USER, true);
            default:
          }
          return field
        })
      },
      computeFilters: administrativeTicketConf.index.computeSearch,

      dataset: {
        state: {
          label: "Etat du ticket",
          available: {
            new: {
              id: 'new',
              label: strings.form.ticket.state.options.new,
              filters: () => {
                return {
                  state: [ticketStateField.options.find(option => option.value === 'new')]
                }
              }
            },
            waiting: {
              id: 'waiting',
              label: strings.form.ticket.state.options.waiting,
              filters: () => {
                return {
                  state: [ticketStateField.options.find(option => option.value === 'waiting')]
                }
              }
            },
            inProgress: {
              id: 'in_progress',
              label: strings.form.ticket.state.options.in_progress,
              filters: () => {
                return {
                  state: [ticketStateField.options.find(option => option.value === 'in_progress')]
                }
              }
            },
            closed: {
              id: 'closed',
              label: strings.form.ticket.state.options.closed,
              filters: () => {
                return {
                  state: [ticketStateField.options.find(option => option.value === 'closed')]
                }
              }
            }
          }
        },
        criticity: {
          label: "Urgence",
          available: {
            1: {
              id: 1,
              label: strings.form.ticket.criticity.options.urgent,
              filters: () => {
                return {
                  criticity: 1
                }
              }
            },
            2: {
              id: 2,
              label: strings.form.ticket.criticity.options.medium,
              filters: () => {
                return {
                  criticity: 2
                }
              }
            },
            3: {
              id: 3,
              label: strings.form.ticket.criticity.options.noturgent,
              filters: () => {
                return {
                  criticity: 3
                }
              }
            }
          }
        }
      },

      role: "ROLE_USER"
    },
  },
  visualSettings: [],
  dataSettings: [
    {
      fieldKey: "dataset",
      type: "dropdown",
      label: "Données affichées",
      showOnDisabled: () => false,
      validators: [],

      input: 'formValues',
      finalValues: 'values',
      compute: (getValues, extraValues) => {
        let element = getValues().element;
        if(element && stateMachine.elementSettings[element]) {
          return Object.keys(stateMachine.elementSettings[element].dataset).map(set => {
            return {
              id: set,
              label: stateMachine.elementSettings[element].dataset[set].label
            }
          })
        }
        return []
      },
      adapter: {
        requirement: (data) => data && data["id"] && data["label"],
        getValue: (data) => data["id"],
        getLabel: (data) => data["label"],
        isFinalValue: true
      },
    },
    {
      fieldKey: "data",
      type: "dropdownMulti",
      label: "Filtrer les données",
      showOnDisabled: () => false,
      validators: [],

      input: 'formValues',
      finalValues: 'values',
      compute: (getValues, extraValues) => {
        let element = getValues().element;
        let dataset = getValues().dataset;
        if(typeof dataset === 'object') {
          dataset = dataset.id
        }
        if(element && dataset && stateMachine.elementSettings[element] && stateMachine.elementSettings[element].dataset[dataset]) {
          return Object.keys(stateMachine.elementSettings[element].dataset[dataset].available).map(data => {
            return {
              id: stateMachine.elementSettings[element].dataset[dataset].available[data].id,
              label: stateMachine.elementSettings[element].dataset[dataset].available[data].label
            }
          })
        }
        return []
      },
      adapter: {
        requirement: (data) => data && data["id"] && data["label"],
        getValue: (data) => data["id"],
        getLabel: (data) => data["label"],
        isFinalValue: true
      },
    },
    {
      fieldKey: "filters",
      type: "configurableField",
      label: "",
      validators: [],

      subField: (value, getValues, getExtraValues) => {
        let element = getValues().element;
        if(element && stateMachine.elementSettings[element]) {
          return {
            subForm: stateMachine.elementSettings[element].filters
          }
        }
        return {
          subForm: {
            fields: {}
          }
        }
      },
      subFieldConf: {
        label: () => "",
        type: () => "subform",
        disabled: () => false,
        validators: () => [],
      }
    }
  ]
}
