import { withModalHandler } from '../../HOC/ModalHandler';

import FormFieldText from './Field/FormFieldText';
import FormFieldPrice from './Field/FormFieldPrice';
import FormFieldSwitch from './Field/FormFieldSwitch';
import FormFieldTriSwitch from './Field/FormFieldTriSwitch';
import FormFieldPassword from './Field/FormFieldPassword';
import FormFieldTextUpperCase from './Field/FormFieldTextUpperCase';
import FormFieldTextarea from './Field/FormFieldTextarea';
import FormFieldPhoneNumber from './Field/FormFieldPhoneNumber';
import FormFieldMail from './Field/FormFieldMail';
import FormFieldDate from './Field/FormFieldDate';
import FormFieldTime from './Field/FormFieldTime';
import FormFieldDateTime from './Field/FormFieldDateTime';
import FormFieldColor from './Field/FormFieldColor';
import FormFieldDropdown from './Field/FormFieldDropdown';
import FormFieldDropdownEntity from './Field/FormFieldDropdownEntity';
import FormFieldDropdownMulti from './Field/FormFieldDropdownMulti';
import FormFieldDropdownMultiEntity from './Field/FormFieldDropdownMultiEntity';
import FormFieldDropdownMultiEntityAlt from './Field/FormFieldDropdownMultiEntityAlt';
import FormFieldFile from './Field/FormFieldFile';
import FormFieldConfigurable from './Field/FormFieldConfigurable';
import FormFieldArray from './Field/FormFieldArray';
import FormFieldSubForm from './Field/FormFieldSubForm';
import FormFieldTable from './Field/FormFieldTable';
import FormFieldHidden from './Field/FormFieldHidden';

import DashboardFormFieldArray from './Field/Variant/Dashboard/DashboardFormFieldArray';
import DashboardFormFieldParameterChoice from './Field/Variant/Dashboard/DashboardFormFieldParameterChoice';
import DashboardFormFieldDropdown from './Field/Variant/Dashboard/DashboardFormFieldDropdown';
import DashboardFormFieldColor from './Field/Variant/Dashboard/DashboardFormFieldColor';
import DashboardFormFieldText from './Field/Variant/Dashboard/DashboardFormFieldText';
import DashboardFormFieldPrice from './Field/Variant/Dashboard/DashboardFormFieldPrice';
import DashboardFormFieldSwitch from './Field/Variant/Dashboard/DashboardFormFieldSwitch';
import DashboardFormFieldTriSwitch from './Field/Variant/Dashboard/DashboardFormFieldTriSwitch';
import DashboardFormFieldDate from './Field/Variant/Dashboard/DashboardFormFieldDate';
import DashboardFormFieldTime from './Field/Variant/Dashboard/DashboardFormFieldTime';
import DashboardFormFieldDateTime from './Field/Variant/Dashboard/DashboardFormFieldDateTime';
import DashboardFormFieldDropdownEntity from './Field/Variant/Dashboard/DashboardFormFieldDropdownEntity';
import DashboardFormFieldDropdownMulti from './Field/Variant/Dashboard/DashboardFormFieldDropdownMulti';
import DashboardFormFieldDropdownMultiEntity from './Field/Variant/Dashboard/DashboardFormFieldDropdownMultiEntity';

import CallReportFormFieldText from './Field/Variant/CallReport/CallReportFormFieldText';
import CallReportFormFieldTextarea from './Field/Variant/CallReport/CallReportFormFieldTextarea';
import CallReportFormFieldDropdown from './Field/Variant/CallReport/CallReportFormFieldDropdown';
import CallReportFormFieldDropdownEntity from './Field/Variant/CallReport/CallReportFormFieldDropdownEntity';
import CallReportFormFieldDateTime from './Field/Variant/CallReport/CallReportFormFieldDateTime';

import FormButton from './Button/FormButton';

const Text = withModalHandler(FormFieldText);
const Price = withModalHandler(FormFieldPrice);
const Switch = withModalHandler(FormFieldSwitch);
const TriSwitch = withModalHandler(FormFieldTriSwitch);
const Password = withModalHandler(FormFieldPassword);
const TextUppercase = withModalHandler(FormFieldTextUpperCase);
const Textarea = withModalHandler(FormFieldTextarea);
const PhoneNumber = withModalHandler(FormFieldPhoneNumber);
const Mail =  withModalHandler(FormFieldMail);
const Date =  withModalHandler(FormFieldDate);
const Time =  withModalHandler(FormFieldTime);
const DateTime =  withModalHandler(FormFieldDateTime);
const Color =  withModalHandler(FormFieldColor);
const Dropdown = withModalHandler(FormFieldDropdown);
const DropdownEntity = withModalHandler(FormFieldDropdownEntity);
const DropdownMulti = withModalHandler(FormFieldDropdownMulti);
const DropdownMultiEntity = withModalHandler(FormFieldDropdownMultiEntity);
const DropdownMultiEntityAlt = withModalHandler(FormFieldDropdownMultiEntityAlt);
const File = withModalHandler(FormFieldFile);
const Configurable = withModalHandler(FormFieldConfigurable);
const Array = withModalHandler(FormFieldArray);
const SubForm = withModalHandler(FormFieldSubForm);
const Table = withModalHandler(FormFieldTable);
const Hidden = withModalHandler(FormFieldHidden);

const Button = withModalHandler(FormButton);

const DashboardArray = withModalHandler(DashboardFormFieldArray);
const DashboardParameterChoice = withModalHandler(DashboardFormFieldParameterChoice);
const DashboardDropdown = withModalHandler(DashboardFormFieldDropdown);
const DashboardColor = withModalHandler(DashboardFormFieldColor);
const DashboardText = withModalHandler(DashboardFormFieldText);
const DashboardPrice = withModalHandler(DashboardFormFieldPrice);
const DashboardSwitch = withModalHandler(DashboardFormFieldSwitch);
const DashboardTriSwitch = withModalHandler(DashboardFormFieldTriSwitch);
const DashboardDate = withModalHandler(DashboardFormFieldDate);
const DashboardTime = withModalHandler(DashboardFormFieldTime);
const DashboardDateTime = withModalHandler(DashboardFormFieldDateTime);
const DashboardDropdownEntity = withModalHandler(DashboardFormFieldDropdownEntity);
const DashboardDropdownMulti = withModalHandler(DashboardFormFieldDropdownMulti);
const DashboardDropdownMultiEntity = withModalHandler(DashboardFormFieldDropdownMultiEntity);

const CallReportText = withModalHandler(CallReportFormFieldText);
const CallReportTextarea = withModalHandler(CallReportFormFieldTextarea);
const CallReportDropdown = withModalHandler(CallReportFormFieldDropdown);
const CallReportDropdownEntity = withModalHandler(CallReportFormFieldDropdownEntity);
const CallReportDateTime = withModalHandler(CallReportFormFieldDateTime);

export {
  Text,
  Price,
  Switch,
  TriSwitch,
  Password,
  TextUppercase,
  Textarea,
  PhoneNumber,
  Mail,
  Date,
  Time,
  DateTime,
  Color,
  Dropdown,
  DropdownEntity,
  DropdownMulti,
  DropdownMultiEntity,
  DropdownMultiEntityAlt,
  File,
  Configurable,
  Array,
  SubForm,
  Table,
  Hidden,

  Button,

  DashboardArray,
  DashboardParameterChoice,
  DashboardDropdown,
  DashboardColor,
  DashboardText,
  DashboardPrice,
  DashboardSwitch,
  DashboardTriSwitch,
  DashboardDate,
  DashboardTime,
  DashboardDateTime,
  DashboardDropdownEntity,
  DashboardDropdownMulti,
  DashboardDropdownMultiEntity,

  CallReportText,
  CallReportTextarea,
  CallReportDropdown,
  CallReportDropdownEntity,
  CallReportDateTime,
};
