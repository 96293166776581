import React from 'react';

import { Col } from 'react-bootstrap';

import AbstractReference from './AbstractReference';

class ServiceReference extends AbstractReference {

  displayService() {
    if(this.props.service) {
      return (
        <div>
          {this.displayTitle(`Service ${this.props.service.serial_number}`)}
        </div>
      )
    }
  }

  render() {
    return (
      <div className="call-report-history-container">
        <Col>
          {this.displayService()}
        </Col>
      </div>
    );
  }
}


export default ServiceReference;
