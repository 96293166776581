import React from 'react';
import PropTypes from 'prop-types';

import {
  Text,
  Price,
  Switch,
  TriSwitch,
  Password,
  TextUppercase,
  Textarea,
  PhoneNumber,
  Mail,
  Date,
  Time,
  DateTime,
  Color,
  Dropdown,
  DropdownEntity,
  DropdownMulti,
  DropdownMultiEntity,
  DropdownMultiEntityAlt,
  File,
  Configurable,
  Array,
  SubForm,
  Table,
  Hidden,

  Button,

  DashboardArray,
  DashboardParameterChoice,
  DashboardDropdown,
  DashboardColor,
  DashboardText,
  DashboardPrice,
  DashboardSwitch,
  DashboardTriSwitch,
  DashboardDate,
  DashboardTime,
  DashboardDateTime,
  DashboardDropdownEntity,
  DashboardDropdownMulti,
  DashboardDropdownMultiEntity,

  CallReportText,
  CallReportTextarea,
  CallReportDropdown,
  CallReportDropdownEntity,
  CallReportDateTime,
} from './FormFieldReference';

/**
 * FormFieldSelector
 *
 * This class is a selector for HTML form field. It select the right form field
 * using the type in the props of the component. It also check that every main
 * attribute are present for the field.
 *
 * main configuration attribute :
 * - type : the type of the field
 * - formKey : the key of the form (should be unique for each form)
 * - fieldKey : the key of the field (should be unique for each field inside one
 *            form)
 * - label : the string used to present the field to the end user,
 * - value : the value of the field,
 * - disabled : whether the field is disabled or not,
 * - onChange : the callback used to change the value in the parent component,
 * - modifyField : the callback used to change the value of other field in the parent component,
 * - isValid : the list of validation callback to ensure the value of the field
 *           is correct
 */
class FormFieldSelector extends React.Component {

  static defaultProps = {
    ignoreValidation: false,
    defaultValue: null,

    //only for dashboard style
    skipSeparator: false,
  }

  /**
   * Main render method for React Component
   */
  render() {
    //select the variant
    switch (this.props.fieldVariant) {
      case "dashboard":
        switch (this.props.type) {
          case "parameterChoice":
            return (
              <DashboardParameterChoice
                ref={this.props.forwardedRef}

                fieldVariant={this.props.fieldVariant}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                defaultValue={this.props.defaultValue}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                subType={this.props.subType}
                subProps={this.props.subProps}
                choices={this.props.choices}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "text":
            return (
              <DashboardText
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                defaultValue={this.props.defaultValue}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "color":
            return (
              <DashboardColor
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                defaultValue={this.props.defaultValue}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "dropdown":
            return (
              <DashboardDropdown
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                showEmpty={this.props.showEmpty}
                adapter={this.props.adapter}
                filterOptions={this.props.filterOptions}

                input={this.props.input}
                options={this.props.options}
                compute={this.props.compute}

                displayAsRows={this.props.displayAsRows}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "array":
            return (
              <DashboardArray
                ref={this.props.forwardedRef}

                fieldVariant={this.props.fieldVariant}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}
                type={this.props.type}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                filterValues={this.props.filterValues}
                subField={this.props.subField}
                defaultNumberOfField={this.props.defaultNumberOfField}

                allowMoreField={this.props.allowMoreField}
                allowMoreFieldAddLabel={this.props.allowMoreFieldAddLabel}
                allowMoreFieldRemoveLabel={this.props.allowMoreFieldRemoveLabel}
                allowMoreFieldRemovingLabel={this.props.allowMoreFieldRemovingLabel}

                middlePart={this.props.middlePart} //TODO add the display

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "price":
            return (
              <DashboardPrice
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                defaultValue={this.props.defaultValue}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "switch":
            return (
              <DashboardSwitch
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                defaultValue={this.props.defaultValue}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "triswitch":
            return (
              <DashboardTriSwitch
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                defaultValue={this.props.defaultValue}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "date":
            return (
              <DashboardDate
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                defaultValue={this.props.defaultValue}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                min={this.props.min}
                max={this.props.max}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "time":
            return (
              <DashboardTime
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                defaultValue={this.props.defaultValue}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "datetime":
            return (
              <DashboardDateTime
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                defaultValue={this.props.defaultValue}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "dropdownEntity":
            return (
              <DashboardDropdownEntity
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}
                disabledLink={this.props.disabledLink}

                value={this.props.value}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                assertObjectDefaultValue={this.props.assertObjectDefaultValue}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                showEmpty={this.props.showEmpty}
                adapter={this.props.adapter}
                filterOptions={this.props.filterOptions}
                autoComplete={this.props.autoComplete}

                storage={this.props.storage}
                loader={this.props.loader}
                shareStorage={this.props.shareStorage}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "dropdownMulti":
            return (
              <DashboardDropdownMulti
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                adapter={this.props.adapter}
                filterOptions={this.props.filterOptions}
                finalValues={this.props.finalValues}

                alwaysOpen={this.props.alwaysOpen}
                input={this.props.input}
                options={this.props.options}
                compute={this.props.compute}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "dropdownMultiEntity":
            return (
              <DashboardDropdownMultiEntity
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                placeholder={this.props.placeholder?this.props.placeholder:this.props.label}
                disabled={this.props.disabled}

                value={this.props.value}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                adapter={this.props.adapter}
                filterOptions={this.props.filterOptions}
                finalValues={this.props.finalValues}

                storage={this.props.storage}
                loader={this.props.loader}
                shareStorage={this.props.shareStorage}

                middlePart={this.props.middlePart}

                skipSeparator={this.props.skipSeparator}
              />
            );
          case "hidden":
          case "configurableField":
          case "subform":
            break;
          default:
            console.warn("field not found in dashboard variant, display default variant instead, type: "+this.props.type);
        }
        break;
      case "callReport":
        switch (this.props.type) {
          case "text":
            return (
              <CallReportText
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                icons={this.props.icons}
                disabled={this.props.disabled}
                disabledLink={this.props.disabledLink}

                value={this.props.value}
                defaultValue={this.props.defaultValue}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                messageRight={this.props.messageRight}

                middlePart={this.props.middlePart}
              />
            );
          case "textarea":
            return (
              <CallReportTextarea
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                icons={this.props.icons}
                disabled={this.props.disabled}
                disabledLink={this.props.disabledLink}

                value={this.props.value}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                middlePart={this.props.middlePart}
              />
            );
          case "array":
          case "price":
          case "switch":
          case "triswitch":
          case "date":
          case "time":
          case "datetime":
            return (
              <CallReportDateTime
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                label={this.props.label}
                icons={this.props.icons}
                disabled={this.props.disabled}

                value={this.props.value}
                defaultValue={this.props.defaultValue}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                middlePart={this.props.middlePart}
              />
            );
          case "dropdown":
            return (
              <CallReportDropdown
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}
                label={this.props.label}
                icons={this.props.icons}
                disabled={this.props.disabled}

                value={this.props.value}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                showEmpty={this.props.showEmpty}
                adapter={this.props.adapter}
                filterOptions={this.props.filterOptions}
                autoComplete={this.props.autoComplete}

                input={this.props.input}
                options={this.props.options}
                compute={this.props.compute}

                middlePart={this.props.middlePart}
              />
            );
          case "dropdownEntity":
            return (
              <CallReportDropdownEntity
                ref={this.props.forwardedRef}

                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}
                label={this.props.label}
                icons={this.props.icons}
                disabled={this.props.disabled}
                disabledLink={this.props.disabledLink}

                value={this.props.value}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={this.props.onChange}
                modifyField={this.props.modifyField}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={this.props.validationCallback}
                assertObjectDefaultValue={this.props.assertObjectDefaultValue}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                showEmpty={this.props.showEmpty}
                adapter={this.props.adapter}
                filterOptions={this.props.filterOptions}
                autoComplete={this.props.autoComplete}

                storage={this.props.storage}
                loader={this.props.loader}
                shareStorage={this.props.shareStorage}

                middlePart={this.props.middlePart}
              />
            );
          case "dropdownMulti":
          case "dropdownMultiEntity":
          case "hidden":
          case "configurableField":
          case "subform":
          default:
            console.warn("field not found in call report variant, display default variant instead, type: "+this.props.type);
        }
        break;
      case "primary":
      default:
    }
    //select the field using type of the field
    switch (this.props.type) {
      case "text":
        return (
          <Text
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}

            label={this.props.label}
            disabled={this.props.disabled}
            disabledLink={this.props.disabledLink}

            value={this.props.value}
            defaultValue={this.props.defaultValue}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            messageRight={this.props.messageRight}

            middlePart={this.props.middlePart}
          />
        );
      case "price":
        return (
          <Price
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            defaultValue={this.props.defaultValue}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            middlePart={this.props.middlePart}
          />
        );
      case "switch":
        return (
          <Switch
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            defaultValue={this.props.defaultValue}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            middlePart={this.props.middlePart}
          />
        );
      case "triswitch":
        return (
          <TriSwitch
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            defaultValue={this.props.defaultValue}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            middlePart={this.props.middlePart}
          />
        );
      case "password":
        return (
          <Password
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            type={this.props.type}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            verification={this.props.verification}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            middlePart={this.props.middlePart}
          />
        );
      case "textUppercase":
        return (
          <TextUppercase
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            type={this.props.type}

            label={this.props.label}
            disabled={this.props.disabled}
            disabledLink={this.props.disabledLink}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            middlePart={this.props.middlePart}
          />
        );
      case "textarea":
        return (
          <Textarea
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            label={this.props.label}
            disabled={this.props.disabled}
            disabledLink={this.props.disabledLink}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            middlePart={this.props.middlePart}
          />
        );
      case "phoneNumber":
        return (
          <PhoneNumber
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            type={this.props.type}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            middlePart={this.props.middlePart}
          />
        );
      case "mail":
        return (
          <Mail
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            type={this.props.type}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            middlePart={this.props.middlePart}
          />
        );
      case "date":
        return (
          <Date
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            defaultValue={this.props.defaultValue}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            min={this.props.min}
            max={this.props.max}

            middlePart={this.props.middlePart}
          />
        );
      case "time":
        return (
          <Time
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            defaultValue={this.props.defaultValue}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            middlePart={this.props.middlePart}
          />
        );
      case "datetime":
        return (
          <DateTime
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            defaultValue={this.props.defaultValue}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            middlePart={this.props.middlePart}
          />
        );
      case "color":
        return (
          <Color
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            defaultValue={this.props.defaultValue}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            middlePart={this.props.middlePart}
          />
        );
      case "dropdown":
        return (
          <Dropdown
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            showEmpty={this.props.showEmpty}
            adapter={this.props.adapter}
            filterOptions={this.props.filterOptions}
            autoComplete={this.props.autoComplete}

            input={this.props.input}
            options={this.props.options}
            compute={this.props.compute}

            middlePart={this.props.middlePart}
          />
        );
      case "dropdownEntity":
        return (
          <DropdownEntity
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            label={this.props.label}
            disabled={this.props.disabled}
            disabledLink={this.props.disabledLink}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            assertObjectDefaultValue={this.props.assertObjectDefaultValue}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            showEmpty={this.props.showEmpty}
            adapter={this.props.adapter}
            filterOptions={this.props.filterOptions}
            autoComplete={this.props.autoComplete}

            storage={this.props.storage}
            loader={this.props.loader}
            shareStorage={this.props.shareStorage}

            middlePart={this.props.middlePart}
          />
        );
      case "dropdownMulti":
        return (
          <DropdownMulti
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            adapter={this.props.adapter}
            filterOptions={this.props.filterOptions}
            finalValues={this.props.finalValues}

            alwaysOpen={this.props.alwaysOpen}
            input={this.props.input}
            options={this.props.options}
            compute={this.props.compute}

            middlePart={this.props.middlePart}
          />
        );
      case "dropdownMultiEntity":
        return (
          <DropdownMultiEntity
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            adapter={this.props.adapter}
            filterOptions={this.props.filterOptions}
            finalValues={this.props.finalValues}

            storage={this.props.storage}
            loader={this.props.loader}
            shareStorage={this.props.shareStorage}

            middlePart={this.props.middlePart}
          />
        );
      case "dropdownMultiEntityAlt":
        return (
          <DropdownMultiEntityAlt
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            adapter={this.props.adapter}
            filterOptions={this.props.filterOptions}
            finalValues={this.props.finalValues}

            storage={this.props.storage}
            loader={this.props.loader}
            shareStorage={this.props.shareStorage}

            middlePart={this.props.middlePart} //TODO add the display
          />
        );
      case "file":
        return (
          <File
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            middlePart={this.props.middlePart}
          />
        );
      case "configurableField":
        return (
          <Configurable
            ref={this.props.forwardedRef}

            fieldVariant={this.props.fieldVariant}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            type={this.props.type}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            subField={this.props.subField}
            subFieldConf={this.props.subFieldConf}

            middlePart={this.props.middlePart}
          />
        );
      case "array":
        return (
          <Array
            ref={this.props.forwardedRef}

            fieldVariant={this.props.fieldVariant}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            type={this.props.type}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            filterValues={this.props.filterValues}
            subField={this.props.subField}
            defaultNumberOfField={this.props.defaultNumberOfField}

            allowMoreField={this.props.allowMoreField}
            allowMoreFieldAddLabel={this.props.allowMoreFieldAddLabel}
            allowMoreFieldRemoveLabel={this.props.allowMoreFieldRemoveLabel}
            allowMoreFieldRemovingLabel={this.props.allowMoreFieldRemovingLabel}

            middlePart={this.props.middlePart} //TODO add the display
          />
        );
      case "subform":
        return (
          <SubForm
            ref={this.props.forwardedRef}

            fieldVariant={this.props.fieldVariant}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            type={this.props.type}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            subForm={this.props.subForm}
            ignoreSubFormValidation={this.props.ignoreSubFormValidation}

            middlePart={this.props.middlePart} //TODO add the display
          />
        );
      case "table":
        return (
          <Table
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}

            label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            onChange={this.props.onChange}
            modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            table={this.props.table}

            middlePart={this.props.middlePart} //TODO add the display
          />
        );
      case "hidden":
        return (
          <Hidden
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}

            // label={this.props.label}
            disabled={this.props.disabled}

            value={this.props.value}
            defaultValue={this.props.defaultValue}
            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            // onChange={this.props.onChange}
            // modifyField={this.props.modifyField}

            ignoreValidation={this.props.ignoreValidation}
            validators={this.props.validators}
            validationCallback={this.props.validationCallback}
            submitted={this.props.submitted}
            submitting={this.props.submitting}

            // middlePart={this.props.middlePart}
          />
        );
      case "button":
        return (
          <Button
            ref={this.props.forwardedRef}

            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}

            label={this.props.label}
            disabled={this.props.disabled}

            getValues={this.props.getValues}
            getExtraValues={this.props.getExtraValues}
            modifyField={this.props.modifyField}

            submitted={this.props.submitted}
            submitting={this.props.submitting}

            as={this.props.as}
            path={this.props.path}
            onClick={this.props.onClick}

            middlePart={this.props.middlePart} //TODO add the display
          />
        )
      default:
    }
    return <div/>;
  }
}

//use propTypes to check if the attribute are all present
FormFieldSelector.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'price',
    'switch',
    'triswitch',
    'password',
    'textUppercase',
    'textarea',
    'phoneNumber',
    'mail',
    'date',
    'time',
    'datetime',
    'color',
    'dropdown',
    'dropdownEntity',
    'dropdownMulti',
    'dropdownMultiEntity',
    'dropdownMultiEntityAlt',
    'file',
    'configurableField',
    'array',
    'subform',
    'table',
    'hidden',
    'button',
    //dashboard only
    'parameterChoice'
  ]),
  formKey: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.any,
  disabled: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  validators: PropTypes.array
}

export default FormFieldSelector;
