import React from 'react';

import { Col } from 'react-bootstrap';

import AbstractReference from './AbstractReference';

class BillReference extends AbstractReference {

  displayBill() {
    if(this.props.bill) {
      return (
        <div>
          {this.displayTitle(`Facture ${this.props.bill.number}`)}
        </div>
      )
    }
  }

  render() {
    return (
      <div className="call-report-history-container">
        <Col>
          {this.displayBill()}
        </Col>
      </div>
    );
  }
}


export default BillReference;
