import React from 'react';
import BasicFormField from '../../BasicFormField';

import { Col, Row, Button } from 'react-bootstrap';
import '../../../../../css/dashboard_form.css';

import { v4 as uuid } from 'uuid';

import NotEmptyOrNullAssert from '../../../../../Form/Assert/NotEmptyOrNullAssert';

/**
 * FormFieldDropdown
 *
 * This class handles HTML form field.
 *
 * The particular field is a Dropdown, it allow to choose from a list.
 *
 * Specific configuration attribute :
 * - showEmpty : the blank value
 * - adapter : an adapter in case the options are objects
 * - filterOptions : inherit from FormFieldDropdown
 *
 * - input : the type of input either 'options' or 'formValues'
 *         if 'options', options need to be filled
 *         if 'formValues', compute need to be filled
 *
 * - options : the list of options in the dropdown represented by an array
 *           each object inside the array should have both a label and a value
 *
 * - compute : compute the array of options from the others values of the form
 */
class DashboardFormFieldDropdown extends BasicFormField {

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the class.
   */
  static defaultProps = {
    showEmpty: false,
    input: 'options',
    options: [],
    autoComplete: false,

    displayAsRows: true,
    colsSeparator: '|'
  }

  constructor(props) {
    super(props);

    this.uuid = uuid();
    //the state of the component
    //options are the options coming from the call
    this.state = Object.assign(this.state, {
      options: []
    })
  }

  /**
   * @inheritdoc
   */
  getSpecificAsserts() {
    return {
      notEmptyOrNull: NotEmptyOrNullAssert,
    }
  }

  /**
   * @inheritdoc
   */
  getValue(){
    let value = super.getValue();
    if(this.props.autoComplete && this.props.adapter && this.props.adapter.requirement(value)) {
      value = this.applyAdapter(value).label;
    }
    if(!this.props.autoComplete && this.props.adapter && this.props.adapter.requirement(value)) {
      value = this.applyAdapter(value).value;
    }
    return value;
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    let value = super.getFinalValue();
    if(this.props.adapter && this.props.adapter.isFinalValue && this.props.adapter.requirement(value)) {
      return this.applyAdapter(value).value;
    }
    return value;
  }

  /**
   * get the value of the options
   */
  getOptions() {
    //check for input type
    if(this.props.input === 'options' && this.props.options) {
      return this.props.options;
    }
    else if(this.props.input === 'formValues') {
      let options = this.props.compute(this.props.getValues, this.props.getExtraValues());
      if(options) {
        return options;
      }
    }
    return [];
  }

  /**
   * get the value of the options with the filter
   */
  getFilteredOptions() {
    if(this.props.filterOptions) {
      return this.getOptions().filter((item, i) => {
        return this.props.filterOptions(item, this.props.getValues, this.props.getExtraValues);
      })
    }
    return this.getOptions();
  }

  /**
   * apply the adapter for options
   */
  applyAdapter(option) {
    if(this.props.adapter && this.props.autoComplete) {
      return {
        value: 'value('+this.props.adapter.getValue(option)+')',
        label: this.props.adapter.getLabel(option)
      }
    }
    else if(this.props.adapter && !this.props.autoComplete) {
      return {
        value: this.props.adapter.getValue(option),
        label: this.props.adapter.getLabel(option)
      }
    }
    else if(option.value !== undefined && option.value !== null && option.label !== undefined  && option.label !== null) {
      return option;
    }
    return {
      value: option,
      label: option
    }
  }

  /**
   * Send new value to parent element
   */
  onChange = (value, ignoreValidation = false) => {
    let endValue = value;
    if(value && this.props.adapter && this.props.adapter.getValue && !this.props.adapter.isValue) {
      this.getOptions().some((item, i) => {
        if(this.props.autoComplete && 'value('+this.props.adapter.getValue(item).toString()+')' === value.toString()) {
          endValue = item;
          return true;
        }
        else if(!this.props.autoComplete && this.props.adapter.getValue(item).toString() === value.toString()) {
          endValue = item;
          return true;
        }
        return false;
      })
    }
    if(this.props.showEmpty && value === this.getValue()) {
      super.onChange(undefined, ignoreValidation);
    }
    else {
      super.onChange(endValue, ignoreValidation);
    }
  }

  displayOption(option) {
    let {value, label} = this.applyAdapter(option);
    let checked = value === this.getValue();
    return (
      <Button variant="my-seconday-noline" key={value} disabled={this.props.disabled} className={`dashboard-form-dropdown-option dashboard-form-dropdown-option-color${checked?" dashboard-form-dropdown-option-selected":""}`} onClick={() => this.onChange(value)}>
        <Row className="no-gutters">
          <Col xs="auto">&nbsp;&nbsp;&nbsp;<i className={`${checked?"dashboard-form-dropdown-option-icon icon-checkmark":"icon-media-record"}`}/>&nbsp;&nbsp;&nbsp;</Col><Col className="no-gutters dashboard-form-dropdown-option-text">{label}</Col>
        </Row>
      </Button>
    )
  }

  displayOptionsAsRows() {
    if(this.props.displayAsRows) {
      return (
        <>
          {this.getFilteredOptions().map((option, key) => {
            return <Row className="no-gutters" key={key}>{this.displayOption(option)}</Row>
          })}
        </>
      )
    }
  }

  displayOptionsAsCols() {
    if(!this.props.displayAsRows) {
      return (
        <>
          {this.getFilteredOptions().map((option, key) => {
            let separator = <div/>
            if(key > 0) {
              separator = <div className="dashboard-form-dropdown-option-color">&nbsp;&nbsp;{this.props.colsSeparator}&nbsp;&nbsp;</div>
            }
            return <React.Fragment key={key}>{separator}{this.displayOption(option)}</React.Fragment>
          })}
        </>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className={`dashboard-form-row${!this.props.skipSeparator?" dashboard-form-row-separator":""}`}>
        {this.props.label !== ""?<Row className="no-gutters">{this.props.label}{this.displayValidating()}{this.displayMiddlePart()}&nbsp;&nbsp;&nbsp;{this.displayOptionsAsCols()}</Row>:undefined}
        {(this.props.label === "" && !this.props.displayAsRows)?<Row className="no-gutters">{this.props.label}{this.displayValidating()}{this.displayMiddlePart()}&nbsp;&nbsp;&nbsp;{this.displayOptionsAsCols()}</Row>:undefined}
        {(this.props.label === "" && this.props.displayAsRows && this.props.middlePart)?<Row className="no-gutters">{this.displayMiddlePart()}</Row>:undefined}
        {this.displayOptionsAsRows()}
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    );
  }
}

/**
 * HOC Modal Handler to support call options
 */
export default DashboardFormFieldDropdown;
