import React from 'react';
import BasicFormField from '../../BasicFormField';

import { Form, Row, Col } from 'react-bootstrap';
import '../../../../../css/form.css';

/**
 * FormFieldColor
 *
 * This class handles HTML form field.
 *
 * The particular field is for color picker.
 *
 * Specific configuration attribute : none
 */
class DashboardFormFieldSwitch extends BasicFormField {

  componentDidMount() {
    super.componentDidMount();
    if(this.getValue() === undefined && this.props.defaultValue !== undefined) {
      this.onChange(this.props.defaultValue)
    }
  }

 getValue() {
   if(!this.props.value) {
     return false;
   }
   return this.props.value;
 }

 getFinalValue() {
   return this.getValue();
 }

  displayInput() {
    return (
      <Form.Check
        type="switch"
        id={`form-${this.props.formKey}-${this.props.fieldKey}-${this.props.disabled}`}
        label=""
        bsCustomPrefix="nk-switch"
        disabled={this.props.disabled || this.props.submitting}
        checked={this.getValue()}
        onChange={(event) => this.onChange(event.target.checked)}
      />
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className={`dashboard-form-row${!this.props.skipSeparator?" dashboard-form-row-separator":""}`}>
        <Row className="d-flex align-items-center no-gutters">
          {this.props.label !== ""?
            <Col xs="auto" className="no-gutters dashboard-field-switch-label">
              {this.props.label}&nbsp;{this.displayValidating()}&nbsp;&nbsp;&nbsp;{this.shouldDisplayMiddlePart()?<>&nbsp;&nbsp;&nbsp;{this.displayMiddlePart()}</>:''}
            </Col>
          :undefined}
          {this.props.label === "" && this.shouldDisplayMiddlePart()?
            <>
              <Col xs={12} className="no-gutters dashboard-field-switch-label">
                {this.displayValidating()}{this.displayMiddlePart()}
              </Col>
            </>
          :undefined}
          <Col xs={this.props.label === ""?12:""} className="no-gutters">
            {this.displayInput()}
          </Col>
        </Row>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    )
  }
}

export default DashboardFormFieldSwitch;
