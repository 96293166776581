import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

import { fetchPartners } from '../../API/Partners';
import { fetchPartnerAccounts } from '../../API/PartnerAccounts';
import { fetchPlaces } from '../../API/Places';
import { fetchTickets } from '../../API/Tickets';

import {
  form as _cartForm,
  clientRefererForm as _cartClientRefererForm,
  stateForm as _cartStateForm,
  technicalDataForm as _cartTechnicalDataForm
} from './carts';
import {
  numberForm as _billForm
} from './bills';

import {
  installationTicket as ticketsRoutes,
  orders as ordersRoutes,
  partners as partnersRoutes,
  places as placesRoutes
} from '../Route/routes';

import {
  numberForm as _quoteNumberForm
} from './quotes';

let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.order.number.label,
  validators: []
}

let numberWithLink = {
  fieldKey: "number",
  type: "text",
  label: strings.form.order.number.label,

  disabledLink: (value, getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    if(credentials && credentials.roles.includes("ROLE_ORDER")) {
      return getValues(0) && getValues(0)["id"]?ordersRoutes.routes.show.createPath(getValues(0)["id"]):null;
    }
    return false;
  },

  validators: []
}

let signatureDate = {
  fieldKey: "signature_date",
  type: "date",
  label: strings.form.order.signatureDate.label,
  validators: []
}

let validationDate = {
  fieldKey: "validation_date",
  type: "date",
  label: strings.form.order.validationDate.label,
  validators: []
}

let deliveryDate = {
  fieldKey: "delivery_date",
  type: "date",
  label: strings.form.order.deliveryDate.label,
  validators: []
}

let rightToCancellation = {
  fieldKey: "right_to_cancellation",
  type: "switch",
  label: strings.form.order.rightToCancellation.label,
  validators: []
}

let installationTicket = {
  fieldKey: "installation_ticket",
  label: strings.form.order.installationTicket.label,
  type: "dropdownEntity",

  disabledLink: (value) => value && value["ticket"]?ticketsRoutes.routes.show.createPath(value["ticket"]["id"]):null,

  validators: [],

  showEmpty: true,
  loader: {
    id: "tickets",
    loader: fetchTickets("technical"),
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["ticket"]["number"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["ticket"]["number"],
  },
}

let subFamilyRecap = {
  fieldKey: "sub_family_recap",
  label: "",
  type: "array",

  validators: [],

  subField: {
    type: "text",
    label: strings.form.order.subFamily.label,

    validators: []
  }
}

let client = {
  fieldKey: "client",
  type: "dropdownEntity",
  label: strings.form.order.client.label,
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && (credentials.roles.includes('ROLE_USER') || credentials.roles.includes('ROLE_NYUKOM'))) {
        return partnersRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  loader: {
    id: "clients",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let clientAccount = {
  fieldKey: "client_account",
  type: "dropdownEntity",
  label: strings.form.order.clientAccount.label,
  validators: [],

  loader: {
    id: "clientAccounts",
    loader: fetchPartnerAccounts,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["partner_type"] && data["partner_type"]["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["partner_type"]["name"],
  },
}

let place = {
  fieldKey: "place",
  type: "dropdownEntity",
  label: strings.form.order.place.label,
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && (credentials.roles.includes('ROLE_USER') || credentials.roles.includes('ROLE_NYUKOM'))) {
        return placesRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  loader: {
    id: "places",
    loader: fetchPlaces,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let cart = {
  fieldKey: "cart",
  type: "subform",
  label: strings.form.order.cartItems.label,

  subForm: _cartForm,

  validators: []
}

let cartClientReferer = {
  fieldKey: "cart",
  type: "subform",
  label: "",

  subForm: _cartClientRefererForm,

  validators: []
}

let cartState = {
  fieldKey: "cart",
  type: "subform",
  label: "",

  subForm: _cartStateForm,

  validators: []
}

let cartTechnicalData = {
  fieldKey: "cart",
  type: "subform",
  label: "",

  subForm: _cartTechnicalDataForm,

  validators: []
}

let quote = {
  fieldKey: "quote",
  type: "subform",
  label: "",
  showOnDisabled: (getValues) => {
    if(getValues() && Array.isArray(getValues()["quote"])) {
      return false;
    }
    return true;
  },

  validators: [],

  subForm: _quoteNumberForm,
}

let bills = {
  fieldKey: "bills",
  type: "array",
  label: "",

  validators: [],

  subField: {
    type: "subform",
    label: "",

    validators: [],

    subForm: _billForm,
  }
}

export const cartForm = {
  formKey: "order",
  fields: [
    cart,
  ]
}

export const identificationForm = {
  formKey: "order",
  fields: [
    numberWithLink,
    validationDate,
    deliveryDate,
    subFamilyRecap
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}

export const subFamilyRecapForm = {
  formKey: "order",
  fields: [
    subFamilyRecap
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}

export const technicalDataForm = {
  formKey: "order",
  fields: [
    cartTechnicalData
  ]
}

export const billForm = {
  formKey: "order",
  fields: [
    bills
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}

export const form = {
  formKey: "order",
  fields: [
    number,
    quote,
    signatureDate,
    validationDate,
    deliveryDate,
    rightToCancellation,
    installationTicket,
    cartState,
    client,
    clientAccount,
    place,
    cartClientReferer
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
