import strings from '../../Localization/Localization';

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { processTimeframe } from './common';

import {
  index,
  getFromId,
  getIdentificationFromId,
  getCartFromId,
  getTechnicalDataFromId,
  getBillFromId,
  editTechnicalDataFromId,
  // editFromId
} from '../../API/Orders';
import {
  form,
  cartForm,
  technicalDataForm,
  billForm
} from '../Form/orders';
import { form as searchForm } from '../Form/Search/orders';

import {
  orders as routes,
  carts as cartRoutes
} from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.orders,

    id: "order",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.title) {
        result["title"] = search.title;
      }
      if(search.number) {
        result["number"] = search.number;
      }
      if(search.installationTicket) {
        result["installation_ticket"] = search.installationTicket;
      }
      if(typeof search.state === 'object' && search.state.length > 0) {
        result["state"] = search.state.map((item) => item.value).toString();
      }
      if(search.rightToCancellation === true) {
        result["right_to_cancellation"] = 1;
      }
      else if(search.rightToCancellation === false) {
        result["right_to_cancellation"] = 0;
      }
      if(typeof search.client === 'object') {
        result["partner_id"] = search.client.id
      }
      if(typeof search.placeDelivery === 'object') {
        result["place_delivery_id"] = search.placeDelivery.id
      }
      if(typeof search.clientUser === 'object') {
        result["client_user_id"] = search.clientUser.id
      }
      if(search.validationDateTimeframe) {
        let validationDateTimeframe = processTimeframe(search.validationDateTimeframe);

        result["validation_date_min"] = validationDateTimeframe.min;
        result["validation_date_max"] = validationDateTimeframe.max;
      }
      if(search.validationDateMin) {
        result["validation_date_min"] = search.validationDateMin;
      }
      if(search.validationDateMax) {
        result["validation_date_max"] = search.validationDateMax;
      }
      if(search.createdAtTimeframe) {
        let createdAtTimeframe = processTimeframe(search.createdAtTimeframe);

        result["created_at_min"] = createdAtTimeframe.min;
        result["created_at_max"] = createdAtTimeframe.max;
      }
      if(search.createdAtMin) {
        result["created_at_min"] = search.createdAtMin;
      }
      if(search.createdAtMax) {
        result["created_at_max"] = search.createdAtMax;
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        enableHeaderEdit: true,
        defaultHeader: [0,1,2,3,4,5],
        headers: [
          {
            id: "state",
            label: <>&nbsp;&nbsp;<i className="icon-flag-1"/>&nbsp;&nbsp;</>,
            value: item => {
              switch (item["cart"]["state"]) {
                case 'o_w_validation':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.cart.state.options.order.waitingValidation}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-info-icon"><i className="icon-cart"/></div>
                    </OverlayTrigger>
                  );
                case 'o_c_period':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.cart.state.options.order.cancellationPeriod}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-warning-icon"><i className="icon-clock"/></div>
                    </OverlayTrigger>
                  );
                case 'o_w_a_payment':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.cart.state.options.order.waitingAdvancePayment}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-warning-icon"><i className="icon-card"/></div>
                    </OverlayTrigger>
                  );
                case 'o_d_i_progress':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.cart.state.options.order.deliveryInProgress}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-info-icon"><i className="icon-deplacement"/></div>
                    </OverlayTrigger>
                  );
                case 'o_delivered':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.cart.state.options.order.delivered}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-validated-icon"><i className="icon-cart"/></div>
                    </OverlayTrigger>
                  );
                case 'o_canceled':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.cart.state.options.order.canceled}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-danger-icon"><i className="icon-cart"/></div>
                    </OverlayTrigger>
                  );
                default:

              }
            }
          },
          {
            id: "number",
            label: "Numéro",
            value: "number"
          },
          {
            id: "partner",
            label: "Nom du partenaire",
            value: item => item["cart"]["client"]["partner"]["name"]
          },
          {
            id: "signatureDate",
            label: "Date de signature",
            value: item => item["signature_date"]
          },
          {
            id: "deliveryDate",
            label: "Date de livraison",
            value: item => item["delivery_date"]
          },
          {
            id: "subFmilies",
            label: "Sous-famille",
            value: item => {
              return item["sub_family_recap"].join(", ");
            }
          },
          {
            id: "place",
            label: "Site de livraison",
            value: item => item["cart"]["place"]["name"]
          },
        ]
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },
  },
  show: {
    pageTitle: strings.common.homepage.orders,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "number",

    apiGet: getIdentificationFromId,

    extendedHeader: true,
    header: {
      imgUrl: (item) => null,
      placeholderTitle: (item) => item["cart"]["client"]["partner"]["name"].split(' ').map((item) => item[0]).reduce((prev, curr) => prev+curr),
      label: (item) => item["cart"]["client"]["partner"]["name"] + " > " +  item["cart"]["place"]["name"],
      additionalData1: (item) => {
        let state = null;
        switch (item["cart"]["state"]) {
          case 'o_w_validation':
            state = strings.form.cart.state.options.order.waitingValidation
            break;
          case 'o_c_period':
            state = strings.form.cart.state.options.order.cancellationPeriod
            break;
          case 'o_w_a_payment':
            state = strings.form.cart.state.options.order.waitingAdvancePayment
            break;
          case 'o_d_i_progress':
            state = strings.form.cart.state.options.order.deliveryInProgress
            break;
          case 'o_delivered':
            state = strings.form.cart.state.options.order.delivered
            break;
          case 'o_canceled':
            state = strings.form.cart.state.options.order.canceled
            break;
          default:
        }
        return `${item?item["number"]:''}${state?` - ${state}`:''}`
      },
      additionalData2: (item) => '',
      additionalData3: (item) => '',
    },

    menu: (item) => {
      let buttons = [];
      if(item.cart.state === 'o_w_validation') {
        buttons.push({
          id: 'validatingOrder',
          type: 'redirection',
          auth: 'ROLE_ORDER_VALIDATING_ORDER',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'validatingOrder'),
          addVerification: true,
          label: strings.order.tabs.buttons.validatingOrder,
          variant: "my-validated"
        });
        buttons.push({
          id: 'cancelOrder',
          type: 'redirection',
          auth: 'ROLE_ORDER_CANCEL_ORDER',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'cancelOrder'),
          addVerification: true,
          label: strings.order.tabs.buttons.cancelOrder,
          variant: "my-warning"
        });
      }
      else if(item.cart.state === 'o_c_period') {
        buttons.push({
          id: 'cancelOrder',
          type: 'redirection',
          auth: 'ROLE_ORDER_CANCEL_ORDER',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'cancelOrder'),
          addVerification: true,
          label: strings.order.tabs.buttons.cancelOrder,
          variant: "my-warning"
        });
      }
      else if(item.cart.state === 'o_w_a_payment') {
        buttons.push({
          id: 'advancePaymentPaid',
          type: 'redirection',
          auth: 'ROLE_ORDER_ADVANCE_PAYMENT_PAID',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'advancePaymentPaid'),
          addVerification: true,
          label: strings.order.tabs.buttons.advancePaymentPaid,
          variant: "my-validated"
        });
      }
      else if(item.cart.state === 'o_d_i_progress') {
        buttons.push({
          id: 'cancelValidatedOrder',
          type: 'redirection',
          auth: 'ROLE_ORDER_CANCEL_VALIDATED_ORDER',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'cancelValidatedOrder'),
          addVerification: true,
          label: strings.order.tabs.buttons.cancelValidatedOrder,
          variant: "my-warning"
        });
        buttons.push({
          id: 'cancelValidatedOrderNoBill',
          type: 'redirection',
          auth: 'ROLE_ORDER_CANCEL_VALIDATED_ORDER_NO_BILL',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'cancelValidatedOrderNoBill'),
          addVerification: true,
          label: strings.order.tabs.buttons.cancelValidatedOrderNoBill,
          variant: "my-danger"
        });
        buttons.push({
          id: 'deliver',
          type: 'redirection',
          auth: 'ROLE_ORDER_DELIVER',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'deliver'),
          addVerification: true,
          label: strings.order.tabs.buttons.deliver,
          variant: "my-validated"
        });
      }
      return buttons;
    },

    tabs: {
      main: {
        title: strings.order.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {
          data["item"]["client_account"] = data["item"]["client"];
          data["item"]["client"] = data["item"]["client_account"]["partner"];
        },

        apiGet: getFromId,

        role: ['ROLE_ORDER'],
        editable: false
      },
      cart: {
        title: strings.order.tab.cart,
        type: 'form',
        form: cartForm,

        apiGet: getCartFromId,
        onUpdateItem: (data) => {},

        role: ['ROLE_ORDER'],
        editable: false
      },
      technicalData: {
        title: strings.order.tab.technicalData,
        type: 'form',
        form: technicalDataForm,

        onUpdateItem: (data) => {
          data["item"]["cart"]["cart_items"].forEach((item, i) => {
            data["item"]["cart"]["cart_items"][i]["_technical_data_values"] = []; //calculate from technical_data_values
            data["item"]["cart"]["cart_items"][i]["technical_data_values"].forEach((technicalDataValue) => {
              if(!data["item"]["cart"]["cart_items"][i]["_technical_data_values"][technicalDataValue["intern_id"]]) {
                data["item"]["cart"]["cart_items"][i]["_technical_data_values"][technicalDataValue["intern_id"]] = [];
              }
              data["item"]["cart"]["cart_items"][i]["_technical_data_values"][technicalDataValue["intern_id"]].push(technicalDataValue);
            });
          });
        },
        onSubmit: (data) => {
          data["cart"]["cart_items"].forEach((item, i) => {
            data["cart"]["cart_items"][i]["technical_data_values"] = data["cart"]["cart_items"][i]["_technical_data_values"].flat();
          });
        },
        postEdit: {
          type: 'tab',
          tabId: 'technicalData'
        },

        apiGet: getTechnicalDataFromId,
        apiEdit: editTechnicalDataFromId,

        role: ['ROLE_ORDER'],
        editable: ['ROLE_NYUKOM']
      },
      bill: {
        title: strings.order.tab.bill,
        type: 'form',
        form: billForm,

        apiGet: getBillFromId,
        onUpdateItem: (data) => {},

        role: ['ROLE_ACCOUNTING_BILL'],
        editable: false,
      },
    }
  }
}
