import strings from '../../Localization/Localization';

import { fetchTicketForms } from '../../API/TicketForms';

let formField = (type) => {
  let loader = fetchTicketForms(type);
  return {
    fieldKey: "form",
    label: strings.form.ticketFormAnswer.form.label,
    type: "dropdownEntity",

    validators: [
      {
        id: 'assertEmpty',
        type: 'notEmptyOrNull',
        invalidFeedback: strings.form.ticketFormAnswer.form.invalid.assertEmpty,
        defaultValue: false
      },
    ],

    showEmpty: true,
    loader: {
      id: "form",
      loader: loader,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["name"],
    },
  }
}

let answerFields = {
  fieldKey: "answer_fields",
  type: "array",
  label: "",

  validators: [],

  allowMoreField: false,
  defaultNumberOfField: 0,

  subField: {
    type: "configurableField",
    label: "",
    validators: [],

    subField: (value, getValues) => {
      if(value["form_field"]["field_type"] === "dropdown") {
        return {
          input: "options",
          options: value["form_field"]["dropdown_elements"],
          showEmpty: true,
          adapter: {
            requirement: (option) => option.value,
            getValue: (option) => option.value,
            getLabel: (option) => option.value,
            isFinalValue: true
          }
        }
      }
    },
    subFieldConf: {
      label: (value) => value["form_field"]["label"],
      type: (value) => value["form_field"]["field_type"],
      disabled: false,
      validators: (value) => [],
      valueAttribute: 'value'
    },
  }
}

export const newForm = (type) => {
  return {
    formKey: "ticketFormAnswer",
    fields: [
      formField(type)
    ]
  }
}

export const form = {
  formKey: "ticketFormAnswer",
  fields: [
    answerFields
  ]
}
