import React from 'react';
import FormFieldArray from '../../FormFieldArray';

/**
 * DashboardFormFieldArray
 *
 * This class handles HTML form field.
 *
 * The particular field is for array. It will display a list of fields using
 * one of the other type of field.
 *
 * Specific configuration attribute :
 * - defaultNumberOfField : the default number of field displayed
 * - allowMoreField : whether a button to add field should be displayed or not
 * - subField : an object representing the sub form :
 *   - type: the type of the subfield,
 *   - other specific field field
 */
class DashboardFormFieldArray extends FormFieldArray {

  static defaultProps = {
    filterValues: () => true
  }

  displayLabel() {
    if(this.props.label !== undefined && this.props.label !== null && this.props.label !== '') {
      return (
        <div className="col-12 no-gutters">
          {this.props.label}
        </div>
      )
    }
    return null;
  }
}

export default DashboardFormFieldArray;
