import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of pre fill comments based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(identifier) {
  return function (page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
    return baseIndex('pre_fill_comment/'+identifier, page, itemPerPage, search, order, scheme);
  }
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single pre fill comment based on his ID
 *
 * @param id the of the pre fill comment requested
 */
export function getFromId(identifier) {
  return function (id) {
    return baseGetFromId('pre_fill_comment/'+identifier, id);
  }
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getIdentificationDataFromId(identifier) {
  return function (id) {
    return baseGetFromId('pre_fill_comment/'+identifier, id, 'id');
  }
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single pre fill comment based on his ID
 *
 * @param id the of the pre fill comment requested
 * @param data the new data of the pre fill comment
 */
export function editFromId(identifier) {
  return function (id, data) {
    return baseEditFromId('pre_fill_comment/'+identifier, id, data);
  }
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new pre fill comment
 *
 * @param data the new data of the partner
 */
export function newItem(identifier) {
  return function (data) {
    return baseNewItem('pre_fill_comment/'+identifier, data);
  }
}


/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get the complete list of pre fill comments based on a pagination system
 */
export function fetchPreFillComments(identifier) {
  return function (){
    return baseIndex('pre_fill_comment/'+identifier, 1, 0);
  }
}
