import React from 'react';
import FormFieldText from '../../FormFieldText';

import { Form, Col, InputGroup } from 'react-bootstrap';
import '../../../../../css/form.css';
import '../../../../../css/call_report_form.css';

/**
 * CallReportFormFieldText
 *
 * Specific configuration attribute : none
 */
class CallReportFormFieldText extends FormFieldText {

  /**
   * Main render method for React Component
   */
  render() {
    let disabledLink = null;
    if(this.props.disabledLink) {
      disabledLink = this.props.disabledLink(this.props.value, this.props.getValues, this.props.getExtraValues)
    }
    return (
      <Col className="container-form-field container-form-field-call-report">
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
          <InputGroup.Prepend
            className="d-flex col-auto container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.icons.map((icon, i) => {
                return (
                  <i key={i} className={`icon-${icon}`}/>
                )
              })}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          {
            //If the field is not disabled show the field as normal
            !(this.props.disabled && disabledLink)?
              <Form.Control
                className="d-flex col-border-radius col-md-border-radius"
                placeholder={this.props.label}
                aria-label={this.props.label}
                aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
                type="text"
                disabled={this.props.disabled || this.props.submitting}
                value={this.getValue()}
                onChange={(event) => this.onChange(event.target.value)}
                isValid={(this.props.disabled)?null:this.isValid()}
                isInvalid={(this.props.disabled)?null:this.isInvalid()}
              />
              :
              //If the field is disabled show a link to send a mail
              <a className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius form-control align-items-center form-disabled" style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}} href={disabledLink}>{this.getValue()}</a>
          }
          {this.displayValidating()}
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    );
  }
}

export default CallReportFormFieldText;
