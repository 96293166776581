import React from 'react';

import '../../css/main_layout.css';

import { connect } from 'react-redux';

import Header from './Header';
import Footer from './Footer';
import LeftMenu from '../Menu/MainMenu/LeftMenu';
import BannerCenter from '../Banner/BannerCenter';

import CredentialsContainer from '../Credentials/CredentialsContainer';

import { Redirect } from "react-router-dom";

import { main as mainRoutes } from '../../MetaData/Route/routes';

/**
 * MainLayout
 *
 * The main layout of the application.
 * it should appear on almost every page, it displays the header, the footer,
 * the left menu and allocate a space for the page of the application
 */
class MainLayout extends React.Component {

  static defaultProps = {
    centerHeader: "breadcrumb",
    customCenterHeader: <div/>,

    leftMenu: "mainMenu",
    customLeftMenu: <div/>,
  }

  displayMaintenance() {
    if(
      this.props.credentials
      && this.props.credentials.roles
      && !this.props.credentials.roles.includes('ROLE_NYUKOM')
      && process.env.REACT_APP_MAINTENANCE_FOR_CLIENT === "1"
    ) {
      return (
        <Redirect to={mainRoutes.routes.maintenance.createPath()}/>
      )
    }
  }

  displayBanners() {
    return (
      <BannerCenter />
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <CredentialsContainer>
        {this.displayMaintenance()}
        <Header centerHeader={this.props.centerHeader} customCenterHeader={this.props.customCenterHeader}/>
        <div className="d-flex justify-content-row main-layout-container">
          <LeftMenu leftMenu={this.props.leftMenu} customLeftMenu={this.props.customLeftMenu}/>
          <div className="body">
            <div>
              {this.displayBanners()}
            </div>
            <div>
              {this.props.children}
            </div>
          </div>
        </div>
        <Footer/>
      </CredentialsContainer>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(MainLayout);
