import React from 'react';

import strings from '../../Localization/Localization';

import { Col, Row, Button } from 'react-bootstrap';

import { Redirect } from "react-router-dom";

import MainLayout from '../Layout/MainLayout';

import { withModalHandler } from '../../HOC/ModalHandler';

import { main as routes } from '../../MetaData/Route/routes'
import { getRoutes } from '../../MetaData/Configuration/tickets';

import '../../css/call_report_appointment_signature.css';

class CallReportAppointmentSignature extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      redirection: false,
      path: null,

      checked: {}
    }
  }

  componentDidMount() {
    let ids = this.getAppointmentIds();
    if(ids === null) {
      this.setState({
        redirection: true,
        path: routes.routes.dashboard.createPath()
      })
    }
    else if(ids.length === 1) {
      this.onClickButton(ids[0])
    }
  }

  getAppointmentIds() {
    let key_values = this.props.history.location.search.slice(1).split('&');
    let parameters = {};
    key_values.forEach(key_value => {
      key_value = key_value.split('=');
      parameters[key_value[0]] = key_value[1]
    });
    if(parameters.appointmentId) {
      return parameters.appointmentId.split(",").map(id => Number(id))
    }
    return null
  }

  onClickButton = (id) => {
    let checked = Object.assign({}, this.state.checked);
    checked[id] = true
    this.setState({
      checked: checked
    })
    this.openInNewTab(getRoutes('technical').routes.yousign.createPath(id))
  }

  openInNewTab = (href) => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
    }).click();
  }

  displayRedirect() {
    if(this.state.redirection === true) {
      return (
        <Redirect to={this.state.path} />
      )
    }
  }

  render() {
    let ids = this.getAppointmentIds();
    return (
      <MainLayout>
      {this.displayRedirect()}
        <Col className="" style={{ height: "100%" }}>
          {(ids && ids.length > 0)?
            ids.map((id, i) => {
              return (
                <Row key={i} className="justify-content-center call-report-appointment-signature-btn-container">
                  <Button variant="my-information" className="call-report-appointment-signature-btn" onClick={() => this.onClickButton(id)}>{this.state.checked[id]?<i className="icon-checkmark"/>:null}{`${strings.callReport.signature.appointment} ${i}`}</Button>
                </Row>
              )
            })
          :null}
        </Col>
      </MainLayout>
    )
  }
}

export default withModalHandler(CallReportAppointmentSignature);
