import strings from '../../Localization/Localization';

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { processTimeframe } from './common';

import {
  index,
  getFromId,
} from '../../API/CallReports';
import {
  form,
  referenceForm
} from '../Form/callReports';
import { form as searchForm } from '../Form/Search/callReports';

import { callReports as routes } from '../Route/routes';

export const conf = {
  prefix: "callReportArchive",
  index: {
    pageTitle: strings.common.homepage.callReport,

    id: "callReport",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};

      if(search.inout) {
        result["inout"] = search.inout
      }
      if(search.result) {
        result["result"] = search.result
      }
      if(typeof search.operator === 'object') {
        result["operator"] = search.operator.id
      }
      if(search.number) {
        result["number"] = search.number
      }
      if(typeof search.user === 'object') {
        result["user"] = search.user.id
      }
      else if(typeof search.user === 'string') {
        result["temporary_user_name"] = search.user
      }
      if(typeof search.partner === 'object') {
        result["partner"] = search.partner.id
      }
      if(search.beginAtTimeframe) {
        let beginAtTimeframe = processTimeframe(search.beginAtTimeframe);

        result["begin_at_min"] = beginAtTimeframe.min;
        result["begin_at_max"] = beginAtTimeframe.max;
      }
      if(search.beginAtMin) {
        result["begin_at_min"] = search.beginAtMin;
      }
      if(search.beginAtMax) {
        result["begin_at_max"] = search.beginAtMax;
      }

      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        headers: [
          {
            label: <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip>{strings.callReport.generalData.form.result.label}</Tooltip>
              }>
              <i className="icon-phone"/>
            </OverlayTrigger>,
            value: item => {
              switch (item["result"]) {
                case 'finished':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.callReport.generalData.form.result.options.finished}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-validated-icon"><i className="icon-phone"/><i className="icon-checkmark"/></div>
                    </OverlayTrigger>
                  );
                case 'failed':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.callReport.generalData.form.result.options.failed}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-danger-icon"><i className="icon-phone"/><i className="icon-cross"/></div>
                    </OverlayTrigger>
                  );
                case 'callbackRequest':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.callReport.generalData.form.result.options.callbackRequest}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-info-icon"><i className="icon-question"/><i className="icon-arrow-thin-left"/><i className="icon-phone"/></div>
                    </OverlayTrigger>
                  );
                case 'callback':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.callReport.generalData.form.result.options.callback}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-warning-icon"><i className="icon-phone"/><i className="icon-arrow-thin-right"/><i className="icon-question"/></div>
                    </OverlayTrigger>
                  );
                case 'transfer':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.callReport.generalData.form.result.options.transfer}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-validated-icon"><i className="icon-phone"/><i className="icon-forward"/></div>
                    </OverlayTrigger>
                  );
                default:

              }
            }
          },
          {
            label: <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip>{strings.callReport.generalData.form.inout.label}</Tooltip>
              }>
              <i className="icon-swap"/>
            </OverlayTrigger>,
            value: item => {
              switch (item["inout"]) {
                case 'in':
                  return "e";
                case 'out':
                  return "s";
                default:

              }
            }
          },
          {
            label: "Opérateur",
            value: (item) => item.operator.displayname
          },
          {
            label: strings.callReport.index.tab.headers.number,
            value: "number"
          },
          {
            label: "Utilisateur",
            value: (item) => (item.user && item.user.id)?item.user.displayname:item["temporary_user"].name
          },
          {
            label: "Date",
            value: (item) => {
              let date = item["begin_at"].split('T');
              return `${date[0]} à ${date[1]}`
            }
          },
          {
            label: "Partenaire",
            value: (item) => item.partner.name
          },
          {
            label: "Références",
            value: (item) => item.bills.length + item.orders.length + item.quotes.length + item.services.length + item.tickets.length + item.ticket_comments.length + item.ticket_appointments.length
          },
        ]
      }
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },
  },
  show: {
    pageTitle: strings.common.homepage.callReport,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "number",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.callReport.show.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (data) => {},

        apiGet: getFromId,

        auth: ['ROLE_NYUKOM'],
        editable: false
      },
      reference: {
        title: strings.callReport.show.tab.reference,
        type: 'form',
        form: referenceForm,

        onUpdateItem: (data) => {},
        onSubmit: (data) => {},

        apiGet: getFromId,

        auth: ['ROLE_NYUKOM'],
        editable: false
      },

    }
  }
}
