import strings from '../../Localization/Localization';

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { store } from '../../Store/configureStore';

import {
  orders as ordersRoutes,
  quotes as quotesRoutes,
  terminationTicket as ticketsRoutes,
  partners as partnersRoutes,
  places as placesRoutes,
  users as usersRoutes,
  products as productsRoutes
} from '../Route/routes';

import { fetchPartners } from '../../API/Partners';
import { fetchPartnerAccounts } from '../../API/PartnerAccounts';
import { fetchPlaces } from '../../API/Places';
import { fetchPartnerUsers } from '../../API/Users';
import { fetchCentreonHost } from '../../API/CentreonHost';
import { fetchCentreonServices } from '../../API/CentreonService';

import { activeForm as _contractForm } from './contracts';
import {
  form as _phonePackageOrderForm,
  newForm as _newPhonePackageOrderForm,
  consumptionForm as _phonePackageConsumptionForm
} from './phonePackageOrders';
import {
  form as _specificPhonePriceListForm
} from './specificPhonePriceLists';
import {
  form as _dependencyForm
} from './serviceDependencies';



let serialNumber = {
  fieldKey: "serial_number",
  label: strings.form.service.serialNumber.label,
  type: "text",
  validators: [],
}

let productName = {
  fieldKey: "product_name",
  label: strings.form.service.productName.label,
  type: "text",
  disabled: true,
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let values = getValues();
      let credentials = getExtraValues().credentials();
      if(values && values["cart_item"] && values["cart_item"]["product"] && values["cart_item"]["product"]["id"] && (credentials.roles.includes('ROLE_ORDER'))) {
        return productsRoutes.routes.show.createPath(values["cart_item"]["product"]["id"]);
      }
    }
    return false;
  },
}

let order = {
  fieldKey: "order",
  type: "dropdownEntity",
  label: strings.form.service.orderNumber.label,
  disabled: true,
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && (credentials.roles.includes('ROLE_ORDER'))) {
        return ordersRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  loader: {
    id: "orders",
    loader: null,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["number"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["number"],
  },
}

let quote = {
  fieldKey: "quote",
  type: "dropdownEntity",
  label: strings.form.quote.number.label,
  disabled: true,
  showOnDisabled: (getValues) => {
    let values = getValues();
    return values["quote"] && values["quote"]["id"];
  },
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && (credentials.roles.includes('ROLE_QUOTE'))) {
        return quotesRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  loader: {
    id: "quotes",
    loader: null,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["number"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["number"],
  },
}

let terminationTicket = {
  fieldKey: "termination_ticket",
  type: "dropdownEntity",
  label: strings.form.service.terminationTicket.label,
  disabled: true,
  showOnDisabled: (getValues) => !Array.isArray(getValues()["termination_ticket"]),
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && (credentials.roles.includes('ROLE_USER') || credentials.roles.includes('ROLE_NYUKOM'))) {
        return ticketsRoutes.routes.show.createPath(value["ticket"]["id"]);
      }
    }
    return false;
  },

  loader: {
    id: "tickets",
    loader: null,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["ticket"] && data["ticket"]["number"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["ticket"]["number"],
  },
}



let partner = {
  fieldKey: "client",
  type: "dropdownEntity",
  label: strings.form.service.partner.label,
  disabled: true,
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && (credentials.roles.includes('ROLE_USER') || credentials.roles.includes('ROLE_NYUKOM'))) {
        return partnersRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  loader: {
    id: "partners",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let client = {
  fieldKey: "client_account",
  type: "dropdownEntity",
  label: strings.form.service.client.label,
  disabled: true,
  validators: [],

  loader: {
    id: "clients",
    loader: fetchPartnerAccounts,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["partner_type"] && data["partner_type"]["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["partner_type"]["name"],
  },
}

let place = {
  fieldKey: "place",
  type: "dropdownEntity",
  label: strings.form.service.place.label,
  disabled: true,
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && (credentials.roles.includes('ROLE_USER') || credentials.roles.includes('ROLE_NYUKOM'))) {
        return placesRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  loader: {
    id: "places",
    loader: fetchPlaces,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let clientReferrer = {
  fieldKey: "client_referrer",
  label: strings.form.cart.clientUser.label,
  type: "dropdownEntity",
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && (credentials.roles.includes('ROLE_USER_INDEX'))) {
        return usersRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  showEmpty: true,
  loader: {
    id: "clientUser",
    loader: fetchPartnerUsers,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let technicalData = {
  fieldKey: "_technical_data_values",
  type: "array",
  label: "",

  validators: [],

  allowMoreField: false,
  defaultNumberOfField: 0,

  subField: {
    type: "array",
    label: "Entité #key#",

    validators: [],

    allowMoreField: false,
    defaultNumberOfField: 0,

    subField: {
      type: 'configurableField',
      label: "",
      validators: [],

      subField: () => {},
      subFieldConf: {
        label: (value) => value["technical_data"]["name"],
        type: (value) => 'text',
        disabled: () => false,
        validators: (value) => [],
        valueAttribute: 'value'
      },
    }
  }
}

let activeContract = {
  fieldKey: "active_contract",
  type: "subform",
  label: "",

  subForm: _contractForm,

  validators: []
}

let contracts = {
  fieldKey: "contracts",
  type: "array",
  label: "",

  validators: [],

  allowMoreField: false,
  defaultNumberOfField: 0,

  subField: {
    type: "subform",
    label: strings.form.service.contracts.label,

    subForm: _contractForm,

    validators: []
  }
}

let amendments = {
  fieldKey: "amendments",
  type: "table",
  label: strings.form.service.amendments.label,
  validators: [],

  table: {
    headers: [
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: (item) => {
          let type = "";
          switch (item["amendment_type"]) {
            case 'service':
              type = strings.form.amendment.type.options.sAlt;
              break;
            case 'contract':
              type = strings.form.amendment.type.options.cAlt;
              break;
            default:
          }
          return type;
        },
        label: "Type",
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: (item) => {
          let state = "";
          switch (item["state"]) {
            case 'a_waiting':
              state = strings.form.cart.state.options.amendment.waiting;
              break;
            case 'a_validated':
              state = strings.form.cart.state.options.amendment.validated;
              break;

            case 'q_w_agreement':
              state = strings.form.cart.state.options.quote.waitingAgreement;
              break;
            case 'q_w_validation':
              state = strings.form.cart.state.options.quote.waitingValidation;
              break;
            case 'q_validated':
              state = strings.form.cart.state.options.quote.validated;
              break;
            case 'q_canceled':
              state = strings.form.cart.state.options.quote.canceled;
              break;
            case 'q_w_yousign':
              state = strings.form.cart.state.options.quote.yousign;
              break;

            case 'o_w_validation':
              state = strings.form.cart.state.options.order.waitingValidation;
              break;
            case 'o_c_period':
              state = strings.form.cart.state.options.order.cancellationPeriod;
              break;
            case 'o_w_a_payment':
              state = strings.form.cart.state.options.order.waitingAdvancePayment;
              break;
            case 'o_d_i_progress':
              state = strings.form.cart.state.options.order.deliveryInProgress;
              break;
            case 'o_delivered':
              state = strings.form.cart.state.options.order.delivered;
              break;
            case 'o_canceled':
              state = strings.form.cart.state.options.order.canceled;
              break;
            default:
          }
          return state;
        },
        label: "Etat",
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: "applied_at",
        label: "Date d'application",
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: (data) => data["last_order"] && data["last_order"]["number"]?data["last_order"]["number"]:"",
        label: "Commande préc.",
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: (item) => item["next_order"] && item["next_order"]["number"]?item["next_order"]["number"]:"",
        label: "Commande suiv.",
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "raw",
        value: (item) => {
          let quantity = item["change"].includes('quantity');
          let product = item["change"].includes('product');
          let place = item["change"].includes('place');
          let price = item["change"].includes('price');

          return (
            <>
              {quantity?
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>{`+${(item["next_cart_item_order"]["quantity"])?item["next_cart_item_order"]["quantity"]:item["next_cart_item_quote"]["quantity"]}`}</Tooltip>
                  }>
                  <div>Quantité</div>
                </OverlayTrigger>
              :null}
              &nbsp;
              {product?
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>{`${item["previous_cart_item"]["product"]["name"]} => ${(item["next_cart_item_order"]["product"])?item["next_cart_item_order"]["product"]["name"]:item["next_cart_item_quote"]["product"]["name"]}`}</Tooltip>
                  }>
                  <div>Produit</div>
                </OverlayTrigger>
              :null}
              &nbsp;
              {place?
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>{`Voir commande`}</Tooltip>
                  }>
                  <div>Déménagement</div>
                </OverlayTrigger>
              :null}
              &nbsp;
              {price?
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>{`${item["previous_cart_item"]["price"]} => ${(item["next_cart_item_order"]["price"])?item["next_cart_item_order"]["price"]:item["next_cart_item_quote"]["price"]}`}</Tooltip>
                  }>
                  <div>Tarif</div>
                </OverlayTrigger>
              :null}
            </>
          );
          // return <div>{item["change"]?item["change"].reduce((accumulator, currentValue) => accumulator + "\n" + currentValue):""}</div>;
        },
        label: "Changement",
        editable: false
      },
    ]
  }
}

// export const cartForm = {
//   formKey: "service",
//   fields: [
//     // cart,
//   ]
// }

// export const identificationForm = {
//   formKey: "service",
//   fields: [
//     // number,
//     // validationDate,
//     // deliveryDate
//   ]
// }

let apiTargetCdrFlex = {
  fieldKey: "api_target_cdr_flex",
  type: "subform",
  label: strings.form.cartItem.apiCdrFlex.label,
  validators: [],

  subForm: {
    formKey: "apiCdrFlex",
    fields: [
      {
        fieldKey: "api_id",
        type: "text",
        label: strings.form.cartItem.apiKeyFlex.label,
        validators: []
      }
    ]
  },
}

let apiTargetCdrAlphalink = {
  fieldKey: "api_target_cdr_alphalink",
  type: "subform",
  label: strings.form.cartItem.apiCdrAlphalink.label,
  validators: [],

  subForm: {
    formKey: "apiCdrAlphalink",
    fields: [
      {
        fieldKey: "api_id",
        type: "text",
        label: strings.form.cartItem.apiKeyAlphalink.label,
        validators: []
      },
      {
        fieldKey: "origin",
        type: "dropdown",
        label: strings.form.cartItem.apiOriginCdrAlphalink.label,
        validators: [],

        showEmpty: true,
        input: 'options',
        options: [
          {
            value: "alphalink",
            label: strings.form.cartItem.apiOriginCdrAlphalink.options.alphalink
          },
          {
            value: "orange",
            label: strings.form.cartItem.apiOriginCdrAlphalink.options.orange
          },
        ],
      }
    ]
  },
}

let apiTargetCdrFile = {
  fieldKey: "api_target_cdr_file",
  type: "subform",
  label: strings.form.cartItem.apiCdrFile.label,
  validators: [],

  subForm: {
    formKey: "apiCdrFile",
    fields: [
      {
        fieldKey: "api_id",
        type: "text",
        label: strings.form.cartItem.apiKeyFile.label,
        validators: []
      }
    ]
  },
}

let phonePackageOrder = {
  fieldKey: "phone_package_order",
  type: "subform",
  label: "",
  validators: [],

  subForm: _phonePackageOrderForm,
}

let phonePackageOrderNew = {
  fieldKey: "phone_package_order",
  type: "subform",
  label: "",
  validators: [],

  subForm: _newPhonePackageOrderForm,
}

let phonePackageConsumption = {
  fieldKey: "phone_package_order",
  type: "subform",
  label: "",
  validators: [],

  subForm: _phonePackageConsumptionForm,
}

let specificPhonePriceList = {
  fieldKey: "specific_phone_price_list",
  type: "subform",
  label: strings.form.service.specificPhonePriceList.label,
  validators: [],

  subForm: _specificPhonePriceListForm,
}

let dependencySlaves = {
  fieldKey: "dependency_slaves",
  type: "array",
  label: "",

  validators: [],

  allowMoreField: false,
  defaultNumberOfField: 0,

  subField: {
    type: "subform",
    label: "",

    subForm: _dependencyForm,

    validators: []
  }
}

let centreonHost = {
  fieldKey: "centreon_host",
  label: strings.form.service.centreonHost.label,
  type: "dropdownEntity",
  validators: [],

  showEmpty: true,
  loader: {
    id: "centreonHost",
    loader: fetchCentreonHost,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let centreonService = {
  fieldKey: "centreon_service",
  label: strings.form.service.centreonService.label,
  type: "dropdownEntity",
  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "centreon_host",
      function: (component) => component.reloadData()
    }
  ],

  showEmpty: true,
  loader: {
    id: "centreonService",
    loader: fetchCentreonServices,
    loaderRequire: () => true,
    loaderArguments: (value, getValue) => {
      let filters = {};
      let values = getValue(0);
      if(values["centreon_host"] && values["centreon_host"]["id"]) {
        filters["host_id"] = values["centreon_host"]["id"]
      }
      return [filters];
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

export const centreonConfigurationForm = {
  formKey: "service",
  fields: [
    centreonHost,
    centreonService
  ]
}

let centreonConfigurations = {
  fieldKey: "centreon_configurations",
  type: "array",
  label: "",

  validators: [],

  allowMoreField: true,
  defaultNumberOfField: 1,

  allowMoreFieldAddLabel: strings.form.service.centreonConfigurations.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.service.centreonConfigurations.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.service.centreonConfigurations.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: strings.form.service.centreonConfigurations.label,

    subForm: centreonConfigurationForm,

    validators: []
  }
}

export const phonePackageApiForm = {
  formKey: "service",
  fields: [
    apiTargetCdrFlex,
    apiTargetCdrAlphalink,
    apiTargetCdrFile
  ]
}

export const phonePackageOrderForm = {
  formKey: "service",
  fields: [
    phonePackageOrder
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}

export const phonePackageConsumptionForm = {
  formKey: "service",
  fields: [
    phonePackageConsumption
  ]
}

export const specificPhonePriceListForm = {
  formKey: "service",
  fields: [
    specificPhonePriceList
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}

export const technicalDataForm = {
  formKey: "service",
  fields: [
    technicalData
  ]
}

export const activeContractForm = {
  formKey: "service",
  fields: [
    activeContract
  ]
}

export const archiveContractForm = {
  formKey: "service",
  fields: [
    contracts
  ]
}

export const amendmentForm = {
  formKey: "service",
  fields: [
    amendments
  ]
}

export const centreonConfigurationsForm = {
  formKey: "service",
  fields: [
    centreonConfigurations
  ]
}

export const addPhonePackageForm = {
  formKey: "service",
  fields: [
    phonePackageOrderNew
  ]
}

export const dependencyForm = {
  formKey: "service",
  fields: [
    dependencySlaves
  ]
}

export const form = {
  formKey: "service",
  fields: [
    serialNumber,
    productName,
    order,
    quote,
    terminationTicket,
    partner,
    client,
    place,
    clientReferrer
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
