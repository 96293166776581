import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

import {
  bills as billsRoutes,
  quotes as quotesRoutes,
  orders as ordersRoutes,
  services as servicesRoutes
} from '../Route/routes';

import {
  getRoutes as getTicketRoutes
} from '../Configuration/tickets'

let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.callReport.number.label,
  validators: []
}

let operator = {
  fieldKey: "operator",
  label: strings.form.callReport.operator.label,
  type: "dropdownEntity",
  showOnDisabled: (getValues) => {
    let values = getValues();
    return values.user && values.user.id;
  },

  validators: [],

  showEmpty: true,
  loader: null,

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let user = {
  fieldKey: "user",
  label: strings.form.callReport.user.label,
  type: "dropdownEntity",
  showOnDisabled: (getValues) => {
    let values = getValues();
    return values.user && values.user.id;
  },

  validators: [],

  showEmpty: true,
  loader: null,

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let temporaryUser = {
  fieldKey: "temporary_user",
  type: "dropdownEntity",
  label: strings.form.callReport.temporaryUser.label,
  showOnDisabled: (getValues) => {
    let values = getValues();
    return values["temporary_user"] && values["temporary_user"].id;
  },

  ignoreValidation: true,
  validators: [],

  showEmpty: true,
  loader: null,

  adapter: {
    requirement: (data) => typeof data === 'object' && data["name"],
    getValue: (data) => data["name"],
    getLabel: (data) => data["name"],
    isFinalValue: true
  },
}

let temporaryUserFunction = {
  fieldKey: "temporary_user",
  type: "dropdownEntity",
  label: strings.form.callReport.temporaryUserFunction.label,
  showOnDisabled: (getValues) => {
    let values = getValues();
    return values["temporary_user"] && values["temporary_user"].id;
  },

  ignoreValidation: true,
  validators: [],

  showEmpty: true,
  loader: null,

  adapter: {
    requirement: (data) => typeof data === 'object' && data["name"],
    getValue: (data) => data["function"],
    getLabel: (data) => data["function"],
    isFinalValue: true
  },
}

let partner = {
  fieldKey: "partner",
  label: strings.form.callReport.partner.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  loader: null,

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let beginAt = {
  fieldKey: "begin_at",
  type: "datetime",
  label: strings.form.callReport.beginAt.label,

  validators: []
}

let inout = {
  fieldKey: "inout",
  type: "dropdown",
  label: strings.form.callReport.inout.label,

  validators: [],

  input: 'options',
  showEmpty: true,
  options: [
    {
      value: 'in',
      label: strings.form.callReport.inout.options.in
    },
    {
      value: 'out',
      label: strings.form.callReport.inout.options.out
    }
  ]
}

let result = {
  fieldKey: "result",
  type: "dropdown",
  label: strings.form.callReport.result.label,

  validators: [],

  input: 'options',
  showEmpty: true,
  options: [
    {
      value: 'finished',
      label: strings.form.callReport.result.options.finished
    },
    {
      value: 'failed',
      label: strings.form.callReport.result.options.failed
    },
    {
      value: 'callbackRequest',
      label: strings.form.callReport.result.options.callbackRequest
    },
    {
      value: 'callback',
      label: strings.form.callReport.result.options.callback
    },
    {
      value: 'transfer',
      label: strings.form.callReport.result.options.transfer
    }
  ]
}

let comment = {
  fieldKey: "comment",
  type: "textarea",
  label: strings.form.callReport.comment.label,
  validators: []
}

let bills = {
  fieldKey: "bills",
  type: "array",
  label: strings.form.callReport.bills.label,
  showOnDisabled: (getValues) => getValues().bills.length > 0,

  validators: [],

  subField: {
    type: "subform",
    label: "",

    validators: [],

    subForm: {
      formKey: "callReportBills",
      fields: [
        {
          fieldKey: "bill",
          type: "dropdownEntity",
          label: strings.form.bill.number.label,

          disabledLink: (value, getValues, getExtraValues) => {
            let credentials = getExtraValues().credentials();
            if(credentials && credentials.roles.includes("ROLE_ACCOUNTING_BILL")) {
              return value && value["id"]?billsRoutes.routes.show.createPath(value["id"]):null;
            }
            return false;
          },

          validators: [],

          loader: null,

          adapter: {
            requirement: (data) => data["id"] && data["number"],
            getValue: (data) => data["id"],
            getLabel: (data) => data["number"],
          },
        }
      ]
    },
  }
}

let quotes = {
  fieldKey: "quotes",
  type: "array",
  label: strings.form.callReport.quotes.label,
  showOnDisabled: (getValues) => getValues().quotes.length > 0,

  validators: [],

  subField: {
    type: "subform",
    label: "",

    validators: [],

    subForm: {
      formKey: "callReportQuotes",
      fields: [
        {
          fieldKey: "quote",
          type: "dropdownEntity",
          label: strings.form.quote.number.label,

          disabledLink: (value, getValues, getExtraValues) => {
            let credentials = getExtraValues().credentials();
            if(credentials && credentials.roles.includes("ROLE_QUOTE")) {
              return value && value["id"]?quotesRoutes.routes.show.createPath(value["id"]):null;
            }
            return false;
          },

          validators: [],

          loader: null,

          adapter: {
            requirement: (data) => data["id"] && data["number"],
            getValue: (data) => data["id"],
            getLabel: (data) => data["number"],
          },
        }
      ]
    },
  }
}

let orders = {
  fieldKey: "orders",
  type: "array",
  label: strings.form.callReport.orders.label,
  showOnDisabled: (getValues) => getValues().orders.length > 0,

  validators: [],

  subField: {
    type: "subform",
    label: "",

    validators: [],

    subForm: {
      formKey: "callReportOrders",
      fields: [
        {
          fieldKey: "order",
          type: "dropdownEntity",
          label: strings.form.order.number.label,

          disabledLink: (value, getValues, getExtraValues) => {
            let credentials = getExtraValues().credentials();
            if(credentials && credentials.roles.includes("ROLE_ORDER")) {
              return value && value["id"]?ordersRoutes.routes.show.createPath(value["id"]):null;
            }
            return false;
          },

          validators: [],

          loader: null,

          adapter: {
            requirement: (data) => data["id"] && data["number"],
            getValue: (data) => data["id"],
            getLabel: (data) => data["number"],
          },
        }
      ]
    },
  }
}

let services = {
  fieldKey: "services",
  type: "array",
  label: strings.form.callReport.services.label,
  showOnDisabled: (getValues) => getValues().services.length > 0,

  validators: [],

  subField: {
    type: "subform",
    label: "",

    validators: [],

    subForm: {
      formKey: "callReportServices",
      fields: [
        {
          fieldKey: "service",
          type: "dropdownEntity",
          label: strings.form.service.serialNumber.label,

          disabledLink: (value, getValues, getExtraValues) => {
            let credentials = getExtraValues().credentials();
            if(credentials && credentials.roles.includes("ROLE_NYUKOM")) {
              return value && value["id"]?servicesRoutes.routes.show.createPath(value["id"]):null;
            }
            return false;
          },

          validators: [],

          loader: null,

          adapter: {
            requirement: (data) => data["id"],
            getValue: (data) => data["id"],
            getLabel: (data) => data["serial_number"]?data["serial_number"]:"N/A",
          },
        }
      ]
    },
  }
}

let tickets = {
  fieldKey: "tickets",
  type: "array",
  label: strings.form.callReport.tickets.label,
  showOnDisabled: (getValues) => getValues().tickets.length > 0,

  validators: [],

  subField: {
    type: "subform",
    label: "",

    validators: [],

    subForm: {
      formKey: "callReportTickets",
      fields: [
        {
          fieldKey: "ticket",
          type: "dropdownEntity",
          label: strings.form.ticket.number.label,

          disabledLink: (value, getValues, getExtraValues) => {
            let credentials = getExtraValues().credentials();
            if(credentials && credentials.roles.includes("ROLE_NYUKOM")) {
              return value && value["id"]?getTicketRoutes(value["request_type"]["group"]["name"]).routes.show.createPath(value["id"]):null;
            }
            return false;
          },

          validators: [],

          loader: null,

          adapter: {
            requirement: (data) => data["id"] && data["number"],
            getValue: (data) => data["id"],
            getLabel: (data) => data["number"],
          },
        }
      ]
    },
  }
}

let ticketComments = {
  fieldKey: "ticket_comments",
  type: "array",
  label: strings.form.callReport.ticketComments.label,
  showOnDisabled: (getValues) => getValues()["ticket_comments"].length > 0,

  validators: [],

  subField: {
    type: "subform",
    label: "",

    validators: [],

    subForm: {
      formKey: "callReportTicketComments",
      fields: [
        {
          fieldKey: "comment",
          type: "subform",
          label: "",

          validators: [],

          subForm: {
            formKey: "callReportTicketComments",
            fields: [
              {
                fieldKey: "ticket",
                type: "dropdownEntity",
                label: strings.form.ticket.number.label,

                disabledLink: (value, getValues, getExtraValues) => {
                  let credentials = getExtraValues().credentials();
                  if(credentials && credentials.roles.includes("ROLE_NYUKOM")) {
                    return value && value["id"]?getTicketRoutes(value["request_type"]["group"]["name"]).routes.show.createPath(value["id"]):null;
                  }
                  return false;
                },

                validators: [],

                loader: null,

                adapter: {
                  requirement: (data) => data["id"] && data["number"],
                  getValue: (data) => data["id"],
                  getLabel: (data) => data["number"],
                },
              },
              {
                fieldKey: "body",
                type: "textarea",
                label: strings.form.comment.body.label,
                validators: []
              }
            ]
          }
        }
        // {
        //   fieldKey: "comment",
        //   type: "dropdownEntity",
        //   label: strings.form.ticket.number.label,
        //
        //   disabledLink: (value, getValues, getExtraValues) => {
        //     console.log(value);
        //     let credentials = getExtraValues().credentials();
        //     if(credentials && credentials.roles.includes("ROLE_NYUKOM")) {
        //       return value && value["id"]?getTicketRoutes(value["ticket"]["request_type"]["group"]["name"]).routes.show.createPath(value["id"]):null;
        //     }
        //     return false;
        //   },
        //
        //   validators: [],
        //
        //   loader: null,
        //
        //   adapter: {
        //     requirement: (data) => data["id"] && data["ticket"] && data["ticket"]["number"],
        //     getValue: (data) => data["id"],
        //     getLabel: (data) => data["ticket"]["number"],
        //   },
        // },
        // {
        //   fieldKey: "comment",
        //   type: "dropdownEntity",
        //   label: strings.form.ticket.number.label,
        //
        //   validators: [],
        //
        //   loader: null,
        //
        //   adapter: {
        //     requirement: (data) => data["id"] && data["body"],
        //     getValue: (data) => data["id"],
        //     getLabel: (data) => data["body"],
        //   },
        // }
      ]
    },
  }
}

let ticketAppointments = {
  fieldKey: "ticket_appointments",
  type: "array",
  label: strings.form.callReport.ticketAppointments.label,
  showOnDisabled: (getValues) => getValues()["ticket_appointments"].length > 0,

  validators: [],

  subField: {
    type: "subform",
    label: "",

    validators: [],

    subForm: {
      formKey: "callReportTicketAppointments",
      fields: [
        {
          fieldKey: "appointment",
          type: "subform",
          label: "",

          validators: [],

          subForm: {
            formKey: "callReportTicketAppointments",
            fields: [
              {
                fieldKey: "ticket",
                type: "dropdownEntity",
                label: strings.form.ticket.number.label,

                disabledLink: (value, getValues, getExtraValues) => {
                  let credentials = getExtraValues().credentials();
                  if(credentials && credentials.roles.includes("ROLE_NYUKOM")) {
                    return value && value["id"]?getTicketRoutes(value["request_type"]["group"]["name"]).routes.show.createPath(value["id"]):null;
                  }
                  return false;
                },

                validators: [],

                loader: null,

                adapter: {
                  requirement: (data) => data["id"] && data["number"],
                  getValue: (data) => data["id"],
                  getLabel: (data) => data["number"],
                },
              },
            ]
          }
        }
        // {
        //   fieldKey: "comment",
        //   type: "dropdownEntity",
        //   label: strings.form.ticket.number.label,
        //
        //   disabledLink: (value, getValues, getExtraValues) => {
        //     console.log(value);
        //     let credentials = getExtraValues().credentials();
        //     if(credentials && credentials.roles.includes("ROLE_NYUKOM")) {
        //       return value && value["id"]?getTicketRoutes(value["ticket"]["request_type"]["group"]["name"]).routes.show.createPath(value["id"]):null;
        //     }
        //     return false;
        //   },
        //
        //   validators: [],
        //
        //   loader: null,
        //
        //   adapter: {
        //     requirement: (data) => data["id"] && data["ticket"] && data["ticket"]["number"],
        //     getValue: (data) => data["id"],
        //     getLabel: (data) => data["ticket"]["number"],
        //   },
        // },
        // {
        //   fieldKey: "comment",
        //   type: "dropdownEntity",
        //   label: strings.form.ticket.number.label,
        //
        //   validators: [],
        //
        //   loader: null,
        //
        //   adapter: {
        //     requirement: (data) => data["id"] && data["body"],
        //     getValue: (data) => data["id"],
        //     getLabel: (data) => data["body"],
        //   },
        // }
      ]
    },
  }
}



export const referenceForm = {
  formKey: "callReport",
  fields: [
    bills,
    quotes,
    orders,
    services,
    tickets,
    ticketComments,
    ticketAppointments
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}

export const form = {
  formKey: "callReport",
  fields: [
    operator,
    number,
    user,
    temporaryUser,
    temporaryUserFunction,
    partner,
    beginAt,
    inout,
    result,
    comment
  ]
}
