import React from 'react';

class ScrollContainer extends React.Component {

  static defaultProps = {
    height: "100px",
    className: "",
    additionalStyle: {},

    onScroll: null
  }

  onScroll = (event) => {
    // console.log('scrolling', event.target);
    // console.log(this.props.onScroll);
    this.props.onScroll(event.target.scrollTop, event.target.scrollTopMax)
  }

  render() {
    return (
      <div className={this.props.className} style={{height: this.props.height, overflowY: "scroll", ...this.props.additionalStyle}} onScroll={this.props.onScroll?this.onScroll:null}>
        {this.props.children}
      </div>
    );
  }
}

export default ScrollContainer;
