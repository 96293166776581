import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

import { fetchActiveTickets } from '../../API/Tickets';
import { fetchAppointmentTypes } from '../../API/TicketAppointmentTypes';

import { fetchPartnerUsers } from '../../API/Users';

import { form as appointmentProductForm } from './appointmentProducts';

import {
  technicalTicket as technicalTicketRoutes,
  commercialTicket as commercialTicketRoutes,
  administrativeTicket as administrativeTicketRoutes
} from '../Route/routes';

let secondaryTickets = (type) => {
  let loader = fetchActiveTickets(type);
  return {
    fieldKey: "secondary_tickets",
    label: strings.form.appointment.secondaryTickets.label,
    type: "array",

    submitRemoval: true,
    removalCondition: (value) => !!value,
    defaultNumberOfField: 0,
    allowMoreField: true,

    allowMoreFieldAddLabel: strings.form.appointment.secondaryTickets.allowMoreField.addLabel,
    allowMoreFieldRemoveLabel: strings.form.appointment.secondaryTickets.allowMoreField.removeLabel,
    allowMoreFieldRemovingLabel: strings.form.appointment.secondaryTickets.allowMoreField.removingLabel,

    validators: [],

    subField: {
      label: "",
      type: "dropdownEntity",

      validators: [],

      showEmpty: true,
      loader: {
        id: "secondaryTickets",
        loader: loader,
        loaderRequire: () => true,
        loaderArguments: () => [],
      },

      adapter: {
        requirement: (data) => data["id"] && data["number"],
        getValue: (data) => data["id"],
        getLabel: (data) => data["number"],
      },
    }
  }
}

let refereeClient = {
  fieldKey: "referee_client",
  label: strings.form.appointment.refereeClient.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  loader: {
    id: "refereeClient",
    loader: fetchPartnerUsers,
    loaderRequire: (value, getValues) => getValues() && getValues()["client"] && getValues()["client"]["partner"] && getValues()["client"]["partner"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues()["client"]["partner"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let cause = {
  fieldKey: "cause",
  type: "textarea",
  label: strings.form.appointment.cause.label,
  validators: []
}

let result = {
  fieldKey: "result",
  type: "textarea",
  label: strings.form.appointment.result.label,
  validators: []
}

let file = {
  fieldKey: "file",
  type: "file",
  label: strings.form.appointment.file.label,
  showOnDisabled: (getValues) => typeof getValues()["yousign_procedure"] !== 'object' || !getValues()["yousign_procedure"]["id"] || getValues()["yousign_result"] !== 'procedure_finished',
  validators: []
}

let fileSigned = {
  fieldKey: "file_signed",
  type: "file",
  label: strings.form.appointment.fileSigned.label,
  showOnDisabled: (getValues) => typeof getValues()["yousign_procedure"] === 'object' && getValues()["yousign_procedure"]["id"] && getValues()["yousign_result"] === 'procedure_finished',
  validators: []
}

let appointmentType = (type) => {
  let loader = fetchAppointmentTypes(type);
  return {
    fieldKey: "appointment_type",
    label: strings.form.appointment.appointmentType.label,
    type: "dropdownEntity",

    validators: [],

    showEmpty: true,
    loader: {
      id: "appointmentType",
      loader: loader,
      loaderRequire: () => (value, getValues) => getValues()["request_type"] && getValues()["request_type"]["id"],
      loaderArguments: (value, getValues, getExtraValues) => {
        return [{
          ticket_type_id: getValues()["request_type"]["id"]
        }]
      }
    },

    adapter: {
      requirement: (data) => data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["name"],
    },
  }
}

let yousignOperatorMode = {
  fieldKey: "yousign_operation_mode",
  type: "dropdown",
  label: strings.form.appointment.yousignOperatorMode.label,
  disabled: (getValues) => {
    if(getValues(0) && getValues(0)["appointment_type"] && typeof getValues(0)["appointment_type"] === "object" && getValues(0)["appointment_type"]["ask_for_signature"]) {
      return false;
    }
    return true;
  },
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  input: 'formValues',
  compute: (getValues) => {
    let values = getValues(0);
    if(values["appointment_type"] && values["referee_client"]) {
      let array = [];
      if(values["referee_client"]["mail"]) {
        array.push({
          value: 'email',
          label: `${strings.form.appointment.yousignOperatorMode.options.mail} (${values["referee_client"]["mail"]})`
        })
      }
      if(values["referee_client"]["mobile_phone_number"] && values["referee_client"]["mobile_phone_number"]["prefix"]) {
        array.push({
          value: 'sms',
          label: `${strings.form.appointment.yousignOperatorMode.options.sms} (+${values["referee_client"]["mobile_phone_number"]["prefix"]}${values["referee_client"]["mobile_phone_number"]["number"]})`
        })
      }
      return array;
    }
    return []
  }
}

let yousignResult = {
  fieldKey: "yousign_result",
  type: "dropdown",
  label: strings.form.appointment.yousignResult.label,
  showOnDisabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return credentials.roles.includes('ROLE_NYUKOM') && typeof getValues()["yousign_procedure"] === 'object' && getValues()["yousign_procedure"]["id"]
  },
  validators: [],

  input: 'options',
  showEmpty: true,
  options: [
    {
      value: 'procedure_finished',
      label: strings.form.appointment.yousignResult.options.procedureFinished
    },
    {
      value: 'procedure_refused',
      label: strings.form.appointment.yousignResult.options.procedureRefused
    },
    {
      value: 'procedure_expired',
      label: strings.form.appointment.yousignResult.options.procedureExpired
    },
    {
      value: 'procedure_deleted',
      label: strings.form.appointment.yousignResult.options.procedureDeleted
    },
  ],
}

let yousignLink = (type) => {
  let path = null;
  switch (type) {
    case 'technical':
      path = (id) => technicalTicketRoutes.routes.yousign.createPath(id);
      break;
    case 'commercial':
      path = (id) => commercialTicketRoutes.routes.yousign.createPath(id);
      break;
    case 'administrative':
      path = (id) => administrativeTicketRoutes.routes.yousign.createPath(id);
      break;
    default:
  }
  return {
    fieldKey: "yousign_link",
    type: "button",
    label: strings.form.appointment.yousignLink.label,
    showOnDisabled: (getValues) => typeof getValues()["yousign_procedure"] === 'object' && getValues()["yousign_procedure"]["id"] && getValues()["yousign_result"] === null,
    validators: [],

    as: 'a',
    path: (getValues, getExtraValues) => path(getValues()["id"])
  }
}

let appointmentProducts = {
  fieldKey: "appointment_products",
  label: strings.form.appointment.appointmentProducts.label,
  type: "array",

  submitRemoval: true,
  removalCondition: (value) => !!value,
  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.appointment.appointmentProducts.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.appointment.appointmentProducts.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.appointment.appointmentProducts.allowMoreField.removingLabel,

  validators: [],

  subField: {
    label: "",
    type: "subform",

    validators: [],

    subForm: appointmentProductForm
  }
}

let timeSlotEstimate = {
  fieldKey: "time_slot_estimate",
  type: "time",
  label: strings.form.appointment.timeSlot.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.appointment.timeSlot.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let dateStart = {
  fieldKey: "date_start",
  type: "datetime",
  label: strings.form.appointment.dateStart.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.appointment.dateStart.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let dateEnd = {
  fieldKey: "date_end",
  type: "datetime",
  label: strings.form.appointment.dateEnd.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.appointment.dateEnd.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let closeTicket = (type) => {
  let requiredRole = null;
  switch (type) {
    case 'technical':
    case 'installation':
    case 'termination':
    case 'technicalGlobal':
    case 'technical_global':
      requiredRole = "ROLE_TECHNICAL_TICKET_EDIT";
      break;
    case 'commercial':
      requiredRole = "ROLE_COMMERCIAL_TICKET_EDIT";
      break;
    case 'administrative':
      requiredRole = "ROLE_ADMINISTRATIVE_TICKET_EDIT";
      break;
    default:
  }
  return {
    fieldKey: "close_ticket",
    type: "switch",
    label: strings.form.ticket.closeTicket.label,
    disabled: (getValues, getExtraValues) => {
      let credentials = getExtraValues().credentials();
      return (getValues()['comment'] && getValues()['comment']["hidden"] === true) || !credentials || !(credentials.roles.includes(requiredRole));
    },
    showOnDisabled: () => false,
    defaultValue: false,
    validators: []
  }
}

export const form = (type) => {
  return {
    formKey: "appointment",
    fields: [
      cause,
      result,
      file,
      fileSigned,
      yousignResult,
      yousignLink(type)
    ],
    extraValues: {
      credentials: () => store.getState().persisted.credentials
    }
  }
}

export const newForm = (type, hasPartner = true) => {
  return {
    formKey: "appointment",
    fields: [
      hasPartner?secondaryTickets(type):null,
      cause,
      result,
      hasPartner?refereeClient:null,
      appointmentType(type),
      hasPartner?yousignOperatorMode:null,
      appointmentProducts,
      timeSlotEstimate,
      dateStart,
      dateEnd,
      closeTicket(type)
    ].filter(field => !!field)
  }
}
