import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";

import collapseMenuReducer from './Reducer/collapseMenu';
import credentialsReducer from './Reducer/credentials';
import cartReducer from './Reducer/cart';
import cartProductReducer from './Reducer/cartProduct';
import searchReducer from './Reducer/search';
import processReducer from './Reducer/process';
import themeReducer from './Reducer/theme';
import dashboardReducer from './Reducer/dashboard';
import callReportReducer from './Reducer/callReport';

import profilReducer from './Reducer/profil';
import modalHandlerReducer from './Reducer/modalHandler';
import optionsContainerReducer from './Reducer/optionsContainer';
import flashbagReducer from './Reducer/flashbag';
import breadcrumbReducer from './Reducer/breadcrumb';

import {
  ADD_CREDENTIALS,
  REMOVE_CREDENTIALS
} from './Action/credentials';

import {
  ADD_LINE_TO_FLASHBAG,
  REMOVE_LINE_FROM_FLASHBAG
} from './Action/flashbag';

import {
  MODIFY_CART,
  RESET_CART,
  ADD_CART_SECTION,
  REMOVE_CART_SECTION,
  MODIFY_CART_SECTION_ORDER,
  MODIFY_CART_SECTION_TITLE,
  ADD_CART_ITEM,
  ADD_CART_ITEM_SLAVE,
  REMOVE_CART_ITEM,
  MODIFY_CART_ITEMS_ORDER,
  MODIFY_CART_ITEM_RENTED,
  MODIFY_CART_ITEM_QUANTITY,
  MODIFY_CART_ITEM_DISCOUNT,
  MODIFY_CART_ITEM_DISCOUNT_UNIT,
  ADD_AMENDMENT,
  REMOVE_AMENDMENT,
  COPY_QUOTE_INFORMATION,
  COPY_QUOTE_ITEMS
} from './Action/cart';

import {
  MODIFY_SEARCH,
  REMOVE_SEARCH,
  RESET_SEARCH,
  MODIFY_ITEM_PER_PAGE_SEARCH,
  MODIFY_ORDER_SEARCH,
  MODIFY_DISPLAY_TYPE_SEARCH
} from './Action/search';

import {
  CREATE_CALL_REPORT,
  MODIFY_CALL_REPORT,
  RESET_CALL_REPORT
} from './Action/callReport';

const stateSyncConfig = {
  whitelist: [
    //credentials action will be sync between tabs
    ADD_CREDENTIALS,
    REMOVE_CREDENTIALS,
    //cart action will be sync between tabs
    MODIFY_CART,
    RESET_CART,
    ADD_CART_SECTION,
    REMOVE_CART_SECTION,
    MODIFY_CART_SECTION_ORDER,
    MODIFY_CART_SECTION_TITLE,
    ADD_CART_ITEM,
    ADD_CART_ITEM_SLAVE,
    REMOVE_CART_ITEM,
    MODIFY_CART_ITEMS_ORDER,
    MODIFY_CART_ITEM_RENTED,
    MODIFY_CART_ITEM_QUANTITY,
    MODIFY_CART_ITEM_DISCOUNT,
    MODIFY_CART_ITEM_DISCOUNT_UNIT,
    ADD_AMENDMENT,
    REMOVE_AMENDMENT,
    COPY_QUOTE_INFORMATION,
    COPY_QUOTE_ITEMS,
    //flashbag action will be syn between tabs
    ADD_LINE_TO_FLASHBAG,
    REMOVE_LINE_FROM_FLASHBAG,
    //search filter for page
    MODIFY_SEARCH,
    REMOVE_SEARCH,
    RESET_SEARCH,
    MODIFY_ITEM_PER_PAGE_SEARCH,
    MODIFY_ORDER_SEARCH,
    MODIFY_DISPLAY_TYPE_SEARCH,
    //call reports will be sync between tabs
    CREATE_CALL_REPORT,
    MODIFY_CALL_REPORT,
    RESET_CALL_REPORT
  ]
};

const persistConfig = {
  key: 'root',
  storage,
};

const persisted = persistReducer(
  persistConfig,
  combineReducers({
    credentials: credentialsReducer,
    collapseMenu: collapseMenuReducer,
    cart: cartReducer,
    search: searchReducer,
    process: processReducer,
    theme: themeReducer,
    flashbag: flashbagReducer,
    dashboard: dashboardReducer,
    callReport: callReportReducer,

    // breadcrumb: breadcrumbReducer
  })
);

const reducer = combineReducers({
  persisted,
  profil: profilReducer,
  cartProduct: cartProductReducer,
  modalHandler: modalHandlerReducer,
  optionsContainer: optionsContainerReducer,

  breadcrumb: breadcrumbReducer
});

export const store = createStore(reducer, applyMiddleware(createStateSyncMiddleware(stateSyncConfig)));

initMessageListener(store);

export const persistor = persistStore(store);
