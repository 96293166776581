import React from 'react';

import { Button } from 'react-bootstrap';

class AbstractReference extends React.Component {

  static defaultProps = {
    validationCounterDecrement: null,
    validationCounterFail: null
  }

  askForValidation = () => {
    if(this.props.validationCounterDecrement) {
      this.props.validationCounterDecrement()
    }
  }

  displayTitle(title, link = null) {
    if(link) {
      return (
        <div>
          <a href={link}>{title}</a>
          <Button variant="my-secondary-noline" onClick={this.props.delete} className=""><i className="icon-cross"/></Button>
        </div>
      )
    }
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div>{title}</div>
        <Button variant="my-secondary-noline" onClick={this.props.delete} className=""><i className="icon-cross"/></Button>
      </div>
    )
  }


  displaySeparator() {
    return (
      <div className="call-report-separator"/>
    )
  }

  render() {
    console.warn("You have used a component (AbstractReference) that shouldn't be used as is.");
    return null;
  }
}


export default AbstractReference;
