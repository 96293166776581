const callReport = {
  number: {
    label: "Numéro"
  },
  operator: {
    label: "Opérateur"
  },
  user: {
    label: "Utilisateur",
  },
  temporaryUser: {
    label: "Utilisateur",
  },
  temporaryUserFunction: {
    label: "Fonction",
  },
  partner: {
    label: "Partenaire",
  },
  beginAt: {
    label: "Date",
  },
  beginAtTimeframe: {
    label: "Date",
  },
  beginAtMin: {
    label: "Date (min)",
  },
  beginAtMax: {
    label: "Date (max)",
  },
  inout: {
    label: "Sens",
    options: {
      in: "Entrant",
      out: "Sortant"
    }
  },
  result: {
    label: "Résultat",
    options: {
      finished: "Terminé",
      failed: "Non abouti",
      callbackRequest: "Nous rappelle",
      callback: "demande de rappel",
      transfer: "Tranféré",
    }
  },
  comment: {
    label: "Commentaire",
  },

  bills: {
    label: "Factures"
  },
  quotes: {
    label: "Devis"
  },
  orders: {
    label: "Commandes"
  },
  services: {
    label: "Services"
  },
  tickets: {
    label: "Tickets"
  },
  ticketComments: {
    label: "Commentaires de ticket"
  },
  ticketAppointments: {
    label: "Intervention de ticket"
  }
};

export default callReport;
