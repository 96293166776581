import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem,
  transitionFromId as baseTransitionFromId,
  statistic as baseStatistic,
  stateMachineStatistic as baseStateMachineStatistic
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of tickets based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(identifier) {
  return function (page = 1, itemPerPage = 10, search = [], order = 'ASC', scheme = null){
    return baseIndex('tickets/'+identifier, page, itemPerPage, search, order, scheme);
  }
}


/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single ticket based on his ID
 *
 * @param id the of the ticket requested
 */
export function getFromId(identifier) {
  return function (id) {
    return baseGetFromId('tickets/'+identifier, id);
  }
}

/**
 * Get a single ticket based on his ID
 *
 * @param id the of the ticket requested
 */
export function getIdentificationFromId(identifier) {
  return function (id) {
    return baseGetFromId('tickets/'+identifier, id, 'identification');
  }
}

/**
 * Get a single ticket based on his ID
 *
 * @param id the of the ticket requested
 */
export function getHistoryFromId(identifier) {
  return function (id) {
    return baseGetFromId('tickets/'+identifier, id, 'history');
  }
}

/**
 * Get a single ticket based on his ID
 *
 * @param id the of the ticket requested
 */
export function getAssignRefereeFromId(identifier) {
  return function (id) {
    return baseGetFromId('tickets/'+identifier, id, 'assign_referee');
  }
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single ticket based on his ID
 *
 * @param id the of the ticket requested
 * @param data the new data of the ticket
 */
export function editFromId(identifier) {
  return function (id, data) {
    return baseEditFromId('tickets/'+identifier, id, data);
  }
}

/**
 * Edit a single ticket form based on his ID
 *
 * @param id the of the ticket form requested
 * @param data the new data of the ticket form
 */
export function editAssignRefereeFromId(identifier) {
  return function (id, data) {
    return baseEditFromId('tickets/'+identifier, id, data, 'assign_referee');
  }
}

/**
 * Edit a single ticket based on his ID
 *
 * @param id the of the ticket requested
 * @param data the new data of the ticket
 */
export function editFormAnswerFromId(identifier) {
  return function (id, data) {
    return baseEditFromId('tickets/'+identifier, id, data, 'edit_form_answer');
  }
}

/**
 * Edit a single ticket form based on his ID
 *
 * @param id the of the ticket form requested
 * @param data the new data of the ticket form
 */
export function newCommentFromId(identifier) {
  return function (id, data) {
    return baseEditFromId('tickets/'+identifier, id, data, 'new_comment');
  }
}

/**
 * Edit a single ticket form based on his ID
 *
 * @param id the of the ticket form requested
 * @param data the new data of the ticket form
 */
export function newFileFromId(identifier) {
  return function (id, data) {
    return baseEditFromId('tickets/'+identifier, id, data, 'new_file');
  }
}

/**
 * Edit a single ticket form based on his ID
 *
 * @param id the of the ticket form requested
 * @param data the new data of the ticket form
 */
export function newAppointmentFromId(identifier) {
  return function (id, data) {
    return baseEditFromId('tickets/'+identifier, id, data, 'new_appointment');
  }
}

/**
 * Edit a single ticket form based on his ID
 *
 * @param id the of the ticket form requested
 * @param data the new data of the ticket form
 */
export function newFormAnswerFromId(identifier) {
  return function (id, data) {
    return baseEditFromId('tickets/'+identifier, id, data, 'new_form_answer');
  }
}

/******************************************************************************
 * TRANSITION FUNCTION                                                        *
 ******************************************************************************/

/**
 *
 */
export function workInProgressTransition(identifier) {
  return function (id, data) {
    return baseTransitionFromId('tickets/'+identifier, id, data, 'work_in_progress');
  }
}

/**
 *
 */
export function waitTransition(identifier) {
  return function (id, data) {
    return baseTransitionFromId('tickets/'+identifier, id, data, 'wait');
  }
}

/**
 *
 */
export function closeTransition(identifier) {
  return function (id, data) {
    return baseTransitionFromId('tickets/'+identifier, id, data, 'close');
  }
}

/******************************************************************************
 * STATISTIC FUNCTION                                                         *
 ******************************************************************************/

export function statistic(identifier) {
  return function (stat, attribute, search) {
    return baseStatistic('tickets/'+identifier, stat, attribute, search);
  }
}

/******************************************************************************
 * STATE MACHINE STATISTIC FUNCTION                                           *
 ******************************************************************************/

export function stateMachineStatistic(identifier) {
  return function (stateStatistic, states, search) {
    return baseStateMachineStatistic('tickets/'+identifier, stateStatistic, states, search);
  }
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new ticket
 *
 * @param data the new data of the partner
 */
export function newItem(identifier) {
  return function (data) {
    return baseNewItem('tickets/'+identifier, data);
  }
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

 /**
  * Fetch all tickets to serve in a dropdown form choice
  */
 export function fetchTickets(identifier) {
   return function () {
     return baseIndex('tickets/'+identifier, 1, 0);
   }
 }

/**
 * Fetch all tickets to serve in a dropdown form choice
 */
export function fetchActiveTickets(identifier) {
  return function () {
    let search = {
      state: "new,waiting,in_progress"
    };
    return baseIndex('tickets/'+identifier, 1, 0, search);
  }
}

/******************************************************************************
 * SPECIFIC FUNCTION                                                          *
 ******************************************************************************/

/**
 * escalate a ticket
 *
 * @param id the id of the ticket to escalate
 * @param username the escalation to check
 */
export function escalate(identifier) {
  return function (id, data) {
    return baseEditFromId('tickets/'+identifier, id, data, 'escalate');
  }
}

/**
 * freeze the gfrt notification of a ticket
 *
 * @param id the id of the ticket to freeze
 */
export function freezeGfrtNotification(identifier) {
  return function (id) {
    let data = {
      freeze_gfrt_notification: true
    }
    return baseEditFromId('tickets/'+identifier, id, data, 'freeze_gfrt_notification');
  }
}

/**
 * unfreeze the gfrt notification of a ticket
 *
 * @param id the id of the ticket to unfreeze
 */
export function unfreezeGfrtNotification(identifier) {
  return function (id) {
    let data = {
      freeze_gfrt_notification: false
    }
    return baseEditFromId('tickets/'+identifier, id, data, 'freeze_gfrt_notification');
  }
}
