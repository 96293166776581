import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  editFromId,
  newItem
} from '../../API/TicketAppointmentTypes';
import { form } from '../Form/appointmentTypes';
import { form as searchForm } from '../Form/Search/appointmentTypes';

import {
  technicalAppointmentTypes as technicalAppointmentTypesRoutes,
  commercialAppointmentTypes as commercialAppointmentTypesRoutes,
  administrativeAppointmentTypes as adminitrativeAppointmentTypesRoutes,
  installationAppointmentTypes as installationAppointmentTypesRoutes,
  terminationAppointmentTypes as terminationAppointmentTypesRoutes,
  technicalGlobalAppointmentTypes as technicalGlobalAppointmentTypesRoutes,
} from '../Route/routes';

import { snakeToCamel } from '../../Utils/SnakeToCamel';

function getRoutes(identifier) {
  if(identifier === 'technical') {
    return technicalAppointmentTypesRoutes
  }
  else if(identifier === 'commercial') {
    return commercialAppointmentTypesRoutes
  }
  else if(identifier === 'administrative') {
    return adminitrativeAppointmentTypesRoutes
  }
  else if(identifier === 'installation') {
    return installationAppointmentTypesRoutes
  }
  else if(identifier === 'termination') {
    return terminationAppointmentTypesRoutes
  }
  else if(identifier === 'technical_global') {
    return technicalGlobalAppointmentTypesRoutes
  }
  else {
    console.error("missing identifier for ticket creation : "+identifier);
  }
}

function createConf(identifier, roleIdentifier) {

  let identifierCC = snakeToCamel(identifier);

  const _form = form(identifier);

  const _index = index(identifier);
  const _getFromId = getFromId(identifier);
  const _editFromId = editFromId(identifier);
  const _newItem = newItem(identifier);

  return {
    prefix: "appointmentTypes/"+identifierCC,
    index: {
      pageTitle: strings.common.homepage[identifierCC+"AppointmentType"],

      id: "appointmentType",
      api: _index,
      onClickItem: {
        redirect: true,
        path: (id) => getRoutes(identifier).routes.show.createPath(id)
      },

      newLink: {
        link: () => getRoutes(identifier).routes.new.createPath(),
        auth: 'ROLE_'+roleIdentifier+'_TICKET_APPOINTMENT_TYPE'
      },

      searchForm: searchForm,
      computeSearch: (search) => {
        let result = {};
        if(search.name) {
          result["name"] = search.name;
        }
        return result;
      },

      availableItemDisplay:['line'],
      itemDisplay: {
        line: {
          label: (item) => item["name"],
        },
      },

      sizing: {
        numberColumn: 2,
        formSizeMd: 12
      },

      buttons: () => []
    },
    show: {
      pageTitle: strings.common.homepage[identifierCC+"AppointmentType"],
      pageTitleReturnPath: () => getRoutes(identifier).routes.index.createPath(),
      breadcrumbIdentifier: "name",

      apiGet: _getFromId,

      extendedHeader: false,

      tabs: {
        main: {
          title: strings.appointmentType.tab.main,
          type: 'form',
          form: _form,

          onUpdateItem: (data) => {},
          onSubmit: (entity) => {},
          postEdit: {
            type: 'tab',
            tabId: 'main'
          },

          apiGet: _getFromId,
          apiEdit: _editFromId,

          role: ['ROLE_'+roleIdentifier+'_TICKET_APPOINTMENT_TYPE'],
          editable: ['ROLE_'+roleIdentifier+'_TICKET_APPOINTMENT_TYPE']
        }
      }
    },
    new: {
      pageTitle: strings.appointmentType.new.title,

      api: _newItem,
      empty: {},
      form: _form,
      onSubmit: (item) => {},
      postNew: {
        type: 'path',
        path: (id) => getRoutes(identifier).routes.show.createPath(id)
      }
    }
  }
}

export const technicalTicketAppointmentTypeConf = createConf('technical', 'TECHNICAL');
export const commercialTicketAppointmentTypeConf = createConf('commercial', 'COMMERCIAL');
export const administrativeTicketAppointmentTypeConf = createConf('administrative', 'ADMINISTRATIVE');

export const installationTicketAppointmentTypeConf = createConf('installation', 'TECHNICAL');
export const terminationTicketAppointmentTypeConf = createConf('termination', 'TECHNICAL');

export const technicalGlobalTicketAppointmentTypeConf = createConf('technical_global', 'TECHNICAL');
